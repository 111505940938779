<template>
  <v-container fluid>
    <v-data-table v-if="header_mappings.length" :items="header_mappings" :headers="headers" :options.sync="pagination_settings">
      <template v-slot:top>
        <div style="position: relative">
          <v-btn color="primary" :to="`/header_mappings/new`">
            Add new
          </v-btn>
        </div>
      </template>
      <template v-slot:item.headers="{item}">
        <v-dialog max-width="500px">
          <template v-slot:activator="{on, attrs}">
            <v-badge color="primary" :content="item.headers.length" overlap bordered>
              <v-btn color="secondary" outlined v-on="on" v-bind="attrs">
                View
              </v-btn>
            </v-badge>
          </template>
          <v-card>
            <v-card-title>Headers</v-card-title>
            <v-data-table v-if="item.headers"
                          :headers="[{text: 'Value', value: 'value'}]"
                          :items="item.headers.map(x=>({key: x, value: x}))"
            >
            </v-data-table>
          </v-card>
        </v-dialog>
      </template>

      <template v-slot:item.date_created="{item}">
            <span v-if="item.date_created">
              {{ item.date_created | moment('MM/DD/YYYY h:mm a') }}
            </span>
      </template>
      <template v-slot:item.date_last_modified="{item}">
            <span v-if="item.date_last_modified">
              {{ item.date_last_modified | moment('MM/DD/YYYY h:mm a') }}
            </span>
      </template>
      <template v-slot:item.mappings="{item}">
        <v-dialog max-width="500px">
          <template v-slot:activator="{on, attrs}">
            <v-badge v-if="item.mappings" color="primary"
                     :content="item.mappings ? Object.keys(item.mappings).length : null" overlap bordered>
              <v-btn color="secondary" outlined v-on="on" v-bind="attrs">
                View
              </v-btn>
            </v-badge>
          </template>
          <v-card>
            <v-card-title>Mappings</v-card-title>
            <v-data-table v-if="item.mappings"
                          :headers="[{text: 'Header',value: 'key'}, {text: 'Our Value',value: 'value'}]"
                          :items="Object.entries(item.mappings).map(x=>({key: x[0], value: x[1]}))">
            </v-data-table>
          </v-card>
        </v-dialog>
      </template>
      <template v-slot:item.static_mappings="{item}">
        <v-dialog max-width="500px">
          <template v-slot:activator="{on, attrs}">
            <v-badge v-if="item.static_mappings" color="primary"
                     :content="item.static_mappings ? Object.keys(item.static_mappings).length : null" overlap bordered>
              <v-btn color="secondary" outlined v-on="on" v-bind="attrs">
                View
              </v-btn>
            </v-badge>
          </template>
          <v-card>
            <v-card-title>Static Mappings</v-card-title>
            <v-card-text v-if="item.static_mappings">
              <v-list-item v-for="[key, value] in Object.entries(item.static_mappings)" :key="key" two-line>
                <v-list-item-content>
                  <v-list-item-title>{{ key }}</v-list-item-title>
                  <v-list-item-subtitle>{{ value }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-card-text>
          </v-card>
        </v-dialog>
      </template>
      <template v-slot:item.actions="{item}">
        <v-btn icon color="secondary" :to="`/header_mappings/${item._id}?copy=true`">
          <v-icon>mdi-content-copy</v-icon>
        </v-btn>
        <v-btn icon color="secondary" :to="`/header_mappings/${item._id}`" :disabled="item.is_in_use">
          <v-icon>mdi-pencil</v-icon>
        </v-btn>
        <v-btn icon color="error" @click="delete_mapping(item)" :disabled="item.is_in_use">
          <v-icon>mdi-delete</v-icon>
        </v-btn>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import report_service from "../services/report.service";

export default {
  name: "HeaderMappings",
  data() {
    return {
      headers: [
        {text: 'Date Created', value: 'date_created'},
        {text: 'Created By', value: 'created_by.name'},
        {text: 'Date Modified', value: 'date_last_modified'},
        {text: 'Modified By', value: 'last_modified_by.name'},
        {text: 'Provider', value: 'provider'},
        {text: 'Brand', value: 'brand'},
        {text: 'Scope', value: 'scope'},
        {text: 'Description', value: 'description'},
        {text: 'Ignore', value: 'ignore'},
        {text: 'Headers', value: 'headers'},
        {text: 'Mappings', value: 'mappings'},
        {text: 'Static Mappings', value: 'static_mappings'},
        {text: 'Actions', value: 'actions'},
      ],
      header_mappings: []
    }
  },
  async mounted() {
    await this.search_mappings();
  },
  computed: {
    pagination_settings: {
      get() {
        return this.$store.getters['pagination_settings/header_mappings'];
      },
      set(value) {
        this.$store.dispatch('pagination_settings/update_header_mappings', value);
      }
    },
  },
  methods: {
    async search_mappings() {
      this.header_mappings = await report_service.header_mappings.search();
    },
    async delete_mapping(item) {
      await report_service.header_mappings.delete(item._id);
      await this.search_mappings();
    }
  }
}
</script>

<style scoped>

</style>
