<template>
  <v-card>
    <v-card-text>
      <v-radio-group v-model="use_custom_description" @change="enable_custom">
        <v-radio :value="false" label="Date Range"></v-radio>
        <v-radio :value="true" label="Custom"></v-radio>
      </v-radio-group>
      <v-date-picker v-if="!use_custom_description" no-title width="20vw" range
                     v-model="date_range"></v-date-picker>
      <v-text-field v-if="use_custom_description" v-model="custom_description"
                    placeholder="Enter custom description..." :rules="custom_description_rules"
                    counter="31"></v-text-field>
    </v-card-text>
  </v-card>
</template>

<script>

export default {
  name: "PaymentPeriodSelection",
  props: {
    vendor_pay_period_description_input: {
      type: String,
    },
    closeModal() {
      this.$emit('close-modal-event');
    },
  },
  data() {

    let date_range_report = null;
    if(this.vendor_pay_period_description_input) {
      let date_ranges = this.vendor_pay_period_description_input.split('to')
      if (date_ranges && date_ranges.length == 2) {
        date_range_report = [];
        date_range_report.push(date_ranges[0].trim());
        date_range_report.push(date_ranges[1].trim());
      }
    }

    return {
      group_description: null,
      use_custom_description: false,
      custom_description_rules: [
        v => v && v.length <= 31 || 'Max 31 characters'
      ],
      date_opened: false,
      date_range: date_range_report,
      custom_description: this.vendor_pay_period_description_input,
    }
  },
  watch: {
    custom_description() {
      this.$emit("pay_period_event", {
        date_range: this.date_range,
        custom_description: this.custom_description,
        use_custom_description: this.use_custom_description,
        sender: 'custom_description'
      })
    },
    date_range() {
      this.$emit("pay_period_event", {
        date_range: this.date_range,
        custom_description: this.custom_description,
        use_custom_description: this.use_custom_description,
        sender: 'date_range'
      });
    }
  },
  methods: {
    enable_custom(e) {
      if (!e) {
        this.custom_description = null;
      }
    },
  }
}
</script>

<style scoped>

</style>
