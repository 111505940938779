var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"8"}},[_c('v-card',[_c('v-card-title',[_vm._v("Vendor Payment Records")]),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-autocomplete',{attrs:{"value":_vm.filters.brand,"loading":_vm.brands_loading,"items":_vm.brands,"label":"Brand","placeholder":"Select a brand...","clearable":""},on:{"change":_vm.brand_updated}})],1),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-autocomplete',{attrs:{"value":_vm.filters.has_invoice_number,"items":_vm.invoice_options,"item-value":"value","item-text":"name","label":"Invoice Options","placeholder":"Select an invoice option...","clearable":""},on:{"change":_vm.has_invoice_number_updated}})],1),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-autocomplete',{attrs:{"value":_vm.filters.group,"items":_vm.group_options,"label":"Group","placeholder":"Select a grouping...","multiple":""},on:{"change":_vm.group_updated}})],1)],1)],1),(_vm.indexed_items)?_c('v-data-table',{attrs:{"loading":_vm.loading,"show-select":"","headers":_vm.headers,"options":_vm.pagination_settings,"items":_vm.indexed_items,"show-expand":"","item-key":"id"},on:{"update:options":function($event){_vm.pagination_settings=$event}},scopedSlots:_vm._u([{key:"item.group",fn:function(ref){
var item = ref.item;
return [_c('v-simple-table',[_c('tbody',_vm._l((Object.entries(item.group).filter(function (x) { return !['brand', 'file_id', 'file_name', 'job_id'].includes(x[0]); })),function(ref){
var key = ref[0];
var value = ref[1];
return _c('tr',{key:key},[_c('td',[_vm._v(_vm._s(key))]),_c('td',[_vm._v(_vm._s(value))])])}),0)])]}},{key:"item.types",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(item.types.length))]}},{key:"item.total",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm._f("currency")(item.total)))]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('google-file-link',{attrs:{"file_id":item.group.file_id,"file_name":item.group.file_name}}),_c('v-btn',{attrs:{"color":"error","icon":""},on:{"click":function($event){return _vm.delete_records(item)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1)]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{staticClass:"pa-0",attrs:{"colspan":headers.length}},[_c('v-simple-table',[_c('thead',[_c('tr',[_c('th',[_vm._v("Type")]),_c('th',[_vm._v("Count")]),_c('th',[_vm._v("Total")])])]),_c('tbody',_vm._l((item.types),function(type){return _c('tr',{key:type.type},[_c('td',[_vm._v(_vm._s(type.type))]),_c('td',[_vm._v(_vm._s(type.count))]),_c('td',[_vm._v(_vm._s(_vm._f("currency")(type.total)))])])}),0)])],1)]}}],null,false,2278513662),model:{value:(_vm.selected_items),callback:function ($$v) {_vm.selected_items=$$v},expression:"selected_items"}}):_vm._e()],1)],1),_c('v-col',{attrs:{"cols":"12","md":"4"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }