<template>
  <v-dialog v-model="dialog" max-width="800">
    <template v-slot:activator="{on, attrs}">
      <v-btn icon dark color="primary" v-bind="attrs" v-on="on">
        <v-icon>mdi-cog</v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-card-title>Process File {{ file.name }}</v-card-title>
      <v-card-text>
        <v-container fluid>
          <v-row>
            <v-col cols="12" md="4">
              <v-autocomplete v-model="process_options.provider" :items="providers" item-text="name"
                              item-value="name" label="Provider"
                              placeholder="Select a provider..."></v-autocomplete>
            </v-col>
            <v-col cols="12" md="4">
              <v-autocomplete v-model="process_options.brand" :items="brands" item-text="name" item-value="name"
                              label="Brand"
                              placeholder="Select a brand..."></v-autocomplete>
            </v-col>
            <v-col cols="12" md="4">
              <v-autocomplete v-model="process_options.scope" :items="scopes" label="Scope"
                              placeholder="Select a scope..."></v-autocomplete>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-btn :disabled="!can_process" color="secondary" @click="process_selected_file">Process</v-btn>
        <v-btn color="error" @click="dialog = false">Cancel</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
  name: "ProcessFileDialog",
  props: {
    file: Object
  },
  data() {
    return {
      dialog: false,
      process_options: {
        scope: null,
        brand: null,
        provider: null
      },
      scopes: [
        'vendor_payment_records'
      ]
    }
  },
  computed: {
    ...mapGetters('reports', [
      'brands',
      'providers'
    ]),
    can_process() {
      return this.file
          && this.file.mimeType !== 'application/vnd.google-apps.folder'
          && this.process_options.scope
          && (this.process_options.brand || this.process_options.provider);
    }
  },
  methods: {
    ...mapActions('file_processor', [
      'process_file'
    ]),
    process_selected_file() {
      this.process_file({file_id: this.file.id, ...this.process_options});
      this.dialog = false;
    }
  }
}
</script>

<style scoped>

</style>