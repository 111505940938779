<template>
  <v-container>
    <v-row justify="center">
      <v-col cols="12" md="6">
        <v-card>
          <v-card-title>Login with your compare power account</v-card-title>
          <v-card-text>
            <v-btn @click="login_click" color="primary" v-if="!is_logged_in">
              <v-icon class="mr-2">mdi-lock</v-icon>
              Login
            </v-btn>
          </v-card-text>
          <v-card-actions>
            <span class="error--text">{{ login_error }}</span>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
  name: "Home",
  computed: {
    redirect_to() {
      if (this.$route.query && this.$route.query.redirectTo) {
        return this.$route.query.redirectTo;
      }
      else {
        return 'file_processor';
      }
    },
    ...mapGetters('firebase', [
      'login_error',
      'is_logged_in',
      'user'
    ])
  },
  methods: {
    login_click: async function () {
      await this.login();
      if (this.is_logged_in) {
        this.$router.push({name: this.redirect_to})
      }
    },
    ...mapActions('firebase', [
      'login'
    ])
  }
}
</script>

<style scoped>

</style>