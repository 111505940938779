<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" md="4">
        <v-card>
          <v-card-title>Select a file to process</v-card-title>
          <v-card-actions>
            <v-container fluid>
              <v-row>
                <v-col cols="12" md="3">
                  <v-autocomplete v-model="process_options.provider" :items="providers" item-text="name"
                                  item-value="name" label="Provider"
                                  placeholder="Select a provider..."></v-autocomplete>
                </v-col>
                <v-col cols="12" md="3">
                  <v-autocomplete v-model="process_options.brand" :items="brands" item-text="name" item-value="name"
                                  label="Brand" @change="brand_change($event)"
                                  placeholder="Select a brand..."></v-autocomplete>
                </v-col>
                <v-col cols="12" md="3">
                  <v-autocomplete v-model="process_options.scope" :items="scopes" label="Scope"
                                  placeholder="Select a scope..."></v-autocomplete>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="6">
                  <v-autocomplete v-model="process_options.header_mapping_id" @change="header_mappings_change($event)" :items="header_mappings" label="header mapping" item-text="description" item-value="_id"
                                  placeholder="Select a header mapping...">
                    <template v-slot:selection="data">
                     <span>  {{ data.item.date_last_modified | moment('MM/DD/YYYY h:mm a') }} </span>
                    </template>
                    <template v-slot:item="data">
                      <v-list-item-content>
                        {{ data.item.date_last_modified | moment('MM/DD/YYYY h:mm a') }}
                      </v-list-item-content>
                      <v-badge :content="data.item.processed_job_count > 0 ? data.item.processed_job_count : null" overlap bordered>
                        <v-btn class="ms-3" target="_blank" color="secondary" :to="`/header_mappings/${data.item._id}`" outlined>
                          View
                        </v-btn>
                      </v-badge>
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col>
                  <v-btn :disabled="!can_process" color="secondary" @click="process_selected_file">Process File</v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-card-actions>
          <v-card-text>
            <file-chooser ref="fc" :active_update="active_update"></file-chooser>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="8" v-if="jobs">
        <v-data-table :items="jobs" :headers="headers" item-key="_id" v-if="jobs.length"  :options.sync="pagination_settings" show-expand>
          <template v-slot:header>
            <v-btn icon @click="load_jobs">
              <v-icon>mdi-refresh</v-icon>
            </v-btn>
          </template>
          <template v-slot:item.file="{item}">
            <google-file-link v-if="item.data.file" :file_id="item.data.file.id"
                              :file_name="item.data.file.name"></google-file-link>
          </template>
          <template v-slot:item.logs="{item}">
            <dialog-table :items="item.data.log"></dialog-table>
          </template>
          <template v-slot:item.finished="{item}">
            <span v-if="item.lastFinishedAt">
              {{ item.lastFinishedAt | moment('from', 'now') }}
            </span>
          </template>
          <template v-slot:item.status="{item}">
            <v-progress-circular color="primary" v-if="!item.lastFinishedAt" indeterminate></v-progress-circular>
            <span v-else>Complete</span>
          </template>

          <template v-slot:expanded-item="{headers, item}">
            <td :colspan="headers.length" class="ma-0 pa-0">
              <v-simple-table v-if="item.data.sheet_results">
                <thead>
                <tr>
                  <th>Sheet Name</th>
                  <th>Header Count</th>
                  <th>Found</th>
                  <th>Processed</th>
                  <th>Not Dupe</th>
                  <th>Has Data</th>
                  <th>Data Count</th>
                  <th>Hash</th>
                  <th>Actions</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="sheet_result in item.data.sheet_results" :key="sheet_result.sheet_name">
                  <td>{{ sheet_result.sheet_name }}</td>
                  <td>
                    <v-dialog max-width="500px" v-if="sheet_result.header_values">
                      <template v-slot:activator="{on, attrs}">
                        <v-badge color="primary" :content="sheet_result.header_values.length" overlap bordered>
                          <v-btn color="secondary" outlined v-on="on" v-bind="attrs">
                            View
                          </v-btn>
                        </v-badge>
                      </template>
                      <v-card>
                        <v-card-title>Headers</v-card-title>
                        <v-data-table v-if="sheet_result.header_values"
                                      :headers="[{text: 'Value', value: 'value'}]"
                                      :items="sheet_result.header_values.map(x=>({key: x, value: x}))"
                        >
                        </v-data-table>
                      </v-card>
                    </v-dialog>
                    <p v-else>No headers found</p>
                  </td>
                  <td>
                    <v-icon :color="sheet_result.mapping || sheet_result.is_empty_sheet ? 'success' : 'error'">
                      {{ sheet_result.mapping || sheet_result.is_empty_sheet ? 'mdi-check' : 'mdi-close' }}
                    </v-icon>
                  </td>
                  <td>
                    <v-icon :color="sheet_result.processed ? 'success' : 'error'">
                      {{ sheet_result.processed ? 'mdi-check' : 'mdi-close' }}
                    </v-icon>
                  </td>
                  <td>
                    <v-icon :color="sheet_result.is_duplicate ? 'error' : 'success'">
                      {{ sheet_result.is_duplicate ? 'mdi-close' : 'mdi-check' }}
                    </v-icon>
                  </td>
                  <td>
                    <v-icon :color="sheet_result.has_data_items ? 'success' : 'error'">
                      {{ sheet_result.has_data_items ? 'mdi-check' : 'mdi-close' }}
                    </v-icon>
                  </td>
                  <td>{{ sheet_result.data_item_count }}</td>
                  <td>{{ sheet_result.hash }}</td>
                  <td>
                    <v-btn v-if="sheet_result.header_values" icon color="primary"
                           :to="generate_header_mapping_link(item, sheet_result)">
                      <v-icon>mdi-plus</v-icon>
                    </v-btn>
                  </td>
                </tr>
                </tbody>
              </v-simple-table>
            </td>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import io from 'socket.io-client';
import GoogleFileLink from "../components/GoogleFileLink";
import FileChooser from "../components/file_processor/FileChooser";
import DialogTable from "../components/DialogTable";
import report_service from "../services/report.service";

export default {
  name: "Jobs",
  components: {FileChooser, GoogleFileLink, DialogTable},
  data() {
    return {
      selected: [],
      process_options: {
        scope: null,
        brand: null,
        provider: null,
        header_mapping_id: null
      },
      scopes: [
        'vendor_payment_records'
      ],
      selected_folder: null,
      headers: [
        {text: 'Id', value: '_id'},
        {text: 'File Name', value: 'data.file.name'},
        {text: 'File', value: 'file'},
        {text: 'Sheets Parsed', value: 'data.sheet_results.length'},
        {text: 'Logs', value: 'logs'},
        {text: 'Error', value: 'failReason'},
        {text: 'Finished', value: 'finished'},
        {text: 'Status', value: 'status'}
      ],
      header_mappings: [],
    }
  },
  computed: {
    ...mapGetters('file_processor', [
      'jobs',
      'folders'
    ]),
    ...mapGetters('reports', [
      'brands',
      'providers'
    ]),
    can_process() {
      return this.selected
          && this.selected.length === 1
          && this.selected[0].mimeType !== 'application/vnd.google-apps.folder'
          && !this.selected[0].folder_id
          && this.process_options.scope
          && (this.process_options.brand || this.process_options.provider);
    },
    pagination_settings: {
      get() {
        return this.$store.getters['pagination_settings/file_processor'];
      },
      set(value) {
        this.$store.dispatch('pagination_settings/update_file_processor', value);
      }
    },
  },
  methods: {
    ...mapActions('file_processor', [
      'load_jobs',
      'update_job',
      'process_file',
      'get_full_job'
    ]),
    process_selected_file() {
      this.process_file({file_id: this.selected[0].id, ...this.process_options});
    },
    active_update(active) {
      this.selected = active;
    },
    generate_header_mapping_link(item, sheet_result) {
      let link = '/header_mappings/new?';
      for (const [key, value] of Object.entries(item.data.report_options)) {
        link += `${key}=${value}&`
      }
      link += `file_id=${item.data.file.id}&`;
      link += sheet_result.header_values.map(x => `headers=${x.replace('#', '%23').replace('+', '%2b')}`).join('&');
      return link;
    },
    brand_change(event) {
      console.log(event);
      const request = {
        brand: this.process_options.brand,
        provider: this.process_options.provider,
        scope: this.process_options.scope,
        ignore: false
      };
      report_service.header_mappings.search(request).then(x => {
        this.header_mappings = x.sort((x, y) => {
          return x.date_last_modified > y.date_last_modified ? -1 : 1;
        });
      });
    },
    header_mappings_change(event) {
      console.log(event);
    },
  },
  async mounted() {
    //let self = this;
    await this.load_jobs();
    const socket = io(process.env.VUE_APP_SOCKET_IO_URL);
    socket.on('connect', () => {
      console.log('connected');
      socket.on('processing-updated', (message) => {
        this.update_job({job_id: message.id, update: message.update});
      })
    })
  }
}
</script>

<style scoped>

</style>
