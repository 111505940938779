import Vue from "vue";

export const gtm_plugin = store => {
    store.subscribe((mutation) => {
        const category = mutation.type.split('/')[0];
        const type = mutation.type.split('/').reverse()[0].replace('set_', '');
        let item = {};
        item.event = type;
        item.target = category;
        item[type] = mutation.payload
        Vue.gtm.trackEvent(item);
    })
}