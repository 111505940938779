<template>
  <v-dialog transition="dialog-bottom-transition" max-width="500px" v-model="dialog">
    <template v-slot:activator="{on: dialog, attrs}">
      <v-tooltip top>
        <template v-slot:activator="{on: tooltip}">
          <v-btn icon color="primary" @click="compare" v-bind="attrs" v-on="{...tooltip,...dialog}">
            <v-icon>mdi-compare-horizontal</v-icon>
          </v-btn>
        </template>
        <span>Compare</span>
      </v-tooltip>
    </template>
    <template v-slot:default>
      <v-skeleton-loader v-if="loading" :loading="loading" class="mx-auto" type="article"></v-skeleton-loader>
      <v-card v-else max-height="75vh">
        <v-card-title>
          Comparison Results
        </v-card-title>
        <v-card-text>
          <v-list v-if="compare_result">
            <v-list-item>New: {{ compare_result.new.length }}</v-list-item>
            <v-list-item>Deleted: {{ compare_result.deleted.length }}</v-list-item>
            <v-list-item>Changed: {{ compare_result.changed.length }}</v-list-item>
            <v-list-item>Unchanged: {{ compare_result.unchanged.length }}</v-list-item>
          </v-list>
        </v-card-text>
      </v-card>
    </template>
  </v-dialog>
</template>

<script>
import report_service from '../../services/report.service'

export default {
  name: "ItemCompareDialog",
  props: {
    invoice_id: String,
    groups: Array
  },
  data() {
    return {
      dialog: false,
      compare_result: null,
      loading: false
    }
  },
  methods: {
    async compare() {
      this.loading = true;
      try {
        const result = await report_service.vendor_payment_records.compare({invoice_id: this.invoice_id, groups: this.groups});
        this.compare_result = result;
        this.loading = false;
      }
      catch (err) {
        this.error = err.message;
        this.loading = false;
      }
    },
  }
}
</script>

<style scoped>

</style>