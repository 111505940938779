import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

// root state object.
// each Vuex instance is just a single state tree.
const state = {
    step: 1
}

// getters are functions.
const getters = {
    step: state => state.step,
    step_two_enabled: (state, rootState, getters, rootGetters) => {
        if (rootGetters["quickbooks/is_logged_in"])
            return true
        else
            return false;
    },
    step_three_enabled: (state, rootState, getters, rootGetters) => {
        if (rootGetters["quickbooks/is_logged_in"]
            && rootGetters["reports/has_selected_items"])
            return true;
        else
            return false;
    },
    step_four_enabled: (state, rootState, getters, rootGetters) => {
        if (rootGetters["quickbooks/is_logged_in"]
            && rootGetters["reports/has_selected_items"]
            && rootGetters['reports/description'])
            return true;
        else
            return false;
    },
    step_five_enabled: (state, rootState, getters, rootGetters) => {
        if (rootGetters["quickbooks/is_logged_in"]
            && rootGetters["reports/has_selected_items"]
            && rootGetters['reports/description']
            && rootGetters["quickbooks/has_selected_customer"])
            return true;
        else
            return false;
    },
    can_do_next_step: (state, getters) => {
        switch (state.step) {
            case 1:
                return getters.step_two_enabled;
            case 2:
                return getters.step_three_enabled;
            case 3:
                return getters.step_four_enabled;
            case 4:
                return getters.step_five_enabled;
            case 5:
            default:
                return true;
        }
    }
}

// actions are functions that cause side effects and can involve
// asynchronous operations.
const actions = {
    next_step({dispatch, state}) {
        let next_step = +state.step + 1;
        dispatch('set_step', next_step);
    },
    previous_step({dispatch, state}) {
        let previous_step = +state.step - 1;
        dispatch('set_step', previous_step)
    },
    set_step({commit, rootGetters, dispatch}, step) {
        if (step > 3) {
            const filters = rootGetters["report_filters/filters"];
            if (filters && filters.brand) {
                const brands = rootGetters["reports/brands"];
                const brand = brands.find(x => x.name === filters.brand);

                if (step == 4) {
                    if (brand && brand.customer) {
                        const customers = rootGetters["quickbooks/customers"];
                        const selected = customers.find(x => x.Id === brand.customer.id);
                        if (selected) {
                            dispatch('quickbooks/set_selected_customer', selected, {root: true});
                        }
                    }
                }

                if (step == 5) {
                    if (brand.types) {
                        const pairs = Object.entries(brand.types);
                        if (pairs && pairs.length) {
                            const selected_items = rootGetters["reports/selected_items"];
                            for (const selected_item of selected_items) {
                                for (const {type} of selected_item.types) {
                                    if (brand.types[type]) {
                                        const item = brand.types[type];
                                        const items = rootGetters["quickbooks/items"];
                                        const found_item = items.find(x => x.Id === item.id);
                                        if (found_item) {
                                            dispatch('reports/update_selected_item_item_type',
                                                {
                                                    _id: selected_item._id,
                                                    type: type,
                                                    item: found_item
                                                },
                                                {
                                                    root: true
                                                })
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        commit('set_step', step);
    }
}


// mutations are operations that actually mutate the state.
// each mutation handler gets the entire state tree as the
// first argument, followed by additional payload arguments.
// mutations must be synchronous and can be recorded by plugins
// for debugging purposes.
const mutations = {
    set_step(state, step) {
        state.step = step;
    }
}

// A Vuex instance is created by combining the state, mutations, actions,
// and getters.
export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
