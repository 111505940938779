import Vue from 'vue'
import Vuex from 'vuex'
import pre_processor_service from '../../services/pre_processor.service'

Vue.use(Vuex)

const state = {
    jobs: [],
    statuses: [],
    file_runs: [],
    pagination_settings: { page : 1, itemsPerPage : 5 },
}

const getters = {
    jobs: state => state.jobs,
    pagination_settings: state => state.pagination_settings,
}

const actions = {
    async load_jobs({commit, state, rootGetters}) {
        if (rootGetters["firebase/is_logged_in"]) {
            const jobs = await pre_processor_service.jobs.search(state.statuses);
            commit('set_jobs', jobs);
        }
    },
    async process_file({dispatch, rootGetters}, file_id) {
        if (rootGetters["firebase/is_logged_in"]) {
            await pre_processor_service.process.file(file_id);
            dispatch('load_jobs');
        }
    },
    async update_job({commit}, payload) {
        commit('update_job', payload);
    },
    async update_pagination_settings({commit}, payload) {
       commit('update_pagination_settings', payload);
    },
}

const mutations = {
    set_jobs(state, jobs) {
        state.jobs = jobs;
    },
    update_job(state, payload) {
        let found_index = state.jobs.findIndex((job) => job._id === payload.job_id);
        if (found_index >= 0) {
            Vue.set(state.jobs, found_index, Object.assign(state.jobs[found_index], payload.update));
        }
    },
    update_pagination_settings(state, payload) {
        state.pagination_settings = payload;
    },
}

// A Vuex instance is created by combining the state, mutations, actions,
// and getters.
export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
