import Vue from 'vue'
import App from './App.vue'
import {auth} from './plugins/firebase'
import vuetify from './plugins/vuetify';
import router from './router'
import store from './store'
import VueGtm from 'vue-gtm'
import VueCurrencyFilter from "vue-currency-filter";
import vueFilterPrettyBytes from 'vue-filter-pretty-bytes'

import mitt from "mitt";
const emitter = new mitt();

Vue.config.productionTip = false

Vue.use(VueGtm, {
    id: process.env.VUE_APP_GTM_KEY,
    vueRouter: router
})

Vue.use(vueFilterPrettyBytes);
Vue.use(require('vue-moment'));
Vue.use(VueCurrencyFilter,
    {
        symbol: '$',
        thousandsSeparator: ',',
        fractionCount: 2,
        fractionSeparator: '.',
        symbolPosition: 'front',
        symbolSpacing: false,
        avoidEmptyDecimals: undefined,
    })

let app;
auth.onAuthStateChanged((user) => {
    if (user) {
        store.commit('firebase/set_is_logged_in', true)
    }
    if (!app) {
        console.log('started');
        app = new Vue({
            router,
            vuetify,
            store,
            render: h => h(App)
        }).$mount('#app');

        Vue.prototype.$emitter = emitter;
    }
})
