import Vue from 'vue'
import Vuex from 'vuex'
import report_service from '../../services/report.service'

Vue.use(Vuex)

// root state object.
// each Vuex instance is just a single state tree.
const state = {
    brands: [],
    providers: [],
    items: [],
    error: null,
    selected_items: [],
    loading: false,
    date_range: null,
    custom_description: null,
    invoice_date: null
}

// getters are functions.
const getters = {
    items: state => state.items,
    selected_items: state => state.selected_items,
    has_selected_items: state => state.selected_items && state.selected_items.length && state.selected_items.length > 0,
    selected_items_have_items: state => state.selected_items ? state.selected_items.every(item => item.types.every(type => type.selected_item)) : false,
    loading: state => state.loading,
    error: state => state.error,
    date_range: state => state.date_range,
    custom_description: state => state.custom_description,
    description: state => {
        if (state.custom_description) {
            return state.custom_description;
        }
        else if (state.date_range && state.date_range.length === 2) {
            const sorted = Object.assign([], state.date_range);
            sorted.sort();
            return sorted.join(' to ');
        }
        else
            return null;
    },
    invoice_date: state => state.invoice_date,
    has_data: state => state.items && state.items.length,
    brands: state => state.brands,
    providers: state => state.providers
}

// actions are functions that cause side effects and can involve
// asynchronous operations.
const actions = {
    async set_report_for_review({dispatch, rootGetters}, payload) {
        try {
            if (rootGetters["firebase/is_logged_in"]) {
                await report_service.set_report_for_review(payload);
                dispatch.search();
            }
        }
        catch (err) {
            console.error(err);
        }
    },
    async load_brands({commit}) {
        const brands = await report_service.brands.search();
        commit('set_brands', brands);
    },
    async load_providers({commit}) {
        const providers = await report_service.providers.search();
        commit('set_providers', providers);
    },
    async search({commit, rootState, rootGetters}) {
        if (rootGetters["firebase/is_logged_in"]) {
            commit('set_selected_items', []);
            commit('set_items', []);
            commit('set_loading', true);
            commit('clear_error');
            try {
                if (rootState.report_filters.filters.brand) {
                    let filtered_items = await report_service.vendor_payment_records.search(rootState.report_filters.filters);
                    filtered_items = filtered_items.map((item, index) => {
                        return {
                            _id: index,
                            ...item
                        };
                    });
                    commit('set_items', filtered_items);
                }
            }
            catch (err) {
                commit('set_error', err);
            }
            finally {
                commit('set_loading', false);
            }
        }
    },
    async set_selected_items({commit}, value) {
        commit('set_selected_items', value);
    },
    async find_last_item_types({getters, rootState, commit, rootGetters}) {
        if (rootGetters["firebase/is_logged_in"]) {
            const selected_items = [...getters.selected_items];
            for (let i = 0; i < selected_items.length; i++) {
                const selected = selected_items[i];
                if (!selected.selected_item) {
                    const item_id = await report_service.vendor_payment_records.get_last_item_type(selected.type, selected.provider, selected.brand, selected.is_negative);
                    if (rootState.quickbooks.items) {
                        const found_item = rootState.quickbooks.items.find(item => item.Id === item_id);
                        if (found_item) {
                            selected.selected_item = Object.assign({}, found_item);
                        }
                    }
                }
            }
            commit('set_selected_items', selected_items);
        }
    },
    clear_selected_items({commit}) {
        commit('set_selected_items', []);
    },
    set_date_range({commit, dispatch}, value) {
        commit('set_date_range', value);
        dispatch('quickbooks/find_matching_invoices', {}, {root: true});
        dispatch('clear_custom_description');
    },
    clear_date_range({commit}) {
        commit('clear_date_range');
    },
    set_custom_description({commit}, value) {
        commit('set_custom_description', value);
    },
    clear_custom_description({commit}) {
        commit('set_custom_description', null);
    },
    set_invoice_date({commit}, value) {
        commit('set_invoice_date', value);
    },
    update_selected_item_item_type({commit}, params) {
        commit('update_selected_item_item_type', params);
    },
    update_selected_item_item_desc({commit}, params) {
        commit('update_selected_item_item_desc', params);
    },
    remove_item({commit}, item) {
        const index = state.selected_items.findIndex(x => x.id === item.id);
        if (index >= 0) {
            let new_items = [...state.selected_items];
            new_items.splice(index, 1);
            commit('set_selected_items', new_items);
        }
    }
}


// mutations are operations that actually mutate the state.
// each mutation handler gets the entire state tree as the
// first argument, followed by additional payload arguments.
// mutations must be synchronous and can be recorded by plugins
// for debugging purposes.
const mutations = {
    set_loading(state, loading) {
        state.loading = loading
    },
    set_items(state, items) {
        state.items = items;
    },
    set_error(state, error) {
        state.error = error;
    },
    clear_error(state) {
        state.error = null;
    },
    set_selected_items(state, value) {
        if (value && value.length) {
            state.selected_items = [...value];
        }
        else {
            state.selected_items = [];
        }
    },
    set_date_range(state, value) {
        state.date_range = value;
        state.invoice_date = value[1];
    },
    clear_date_range(state) {
        state.date_range = null;
        state.invoice_date = null;
    },
    set_custom_description(state, custom_desc) {
        state.custom_description = custom_desc;
    },
    set_invoice_date(state, value) {
        state.invoice_date = value;
    },
    update_selected_item_item_type(state, payload) {
        const index = state.selected_items.findIndex(item => item._id == payload._id);
        if (index !== -1) {
            const found_item = state.selected_items[index];
            console.log(found_item);
            const type_index = found_item.types.findIndex(type => type.type === payload.type);
            const found_type = found_item.types[type_index];
            console.log(found_type);
            found_type.selected_item = payload.item;
            state.selected_items.splice(index, 1, found_item);
        }
    },
    update_selected_item_item_desc(state, payload) {
        const index = state.selected_items.findIndex(item => item._id == payload._id);
        if (index !== -1) {
            const found_item = state.selected_items[index];
            const type_index = found_item.types.findIndex(type => type.type === payload.type);
            const found_type = found_item.types[type_index];
            found_type.selected_item.desc = payload.item.desc;
            state.selected_items.splice(index, 1, found_item);
        }
    },
    set_brands(state, payload) {
        state.brands = payload;
    },
    set_providers(state, payload) {
        state.providers = payload;
    }
}

// A Vuex instance is created by combining the state, mutations, actions,
// and getters.
export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
