import Vue from 'vue'
import Vuex from 'vuex'
import report_service from '@/services/report.service'

Vue.use(Vuex)

// root state object.
// each Vuex instance is just a single state tree.
const state = {
    filters: {has_invoice_number: false, under_review: false, brand: null},
    invoice_options: [
        {
            name: 'All',
            value: null
        },
        {
            name: 'No Invoice #',
            value: false
        },
        {
            name: 'Has Invoice #',
            value: true
        }
    ],
    review_options: [
        {
            name: 'All',
            value: null
        },
        {
            name: 'Not Under Review',
            value: false
        },
        {
            name: 'Under Review',
            value: true
        }
    ],
    brands: [],
    brands_loading: false,
}

// getters are functions.
const getters = {
    filters: state => state.filters,
    invoice_options: state => state.invoice_options,
    review_options: state => state.review_options,
    brands: state => state.brands,
    brands_loading: state => state.brands_loading
}

// actions are functions that cause side effects and can involve
// asynchronous operations.
const actions = {
    async load_brands({commit, state, rootGetters}) {
        if (rootGetters["firebase/is_logged_in"]) {
            commit('set_brands', []);
            commit('set_brands_loading', true);
            const brands = await report_service.vendor_payment_records.brands.search(state.filters);
            commit('set_brands_loading', false);
            commit('set_brands', brands);
        }
    },
    async update_filter({commit, state, dispatch}, property) {
        let filters = {...state.filters, ...property};
        commit('set_filters', filters);
        await dispatch('reports/search', {}, {root: true});
    },
    async clear_filters({commit, dispatch}) {
        commit('set_filters', {});
        await dispatch('reports/search', {}, {root: true});
    }
}


// mutations are operations that actually mutate the state.
// each mutation handler gets the entire state tree as the
// first argument, followed by additional payload arguments.
// mutations must be synchronous and can be recorded by plugins
// for debugging purposes.
const mutations = {
    set_filters(state, filters) {
        state.filters = filters;
    },
    set_brands(state, brands) {
        state.brands = brands;
    },
    set_brands_loading(state, value) {
        state.brands_loading = value;
    }
}

// A Vuex instance is created by combining the state, mutations, actions,
// and getters.
export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
