import { render, staticRenderFns } from "./DialogTable.vue?vue&type=template&id=2dc98626&scoped=true&"
import script from "./DialogTable.vue?vue&type=script&lang=js&"
export * from "./DialogTable.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2dc98626",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBadge } from 'vuetify/lib/components/VBadge';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';
installComponents(component, {VBadge,VBtn,VDialog,VSimpleTable})
