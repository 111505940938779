import axios from "axios";
import {auth} from "../plugins/firebase";

const formatters = {
    invoice: function (item) {
        let found_field = item.CustomField.find((field) => field.DefinitionId === process.env.VUE_APP_QB_CUSTOM_FIELD_DEFINITION_ID);
        if (found_field) {
            item.custom_field = found_field.StringValue;
        }
        item.Line = item.Line.filter((line) => {
            return line.DetailType === 'SalesItemLineDetail';
        })
        return item;
    }
}

export default {
    invoices: {
        get: async function (realm_id, access_token, invoice_id) {
            try {
                const token = await auth.currentUser.getIdToken();
                const response = await axios.get(`${process.env.VUE_APP_API_URL}quickbooks/${realm_id}/invoices/${invoice_id}`,
                    {
                        headers: {
                            Authorization: `Bearer ${token}`,
                            QB_AUTH: `Bearer ${access_token}`
                        }
                    });
                const invoice = formatters.invoice(response.data.Invoice);
                return invoice;
            }
            catch (err) {
                console.error(err);
                throw err;
            }

        },
        clear: async function (realm_id, access_token, invoice_id) {
            try {
                const token = await auth.currentUser.getIdToken();
                const response = await axios.patch(`${process.env.VUE_APP_API_URL}quickbooks/${realm_id}/invoices/${invoice_id}/clear`,
                    null,
                    {
                        headers: {
                            Authorization: `Bearer ${token}`,
                            QB_AUTH: `Bearer ${access_token}`
                        }
                    });
                return response.data;
            }
            catch (err) {
                console.error(err);
                throw err;
            }
        },
        update: async function (realm_id, access_token, customer_id, invoice_id, sync_token, line_items, description, invoice_date, append, delete_records, reprocess) {
            try {
                const token = await auth.currentUser.getIdToken();
                const response = await axios.patch(`${process.env.VUE_APP_API_URL}quickbooks/${realm_id}/invoices/${invoice_id}`,
                    {
                        customer_id: customer_id,
                        append: append,
                        reprocess: reprocess,
                        delete_records: delete_records,
                        sync_token: sync_token,
                        description: description,
                        invoice_date: invoice_date,
                        items: line_items
                    },
                    {
                        headers: {
                            Authorization: `Bearer ${token}`,
                            QB_AUTH: `Bearer ${access_token}`
                        }
                    });
                const invoice = formatters.invoice(response.data.Invoice);
                return invoice;
            }
            catch (err) {
                console.error(err);
                throw err;
            }
        },
        delete: async function (realm_id, access_token, invoice_id, sync_token, delete_records, reprocess) {
            try {
                const token = await auth.currentUser.getIdToken();
                const response = await axios.delete(`${process.env.VUE_APP_API_URL}quickbooks/${realm_id}/invoices/${invoice_id}`,
                    {
                        params: {
                            sync_token: sync_token,
                            delete_records: delete_records,
                            reprocess: reprocess
                        },
                        headers: {
                            Authorization: `Bearer ${token}`,
                            QB_AUTH: `Bearer ${access_token}`
                        }
                    });
                const invoice = response.data.Invoice;
                return invoice;
            }
            catch (err) {
                console.error(err);
                throw err;
            }
        },
        create: async function (realm_id, access_token, customer_id, line_items, description, invoice_date) {
            try {
                const token = await auth.currentUser.getIdToken();
                const response = await axios.post(`${process.env.VUE_APP_API_URL}quickbooks/${realm_id}/customers/${customer_id}/invoices`,
                    {
                        description: description,
                        invoice_date: invoice_date,
                        items: line_items
                    },
                    {
                        headers: {
                            Authorization: `Bearer ${token}`,
                            QB_AUTH: `Bearer ${access_token}`
                        }
                    });
                const invoice = formatters.invoice(response.data.Invoice);
                return invoice;
            }
            catch (err) {
                console.error(err);
                throw err;
            }
        },
        create_v1: async function ({realm_id, access_token, customer_id, invoice_request}) {
            try {
                const token = await auth.currentUser.getIdToken();
                const response = await axios.post(`${process.env.VUE_APP_API_URL}quickbooks/${realm_id}/customers/${customer_id}/v1/invoices`,
                    invoice_request,
                    {
                        headers: {
                            Authorization: `Bearer ${token}`,
                            QB_AUTH: `Bearer ${access_token}`
                        }
                    });
                const invoice = formatters.invoice(response.data.Invoice);
                return invoice;
            }
            catch (err) {
                console.error(err);
                throw err;
            }
        },
        update_invoice_v1: async function ({ realm_id, access_token, invoice_request, bq_invoice_id}) {
            try {
                const token = await auth.currentUser.getIdToken();
                const response = await axios.patch(`${process.env.VUE_APP_API_URL}reports/invoices/${bq_invoice_id}`,
                    {
                        vendor_pay_period_description: invoice_request.vendor_pay_period_description,
                        invoice_date: invoice_request.invoice_date,
                        qb_invoice_id: invoice_request.qb_invoice_id
                    },
                    {
                        params: {
                            realm_id: realm_id,
                        },
                        headers: {
                            Authorization: `Bearer ${token}`,
                            QB_AUTH: `Bearer ${access_token}`
                        }
                    });

                const invoice = formatters.invoice(response.data.Invoice);
                return invoice;
            }
            catch (err) {
                console.error(err);
                throw err;
            }
        },
        search: async function (realm_id, access_token, customer_id) {
            try {
                const token = await auth.currentUser.getIdToken();
                const {data} = await axios.get(`${process.env.VUE_APP_API_URL}quickbooks/${realm_id}/customers/${customer_id}/invoices`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        QB_AUTH: `Bearer ${access_token}`
                    }
                });
                return data.map(formatters.invoice);
            }
            catch (err) {
                console.error(err);
                throw err;
            }
        }
    },
    items: {
        search: async function (realm_id, access_token) {
            try {
                const token = await auth.currentUser.getIdToken();
                const {data} = await axios.get(`${process.env.VUE_APP_API_URL}quickbooks/${realm_id}/items`,
                    {
                        headers: {
                            Authorization: `Bearer ${token}`,
                            QB_AUTH: `Bearer ${access_token}`
                        }
                    });
                let result = data.QueryResponse.Item;
                result = result.filter(x => x.Type !== 'Category');
                result.sort((a, b) => a.FullyQualifiedName < b.FullyQualifiedName ? -1 : 1);
                return result;
            }
            catch (err) {
                console.error(err);
                throw err;
            }
        }
    },
    customers: {
        search: async function (realm_id, access_token) {
            try {
                const token = await auth.currentUser.getIdToken();
                const {data} = await axios.get(`${process.env.VUE_APP_API_URL}quickbooks/${realm_id}/customers`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        QB_AUTH: `Bearer ${access_token}`
                    }
                });
                let customers = data.QueryResponse.Customer;
                customers.sort((a, b) => a.FullyQualifiedName < b.FullyQualifiedName ? -1 : 1);
                return customers;
            }
            catch (err) {
                console.error(err);
                throw err;
            }
        },
    },
    get_login_url: async function () {
        try {
            const token = await auth.currentUser.getIdToken();
            let {data} = await axios.get(`${process.env.VUE_APP_API_URL}quickbooks/auth/get_auth_uri`, {
                headers: {
                    Authorization: `Bearer ${token}`
                },
                params: {
                    redirect_url: process.env.VUE_APP_QB_CALLBACK_URL
                }
            });
            return data;
        }
        catch (err) {
            console.error(err);
            throw err;
        }
    },
    get_login_url_v1: async function () {
        try {
            const token = await auth.currentUser.getIdToken();
            let {data} = await axios.get(`${process.env.VUE_APP_API_URL}quickbooks/auth/get_auth_uri`, {
                headers: {
                    Authorization: `Bearer ${token}`
                },
                params: {
                    redirect_url: process.env.VUE_APP_QB_INVOICES_NEW_CALLBACK_URL
                }
            });
            return data;
        }
        catch (err) {
            console.error(err);
            throw err;
        }
    },
    get_tokens: async function (code, realm_id) {
        try {
            const token = await auth.currentUser.getIdToken();
            let {data} = await axios.get(`${process.env.VUE_APP_API_URL}quickbooks/auth/get_tokens`, {
                headers: {
                    Authorization: `Bearer ${token}`
                },
                params: {
                    code: code,
                    realmId: realm_id,
                    redirectUri: process.env.VUE_APP_QB_CALLBACK_URL
                }
            });
            return data;
        }
        catch (err) {
            console.error(err);
            throw err;
        }
    },
    get_tokens_v1: async function (code, realm_id) {
        try {
            const token = await auth.currentUser.getIdToken();
            let {data} = await axios.get(`${process.env.VUE_APP_API_URL}quickbooks/auth/get_tokens`, {
                headers: {
                    Authorization: `Bearer ${token}`
                },
                params: {
                    code: code,
                    realmId: realm_id,
                    redirectUri: process.env.VUE_APP_QB_INVOICES_NEW_CALLBACK_URL
                }
            });
            return data;
        }
        catch (err) {
            console.error(err);
            throw err;
        }
    },
    refresh_tokens: async function (refresh_token) {
        try {
            const token = await auth.currentUser.getIdToken();
            let {data} = await axios.get(`${process.env.VUE_APP_API_URL}quickbooks/auth/refresh_tokens`, {
                headers: {
                    Authorization: `Bearer ${token}`
                },
                params: {
                    refresh_token: refresh_token
                }
            });
            return data;
        }
        catch (err) {
            console.error(err);
            throw err;
        }
    }
}
