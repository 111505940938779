var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.has_invoices)?_c('v-data-table',{attrs:{"loading":_vm.invoices_loading,"headers":_vm.invoice_headers,"items":_vm.invoices,"item-key":"Id","show-expand":"","single-expand":""},scopedSlots:_vm._u([{key:"item.TotalAmt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currency")(item.TotalAmt))+" ")]}},{key:"item.Balance",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currency")(item.Balance))+" ")]}},{key:"item.custom_field",fn:function(ref){
var item = ref.item;
return [_c('span',{class:{'red--text': item.has_match}},[_vm._v(_vm._s(item.custom_field))])]}},{key:"item.MetaData.CreateTime",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moment")(item.MetaData.CreateTime,'YYYY-MM-DD h:mm a'))+" ")]}},{key:"item.MetaData.LastUpdatedTime",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moment")(item.MetaData.LastUpdatedTime,'YYYY-MM-DD h:mm a'))+" ")]}},{key:"item.reports",fn:function(ref){
var item = ref.item;
return [(item.reports && item.reports.length)?_c('div',[_c('invoice-clear-dialog',{attrs:{"invoice":item}}),_vm._l((item.reports),function(report){return _c('google-file-link',{key:report.file_id,attrs:{"file_id":report.file_id,"file_name":report.file_name}})}),_c('item-compare-dialog',{attrs:{"invoice_id":item.Id,"groups":_vm.selected_items.map(function (x){ return x.group; })}})],2):_vm._e()]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{staticClass:"ma-0 pa-0",attrs:{"colspan":headers.length}},[_c('v-simple-table',{staticClass:"grey lighten-4",scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',[_vm._v("Type")]),_c('th',[_vm._v("Category")]),_c('th',[_vm._v("Description")]),_c('th',[_vm._v("Quantity")]),_c('th',[_vm._v("Amount")])])]),_c('tbody',_vm._l((item.Line),function(line){return _c('tr',{key:line.Id},[_c('td',[_vm._v(_vm._s(line.SalesItemLineDetail ? line.SalesItemLineDetail.ItemAccountRef.name : 'NA'))]),_c('td',[_vm._v(_vm._s(line.SalesItemLineDetail ? line.SalesItemLineDetail.ItemRef.name : 'NA'))]),_c('td',[_vm._v(_vm._s(line.Description))]),_c('td',[_vm._v(_vm._s(line.SalesItemLineDetail ? line.SalesItemLineDetail.Qty : 'NA'))]),_c('td',[_vm._v(_vm._s(_vm._f("currency")(line.Amount ? line.Amount : 'NA')))])])}),0)]},proxy:true}],null,true)})],1)]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"icon":"","color":"primary","disabled":!_vm.has_selected_customer || !_vm.selected_items_have_items || !_vm.invoice_date},on:{"click":function($event){return _vm.append_invoice(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-note-plus")])],1)]}}],null,true)},[_c('span',[_vm._v("Append")])]),_c('invoice-replace-dialog',{attrs:{"disabled":!_vm.has_selected_customer || !_vm.selected_items_have_items || !_vm.invoice_date,"invoice":item}}),_c('invoice-delete-dialog',{attrs:{"invoice":item}})]}}],null,false,254341829)}):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }