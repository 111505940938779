import Vue from 'vue'
import Vuex from 'vuex'
import file_processor_service from '../../services/file_processor.service'

Vue.use(Vuex)

const state = {
    jobs: [],
    statuses: [],
    folders: [],
    files: []
}

const getters = {
    jobs: state => state.jobs.sort((x, y) => { return x.lastRunAt < y.lastRunAt ? 1 : -1 ; }),
    statuses: state => state.statuses,
    folders: state => state.folders,
    files: state => state.files
}

const actions = {
    async load_jobs({commit, state, rootGetters}) {
        if (rootGetters["firebase/is_logged_in"]) {
            const jobs = await file_processor_service.jobs.search(state.statuses);
            commit('set_jobs', jobs);
        }
    },
    async load_folders({commit, rootGetters}) {
        if (rootGetters["firebase/is_logged_in"]) {
            const jobs = await file_processor_service.folders.all();
            commit('set_folders', jobs);
        }
    },
    async load_files({commit, rootGetters}, folder_id) {
        if (rootGetters["firebase/is_logged_in"]) {
            const files = await file_processor_service.folders.get_files_by_id(folder_id);
            commit('set_files', files);
        }
    },
    async process_folder({dispatch, rootGetters}, folder_id) {
        if (rootGetters["firebase/is_logged_in"]) {
            await file_processor_service.process.folder(folder_id);
            dispatch('load_jobs');
        }
    },
    async process_file({dispatch, rootGetters}, payload) {
        if (rootGetters["firebase/is_logged_in"]) {
            await file_processor_service.process.file(payload);
            dispatch('load_jobs');
        }
    },
    async process_all({dispatch, rootGetters}) {
        if (rootGetters["firebase/is_logged_in"]) {
            await file_processor_service.process.all();
            dispatch('load_jobs');
        }
    },
    async update_job({commit}, payload) {
        commit('update_job', payload);
    },
    async get_full_job({commit}, payload){
        const job = await file_processor_service.jobs.get_by_id(payload.job_id);
        commit('update_job', {job_id: payload.job_id, update: job})
    }
}

const mutations = {
    set_jobs(state, jobs) {
        state.jobs = jobs;
    },
    set_folders(state, folders) {
        state.folders = folders;
    },
    set_files(state, files) {
        state.files = files;
    },
    update_job(state, payload) {
        let found_index = state.jobs.findIndex((job) => job._id === payload.job_id);
        if (found_index >= 0) {
            Vue.set(state.jobs, found_index, Object.assign(state.jobs[found_index], payload.update));
        }
    }
}

// A Vuex instance is created by combining the state, mutations, actions,
// and getters.
export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
