<template>
  <v-app>
    <v-app-bar
        app
        color="primary"
        dark
    >
      <v-row class="hidden-md-and-up">
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-app-bar-nav-icon slot="activator" v-bind="attrs" v-on="on"></v-app-bar-nav-icon>
          </template>
          <v-list>
            <v-list-item v-if="!is_logged_in">
              <v-list-item-title>
                <v-btn to="/" text>
                  Login
                </v-btn>
              </v-list-item-title>
            </v-list-item>
            <v-list-item
                v-for="(item, index) in logged_in_items"
                :key="index"
            >
              <v-list-item-title>
                <v-btn :key="item.to" :to="item.to" text>
                  {{ item.text }}
                </v-btn>
              </v-list-item-title>
            </v-list-item>
            <v-list-item v-if="!is_logged_in">
              <v-list-item-title>
                <v-btn @click="logout_click" text v-if="is_logged_in" class="mr-2">
                  Logout
                </v-btn>
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-row>
      <v-toolbar-title>Compare Power Test
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-items class="hidden-sm-and-down">
        <v-btn v-if="!is_logged_in" to="/" text>
          Login
        </v-btn>
        <v-btn v-for="item in logged_in_items" :key="item.to" :to="item.to" text>
          {{ item.text }}
        </v-btn>
        <v-btn @click="logout_click" text v-if="is_logged_in" class="mr-2">
          Logout
        </v-btn>
        <v-avatar v-if="is_logged_in" class="mt-2">
          <v-img :src="user.photoURL"></v-img>
        </v-avatar>
      </v-toolbar-items>
    </v-app-bar>
    <v-main>
      <router-view/>
    </v-main>
  </v-app>
</template>

<script>

import {mapActions, mapGetters} from "vuex";

export default {
  name: 'App',
  data() {
    return {
      menu_items: [
        {to: '/brands', text: 'brands', logged_in_only: true},

        // {to: '/pre_processor', text: 'Pre Processor', logged_in_only: true},
        // {to: '/allowed_properties', text: 'Allowed Properties', logged_in_only: true},
        // {to: '/header_mappings', text: 'Header Mappings', logged_in_only: true},
        // {to: '/file_processor', text: 'File Processor', logged_in_only: true},


        // {to: '/file_runs', text: 'File Runs', logged_in_only: true},
        // {to: '/vendor_payment_records', text: 'Vendor Payment Records', logged_in_only: true},
        // {to: '/invoices', text: 'Invoices', logged_in_only: true},
        {to: '/invoices_new', text: 'New Invoices To Create', logged_in_only: true},
        {to: '/vendor_payment_reports', text: 'VPR Management', logged_in_only: true},
        {to: '/vendor_payment_reports', text: 'Historical Invoices', logged_in_only: true}
      ]
    }
  },
  computed: {
    logged_in_items() {
      return this.menu_items.filter(x => x.logged_in_only);
    },
    ...mapGetters('firebase', [
      'user',
      'is_logged_in'
    ])
  },
  methods: {
    logout_click: async function () {
      await this.logout();
      this.$router.push({name: 'home'})
    },
    ...mapActions('firebase', [
      'logout'
    ]),
    ...mapActions('reports', [
      'load_brands',
      'load_providers'
    ])
  },
  mounted() {
    if (this.is_logged_in) {
      this.load_brands();
      this.load_providers();
    }
  }
};
</script>

<style>

.v-toolbar__content, .v-toolbar__extension {
 /* padding: 0px 0px !important; */
}

</style>
