import firebase from 'firebase'

firebase.initializeApp({
    apiKey: process.env.VUE_APP_FB_API_KEY,
    authDomain: process.env.VUE_APP_FB_AUTH_DOMAIN,
    projectId: process.env.VUE_APP_FB_PROJECT_ID,
    storageBucket: process.env.VUE_APP_FB_STORAGE_BUCKET,
    messagingSenderId: process.env.VUE_APP_FB_MESSAGING_SENDER_ID,
    appId: process.env.VUE_APP_FB_APP_ID
});


// export utils/refs
export const auth = firebase.auth()
export const google_login = async function () {
    const provider = new firebase.auth.GoogleAuthProvider();
    try {
        const {user} = await auth.signInWithPopup(provider);
        if (user.email.includes('@comparepower.com')) {
            return {
                displayName: user.displayName,
                photoURL: user.photoURL,
                email: user.email,
                access_token: user.za,
                refresh_token: user.refreshToken
            };
        }
        else {
            throw new Error('You do not have access to this application');
        }
    }
    catch (error) {
        const errorCode = error.code;
        const errorMessage = error.message;
        const email = error.email;
        const credential = error.credential;
        console.error(errorCode, errorMessage, email, credential);
        throw error;
    }
}
export const sign_out = async function () {
    try {
        await firebase.auth().signOut()
    }
    catch (error) {
        console.log(error)
    }
}
export const is_logged_in = function () {
    return auth.currentUser ? true : false;
}