<template>
  <v-card>
    <v-card-title>Customer Selection</v-card-title>
    <v-card-text>
      <v-autocomplete v-if="customers" v-model="selected_customer" :loading="customers_loading" :items="customers"
                      item-text="DisplayName" item-value="Id" return-object label="Select a customer"
                      clearable></v-autocomplete>
    </v-card-text>
    <v-card-actions>
      <v-btn :disabled="!has_selected_customer" color="primary" to="next_step">Create Invoice</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
  name: "CustomerSelection",
  computed: {
    selected_customer: {
      get() {
        return this.$store.state.quickbooks.selected_customer;
      },
      set(value) {
        this.$store.dispatch('quickbooks/set_selected_customer', value)
      }
    },
    ...mapGetters('quickbooks', [
      'customers',
      'customers_loading',
      'has_selected_customer',
    ])
  },
  mounted() {
    this.load_customers();
  },
  methods: {
    ...mapActions('quickbooks', [
      'load_customers'
    ])
  }
}
</script>

<style scoped>

</style>