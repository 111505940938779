var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[(_vm.header_mappings.length)?_c('v-data-table',{attrs:{"items":_vm.header_mappings,"headers":_vm.headers,"options":_vm.pagination_settings},on:{"update:options":function($event){_vm.pagination_settings=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('div',{staticStyle:{"position":"relative"}},[_c('v-btn',{attrs:{"color":"primary","to":"/header_mappings/new"}},[_vm._v(" Add new ")])],1)]},proxy:true},{key:"item.headers",fn:function(ref){
var item = ref.item;
return [_c('v-dialog',{attrs:{"max-width":"500px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-badge',{attrs:{"color":"primary","content":item.headers.length,"overlap":"","bordered":""}},[_c('v-btn',_vm._g(_vm._b({attrs:{"color":"secondary","outlined":""}},'v-btn',attrs,false),on),[_vm._v(" View ")])],1)]}}],null,true)},[_c('v-card',[_c('v-card-title',[_vm._v("Headers")]),(item.headers)?_c('v-data-table',{attrs:{"headers":[{text: 'Value', value: 'value'}],"items":item.headers.map(function (x){ return ({key: x, value: x}); })}}):_vm._e()],1)],1)]}},{key:"item.date_created",fn:function(ref){
var item = ref.item;
return [(item.date_created)?_c('span',[_vm._v(" "+_vm._s(_vm._f("moment")(item.date_created,'MM/DD/YYYY h:mm a'))+" ")]):_vm._e()]}},{key:"item.date_last_modified",fn:function(ref){
var item = ref.item;
return [(item.date_last_modified)?_c('span',[_vm._v(" "+_vm._s(_vm._f("moment")(item.date_last_modified,'MM/DD/YYYY h:mm a'))+" ")]):_vm._e()]}},{key:"item.mappings",fn:function(ref){
var item = ref.item;
return [_c('v-dialog',{attrs:{"max-width":"500px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(item.mappings)?_c('v-badge',{attrs:{"color":"primary","content":item.mappings ? Object.keys(item.mappings).length : null,"overlap":"","bordered":""}},[_c('v-btn',_vm._g(_vm._b({attrs:{"color":"secondary","outlined":""}},'v-btn',attrs,false),on),[_vm._v(" View ")])],1):_vm._e()]}}],null,true)},[_c('v-card',[_c('v-card-title',[_vm._v("Mappings")]),(item.mappings)?_c('v-data-table',{attrs:{"headers":[{text: 'Header',value: 'key'}, {text: 'Our Value',value: 'value'}],"items":Object.entries(item.mappings).map(function (x){ return ({key: x[0], value: x[1]}); })}}):_vm._e()],1)],1)]}},{key:"item.static_mappings",fn:function(ref){
var item = ref.item;
return [_c('v-dialog',{attrs:{"max-width":"500px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(item.static_mappings)?_c('v-badge',{attrs:{"color":"primary","content":item.static_mappings ? Object.keys(item.static_mappings).length : null,"overlap":"","bordered":""}},[_c('v-btn',_vm._g(_vm._b({attrs:{"color":"secondary","outlined":""}},'v-btn',attrs,false),on),[_vm._v(" View ")])],1):_vm._e()]}}],null,true)},[_c('v-card',[_c('v-card-title',[_vm._v("Static Mappings")]),(item.static_mappings)?_c('v-card-text',_vm._l((Object.entries(item.static_mappings)),function(ref){
var key = ref[0];
var value = ref[1];
return _c('v-list-item',{key:key,attrs:{"two-line":""}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(key))]),_c('v-list-item-subtitle',[_vm._v(_vm._s(value))])],1)],1)}),1):_vm._e()],1)],1)]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"icon":"","color":"secondary","to":("/header_mappings/" + (item._id) + "?copy=true")}},[_c('v-icon',[_vm._v("mdi-content-copy")])],1),_c('v-btn',{attrs:{"icon":"","color":"secondary","to":("/header_mappings/" + (item._id)),"disabled":item.is_in_use}},[_c('v-icon',[_vm._v("mdi-pencil")])],1),_c('v-btn',{attrs:{"icon":"","color":"error","disabled":item.is_in_use},on:{"click":function($event){return _vm.delete_mapping(item)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1)]}}],null,false,1750254942)}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }