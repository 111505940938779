<template>
  <v-tooltip top transition="scale-transition" v-if="file_name">
    <template v-slot:activator="{on, attrs}">
      <v-btn icon dark color="secondary" v-on="on" v-bind="attrs" target="_blank"
             :href="`https://drive.google.com/file/d/${file_id}/edit`">
        <v-icon>mdi-file</v-icon>
      </v-btn>
    </template>
    {{ file_name }}
  </v-tooltip>
  <v-btn v-else icon dark color="secondary" target="_blank" :href="`https://drive.google.com/file/d/${file_id}/edit`">
    <v-icon>mdi-file</v-icon>
  </v-btn>
</template>

<script>
export default {
  name: "GoogleFileLink",
  props: {
    file_name: String,
    file_id: String
  }
}
</script>

<style scoped>

</style>