<template>
  <v-dialog width="500" v-model="dialog">
    <template v-slot:activator="{on: dialog, attrs}">
      <v-tooltip top>
        <template v-slot:activator="{on: tooltip}">
          <v-btn icon color="error" v-on="{...tooltip,...dialog}" v-bind="attrs">
            <v-icon>mdi-trash-can</v-icon>
          </v-btn>
        </template>
        <span>Delete</span>
      </v-tooltip>
    </template>
    <template v-slot:default="dialog">
      <v-card>
        <v-card-title>
          Delete invoice {{ invoice.DocNumber }}?
        </v-card-title>
        <v-card-subtitle>
          Total: {{ invoice.TotalAmt | currency }}
        </v-card-subtitle>
        <v-simple-table>
          <thead>
          <tr>
            <th>Desc</th>
            <th>Amount</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="line in invoice.Line" :key="line.Id">
            <td>{{ line.Description }}</td>
            <td>{{ line.Amount | currency }}</td>
          </tr>
          </tbody>
        </v-simple-table>
        <v-card-text v-if="invoice.reports.length">
          <h3 class="error--text">What do you want to do with the existing report?</h3>
          <v-select v-model="delete_invoice_options" :items="options" item-value="props" item-text="label"></v-select>
        </v-card-text>
        <v-card-actions>
          <v-btn color="error" :disabled="delete_records === null"
                 @click="delete_invoice({invoice: invoice, delete_invoice_options: delete_invoice_options})">
            Delete
          </v-btn>
          <v-btn color="primary" @click="dialog.value = false">Cancel</v-btn>
        </v-card-actions>
      </v-card>
    </template>
  </v-dialog>
</template>

<script>
import {mapActions} from "vuex";

export default {
  name: "InvoiceDeleteDialog",
  props: {
    invoice: Object
  },
  data() {
    return {
      dialog: false,
      options: [
        {
          label: 'Delete report records and set to reprocess',
          props: {
            delete_records: true,
            reprocess: true
          }
        },
        {
          label: 'Delete report records with no reprocessing',
          props: {
            delete_records: true,
            reprocess: false
          }
        },
        {
          label: 'Put report records back to not invoiced',
          props: {
            delete_records: false,
            reprocess: false
          }
        }
      ],
      delete_invoice_options: {
        delete_records: true,
        reprocess: true
      },
    }
  },
  methods: {
    ...mapActions('quickbooks', [
      'delete_invoice'
    ]),
  }
}
</script>

<style scoped>

</style>