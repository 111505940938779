var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"transition":"dialog-bottom-transition","max-width":"70vw","retain-focus":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var dialog = ref.on;
var attrs = ref.attrs;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"error"}},'v-btn',attrs,false),Object.assign({}, tooltip,dialog)),[_c('v-icon',[_vm._v("mdi-alert-circle")])],1)]}}],null,true)},[_c('span',[_vm._v("Take Action")])])]}},{key:"default",fn:function(dialog){return [_c('v-card',[_c('v-card-title',[_vm._v("Take action on "+_vm._s(_vm.item.brand)+" "+_vm._s(_vm._f("currency")(_vm.item.total)))]),_c('v-card-text',[_c('v-autocomplete',{attrs:{"items":_vm.options.filter(function (x){ return x.active; }),"item-text":"text","item-value":"val","placeholder":"Select an action..."},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(item.text)}}),_c('v-list-item-subtitle',{domProps:{"textContent":_vm._s(item.desc)}})],1)]}}],null,true),model:{value:(_vm.action),callback:function ($$v) {_vm.action=$$v},expression:"action"}}),(_vm.show_note)?_c('v-textarea',{attrs:{"placeholder":"Enter a note"},model:{value:(_vm.note),callback:function ($$v) {_vm.note=$$v},expression:"note"}}):_vm._e(),(_vm.item.invoice)?_c('div',[_c('h3',{staticClass:"error--text"},[_vm._v("This item has an invoice associated with it")]),_c('p',[_vm._v(" Id: "+_vm._s(_vm.item.group.invoice.Id)+" Number:"+_vm._s(_vm.item.group.invoice.DocNumber)+" ")])]):_vm._e()],1),(_vm.action)?_c('v-card-actions',[_c('v-btn',{attrs:{"color":"error","disabled":_vm.disable_action},on:{"click":_vm.take_action}},[_vm._v(" "+_vm._s(_vm.action.button_text)+" ")]),_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){dialog.value = false}}},[_vm._v(" Cancel ")])],1):_vm._e()],1)]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}})}
var staticRenderFns = []

export { render, staticRenderFns }