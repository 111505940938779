<template>
  <div>
    <v-treeview :active.sync="active" @update:active="active_update" color="secondary" :items="items"
                :load-children="load_children" item-key="key" item-disabled="disabled" activatable return-object>
      <template v-slot:prepend="{item, leaf, open, active}">
        <v-icon v-if="item.children">
          {{ open ? 'mdi-folder-open' : 'mdi-folder' }}
        </v-icon>
        <v-icon v-else>
          {{ active ? 'mdi-file' : 'mdi-file-outline' }}
        </v-icon>
      </template>
      <template v-slot:append="{item, leaf, open}">
        <v-btn v-if="!leaf && open" icon dark color="secondary" @click="load_children(item)">
          <v-icon>mdi-refresh</v-icon>
        </v-btn>
      </template>
      <template v-slot:label="{item}">
        <span :title="item.name">{{item.name}}</span>
      </template>
    </v-treeview>
  </div>
</template>

<script>
import file_processor_service from '../../services/file_processor.service'

export default {
  name: "FileChooser",
  props: {
    active_update: Function,
    open_update: Function
  },
  data() {
    return {
      items: [],
      active: []
    }
  },
  async mounted() {
    this.active = this.selected;
    const folders = await file_processor_service.folders.all();
    this.items = folders.map(x => ({
      ...x,
      key: x.id,
      children: []
    }));
  },
  methods: {
    async load_children(item) {
      try {
        const children = await file_processor_service.folders.get_files_by_id(item.folder_id || item.id);
        item.children = children.map(x => {
          let result = {
            ...x, key: x.id
          };
          if (x.mimeType === 'application/vnd.google-apps.folder') {
            result.children = [];
          }
          return result;
        })
      }
      catch (err) {
        item.children = [];
      }
    }
  }
}
</script>

<style scoped>

</style>