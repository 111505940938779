<template>
  <v-data-table v-if="has_invoices" :loading="invoices_loading" :headers="invoice_headers" :items="invoices"
                item-key="Id" show-expand single-expand>
    <template v-slot:item.TotalAmt="{item}">
      {{ item.TotalAmt | currency }}
    </template>
    <template v-slot:item.Balance="{item}">
      {{ item.Balance | currency }}
    </template>
    <template v-slot:item.custom_field="{item}">
      <span :class="{'red--text': item.has_match}">{{ item.custom_field }}</span>
    </template>
    <template v-slot:item.MetaData.CreateTime="{item}">
      {{ item.MetaData.CreateTime | moment('YYYY-MM-DD h:mm a') }}
    </template>
    <template v-slot:item.MetaData.LastUpdatedTime="{item}">
      {{ item.MetaData.LastUpdatedTime | moment('YYYY-MM-DD h:mm a') }}
    </template>
    <template v-slot:item.reports="{item}">
      <div v-if="item.reports && item.reports.length">
        <invoice-clear-dialog :invoice="item"></invoice-clear-dialog>
        <google-file-link v-for="report in item.reports" :key="report.file_id" :file_id="report.file_id"
                          :file_name="report.file_name"></google-file-link>
        <item-compare-dialog :invoice_id="item.Id" :groups="selected_items.map(x=> x.group)"></item-compare-dialog>
      </div>
    </template>
    <template v-slot:expanded-item="{headers, item}">
      <td :colspan="headers.length" class="ma-0 pa-0">
        <v-simple-table class="grey lighten-4">
          <template v-slot:default>
            <thead>
            <tr>
              <th>Type</th>
              <th>Category</th>
              <th>Description</th>
              <th>Quantity</th>
              <th>Amount</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="line in item.Line" :key="line.Id">
              <td>{{ line.SalesItemLineDetail ? line.SalesItemLineDetail.ItemAccountRef.name : 'NA' }}</td>
              <td>{{ line.SalesItemLineDetail ? line.SalesItemLineDetail.ItemRef.name : 'NA' }}</td>
              <td>{{ line.Description }}</td>
              <td>{{ line.SalesItemLineDetail ? line.SalesItemLineDetail.Qty : 'NA' }}</td>
              <td>{{ line.Amount ? line.Amount : 'NA' | currency }}</td>
            </tr>
            </tbody>
          </template>
        </v-simple-table>
      </td>
    </template>
    <template v-slot:item.actions="{item}">
      <v-tooltip top>
        <template v-slot:activator="{on, attrs}">
          <v-btn icon color="primary" class="mr-2"
                 :disabled="!has_selected_customer || !selected_items_have_items || !invoice_date"
                 @click="append_invoice(item)" v-bind="attrs" v-on="on">
            <v-icon>mdi-note-plus</v-icon>
          </v-btn>
        </template>
        <span>Append</span>
      </v-tooltip>
      <invoice-replace-dialog :disabled="!has_selected_customer || !selected_items_have_items || !invoice_date"
                              :invoice="item"></invoice-replace-dialog>
      <invoice-delete-dialog :invoice="item"></invoice-delete-dialog>
    </template>
  </v-data-table>
</template>

<script>
import {mapGetters, mapActions} from 'vuex'
import InvoiceDeleteDialog from "@/components/payments/InvoiceDeleteDialog";
import InvoiceReplaceDialog from "@/components/payments/InvoiceReplaceDialog";
import ItemCompareDialog from "./ItemCompareDialog";
import InvoiceClearDialog from "./InvoiceClearDialog";
import GoogleFileLink from "../GoogleFileLink";

export default {
  name: "InvoiceSelector",
  components: {
    GoogleFileLink,
    InvoiceClearDialog,
    ItemCompareDialog,
    InvoiceReplaceDialog,
    InvoiceDeleteDialog
  },
  data() {
    return {
      compare_result: null,
      search_text: null,
      invoice_headers: [
        {text: 'Id', value: 'DocNumber'},
        {text: 'Created', value: 'MetaData.CreateTime'},
        {text: 'Last Modified', value: 'MetaData.LastUpdatedTime'},
        {text: 'Invoice Date', value: 'TxnDate'},
        {text: 'Due Date', value: 'DueDate'},
        {text: 'Pay Period Desc', value: 'custom_field'},
        {text: 'Total Amount', value: 'TotalAmt'},
        {text: 'Balance', value: 'Balance'},
        {text: 'Reports', value: 'reports'},
        {text: 'Actions', value: 'actions'}
      ]
    }
  },
  computed: {
    ...mapGetters('reports', [
      'selected_items',
      'selected_items_have_items',
      'invoice_date'
    ]),
    ...mapGetters('quickbooks', [
      'invoices',
      'invoices_loading',
      'is_logged_in',
      'has_invoices',
      'has_selected_customer'
    ])
  },
  methods: {
    ...mapActions('reports', [
      'search',
      'clear_selected_items',
      'clear_date_range'
    ]),
    ...mapActions('quickbooks', [
      'append_invoice',
      'replace_invoice',
      'delete_invoice',
      'clear_invoice'
    ]),
    ...mapActions('stepper', [
      'set_step'
    ])
  }
}
</script>

<style scoped>

</style>
