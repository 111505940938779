<template>

  <div v-if="!qb_authorized" class="" >
    <div class="mt-10">
      <v-layout align-center justify-center column fill-height>
        <v-flex row align-center>
          <b class="ml-3 warning-display">User is not authorized in quickbooks. Please refresh the page and login to quickbooks and try again..</b>
        </v-flex>
      </v-layout>
    </div>
  </div>

  <div v-else>

    <v-container fluid class="mt-3 mb-0 pb-0">
      <v-row no-gutters>
        <b class="ml-3 mb-1">Reports Filter</b>
      </v-row>
      <v-row align="center" no-gutters class="mt-0 pt-0 mb-0 pb-0">
        <v-col
            class="d-flex mt-0 pt-0 mb-0 pb-0"
            cols="12"
            sm="6">
          <v-select hide-details class="mb-2"
                    v-model="report_valid_filter_selected_item"
                    :items="report_valid_filters"
                    label="Reports"
                    solo
                    @change="report_valid_filter_selected"></v-select>
        </v-col>
      </v-row>
    </v-container>

    <div class="mt-0 pt-0">

      <div class="mt-5" v-if ="is_loading_data">
        <v-layout align-center justify-center column fill-height>
          <v-flex row align-center>
            <v-progress-circular indeterminate :size="50" color="primary" class=""></v-progress-circular>
            <b class="ml-3">Loading...</b>
          </v-flex>
        </v-layout>
      </div>

      <v-data-table v-if ="!is_loading_data"
        :headers="headers"
        :items="vendor_reports"
        item-key="original_file_id"
        class="elevation-1 mt-1"
        :search="search">
      <template v-slot:top>
        <v-text-field
            v-model="search"
            label="Search"
            class="mx-4"
        ></v-text-field>
      </template>
<!--      <template v-slot:body.append>-->
<!--        <tr>-->
<!--          <td></td>-->
<!--          <td>-->
<!--            <v-text-field-->
<!--                v-model="calories"-->
<!--                type="number"-->
<!--                label="Less than"-->
<!--            ></v-text-field>-->
<!--          </td>-->
<!--          <td colspan="4"></td>-->
<!--        </tr>-->
<!--      </template>-->

      <template v-slot:item.original_file_name="{ item }">
        <v-expansion-panels>
          <v-expansion-panel
              @click="panel_change($event, item)">
              <v-expansion-panel-header>
                {{item.original_file_name}}
              </v-expansion-panel-header>
              <v-expansion-panel-content>

                <v-card>
                  <v-card-title class="pt-0 pb-0 mt-0 mb-0" >{{item.original_file_name}}</v-card-title>
                  <v-card-text class="mt-0 pt-1">
                    <v-container fluid>
                      <v-row>
                        <v-col cols="6" md="4">

                          <v-row>
                            <b>Report Links</b>
                          </v-row>
                          <v-row>
                            <div style="display: inline-block" >
                              <a :href="item.original_file_info ? item.original_file_info.cloud_storage_path : null" class="mt-2">
                                {{ item.original_file_info ? item.original_file_info.cloud_storage_path : null }}
                              </a>
                              <a :href="item.original_file_info ? item.original_file_info.extracted_cloud_storage_path : null" class="mt-2">
                                {{ item.original_file_info ? item.original_file_info.extracted_cloud_storage_path : null }}
                              </a>
                              <a :href="item.original_file_info ? item.original_file_info.cleaned_cloud_storage_path : null" class="mt-2">
                                {{ item.original_file_info ? item.original_file_info.cleaned_cloud_storage_path : null }}
                              </a>
                            </div>
                          </v-row>
                          <v-row class="mt-4">
                            <b>
                              <v-btn
                                color="red lighten-2"
                                dark
                                @click ="view_vendor_payment_report_metrics(item)">
                              View Report Details
                            </v-btn>
                            </b>
                          </v-row>
                          <v-row>
                          </v-row>

                        </v-col>
                        <v-col>
                          <v-card class="ml-3">
                            <v-card-title class ="mt-1 py-0">
                             Report Details
                            </v-card-title>
                            <v-card-text v-if="item.original_file_info" class="my-0 py-0">
                              <h4>date created in cloud storage</h4>
                                     {{  item.original_file_info.cloud_meta_data.timeCreated  }}
                            </v-card-text>
                            <v-card-text v-if="item.original_file_info" class="my-0 py-0" >
                              <h4>date_updated in cloud storage</h4>
                              {{  item.original_file_info.cloud_meta_data.timeCreated  }}
                            </v-card-text>

                            <v-card-text v-if="item.original_file_info" class="my-0 py-0" >
                              <h4>date created in google drive</h4>
                              {{  item.original_file_info.meta_data.createdTime  }}
                            </v-card-text>

                            <v-card-text v-if="item.original_file_info" class="my-0 py-0" >
                              <h4>date updated in google drive</h4>
                              {{  item.original_file_info.meta_data.modifiedTime  }}
                            </v-card-text>

                            <v-card-text v-if="item.original_file_info" class="my-0 py-0" >
                              <h4>file Extension</h4>
                              {{  item.original_file_info.fullFileExtension  }}
                            </v-card-text>

                          </v-card>
                        </v-col>
                        <v-col >
                          <v-card class="ml-3" v-if="item.original_file_info">
                            <v-card-title class ="mt-1 py-0">
                              Extracted Files
                            </v-card-title>
                            <v-card-text v-if="item.original_file_info" class="my-0 py-0">
                              <h4>Extracted Files from Report</h4>
                              {{  item.original_file_info.extracted_files.length  }}
                            </v-card-text>
                            <v-card  class ="my-0 py-0"  v-for="(exfile, eindex) of item.original_file_info.extracted_files" v-bind:key="eindex">
                              <v-card-text class ="my-0 py-0">
                                <h4>Extracted File Name</h4>
                                <p class="py-0 my-0">{{  exfile.original_name  }} </p>
                                <p class="py-0 my-0"  v-if ="exfile.original_name === 'Data'">Used for Vendor Report</p>
                              </v-card-text>
                            </v-card>
                            <v-btn class =mt-3>View more Details</v-btn>
                          </v-card>
                        </v-col>
                        <v-col >
                          <v-card class="ml-3">
                            <v-card-title class ="mt-1 py-0">
                              Additional Details
                            </v-card-title>
                          </v-card>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>
                  <v-card-actions>

                    <v-btn v-if="item.is_valid === false" color="secondary" @click="mark_report_as_invalid_prompt(item)" >Mark report as Valid</v-btn>
                    <v-btn v-else color="secondary" @click="mark_report_as_invalid_prompt(item)" >Mark report as Invalid</v-btn>
                    <v-btn color="error" v-if="(item.is_valid === null || item.is_valid === true) && item.invoices.filter(x => x.qb_invoice_id).length > 0" @click="delete_all_invoices_from_report_prompt(item)">Delete all invoices from this report</v-btn>
                  </v-card-actions>
                </v-card>

              </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>
      </template>

    </v-data-table>
    </div>

    <v-dialog
        v-model="dialog"
        max-width="800">
      <v-card>
        <v-card-title class="text-h5">
          File Metrics
        </v-card-title>
        <v-container fluid v-if="selected_vendor_payment_report" >
          <v-row no-gutters>
            <v-col cols="2" class="pr-3">
             <h5>Brands:</h5>
            </v-col>
            <v-col >
               {{ selected_vendor_payment_report.vendor_report_metrics.brands }}
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="2" class="pr-3">
              <h5>Channels:</h5>
            </v-col>
            <v-col >
              {{ selected_vendor_payment_report.vendor_report_metrics.channels }}
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="2" class="pr-3">
              <h5>Payment Amounts:</h5>
            </v-col>
            <v-col >
              {{ selected_vendor_payment_report.vendor_report_metrics.payment_amounts }}
            </v-col>
          </v-row>

          <v-divider class="mt-2 mb-6" ></v-divider>

          <v-row class="ms-1" >

            <v-col cols="6">
              <v-row no-gutters ><h5>Payments by Channel</h5></v-row>
              <v-row no-gutters>
              <v-col>
                <v-container>
                  <v-row no-gutters v-for="(a, index) of selected_vendor_payment_report.vendor_report_metrics.payments_by_channel" :key="index" >
                    <v-col cols="6">
                      <h5>{{a.channel}}</h5>
                    </v-col>
                    <v-col>${{ a.payment_amount }}</v-col>
                  </v-row>
                </v-container>
              </v-col>
              </v-row>
            </v-col>
            <v-col cols="6" >
              <v-row no-gutters><h5>Payments by Brand</h5></v-row>
              <v-row no-gutters>
                <v-col>
                  <v-container>
                    <v-row no-gutters v-for="(a, index) of selected_vendor_payment_report.vendor_report_metrics.payments_by_brand" :key="index" >
                      <v-col cols="6">
                        <h5>{{a.brand}}</h5>
                      </v-col>
                      <v-col>${{ a.payment_amount }}</v-col>
                    </v-row>
                  </v-container>
                </v-col>
              </v-row>
            </v-col>

          </v-row>

          <v-divider class="mt-3 mb-3" ></v-divider>

          <v-row no-gutters class="mb-0 pb-0 mt-0 pb-0" >
            <v-col cols="6">
              <h5>Items Count:</h5>
            </v-col>
            <v-col >
              {{ selected_vendor_payment_report.vendor_report_metrics.items_count }}
            </v-col>
          </v-row>
          <v-row no-gutters class="mb-0 pb-0 mt-0 pb-0">
            <v-col cols="6">
              <h5>Items Count with No Channel:</h5>
            </v-col>
            <v-col >
              {{ selected_vendor_payment_report.vendor_report_metrics.items_count_with_null_channel }}
            </v-col>
          </v-row>
          <v-row no-gutters class="mb-0 pb-0 mt-0 pb-0">
            <v-col cols="6">
              <h5>Items Count with No Brand:</h5>
            </v-col>
            <v-col >
              {{ selected_vendor_payment_report.vendor_report_metrics.items_count_with_null_brand }}
            </v-col>
          </v-row>
          <v-row no-gutters class="mb-0 pb-0 mt-0 pb-0">
            <v-col cols="6">
              <h5>Items Count with No Payment Amount:</h5>
            </v-col>
            <v-col>
              {{ selected_vendor_payment_report.vendor_report_metrics.items_count_with_null_or_zero_payment_amount }}
            </v-col>
          </v-row>
          <v-row no-gutters class="mb-0 pb-0 mt-0 pb-0">
            <v-col cols="6">
              <h5>Items Count with No Payment Key:</h5>
            </v-col>
            <v-col >
              {{ selected_vendor_payment_report.vendor_report_metrics.items_count_with_null_payment_key }}
            </v-col>
          </v-row>

          <v-divider class="mt-3 mb-3" ></v-divider>

          <v-row no-gutters class="mb-0 pb-0 mt-0 pb-0">
            <v-col cols="6">
              <h5>Missing QuickBook Customer</h5>
            </v-col>
            <v-col >
              <span :class="selected_vendor_payment_report.vendor_report_metrics.items_with_missing_customer_qb
                    && selected_vendor_payment_report.vendor_report_metrics.items_with_missing_customer_qb.length === 0 ? 'ok-display' : 'warning-display'">
                {{ selected_vendor_payment_report.vendor_report_metrics.items_with_missing_customer_qb }}
              </span>
            </v-col>
          </v-row>
          <v-row no-gutters class="mb-0 pb-0 mt-0 pb-0">
            <v-col cols="6">
              <h5>Quick Book upfront LineItem exists</h5>
            </v-col>
            <v-col >
              <span :class="selected_vendor_payment_report.vendor_report_metrics.qb_line_item_exists === 'yes' ? 'ok-display' : 'warning-display'">
                {{ selected_vendor_payment_report.vendor_report_metrics.qb_line_item_exists }}
              </span>
            </v-col>
          </v-row>
          <v-row no-gutters class="mb-0 pb-0 mt-0 pb-0">
            <v-col cols="6">
              <h5>Items count with missing quickbook customer</h5>
            </v-col>
            <v-col >
              <span :class="selected_vendor_payment_report.vendor_report_metrics.items_count_with_null_line_item_qb === 0 ? 'ok-display' : 'warning-display'">
                {{ selected_vendor_payment_report.vendor_report_metrics.items_count_with_null_line_item_qb }}
              </span>
            </v-col>
          </v-row>

        </v-container>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialog = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="qb_dialog" max-width="800">
      <v-card v-if="selected_vendor_payment_report && selected_vendor_payment_report.is_loading === false" >
        <v-card-title class="text-h5">
          Invoice Details
        </v-card-title>
        <v-container fluid v-if="selected_vendor_payment_report" >

          <v-simple-table>
            <template v-slot:default>
              <thead>
              <tr>
                <th class="text-left">
                  QB customer
                </th>
                <th class="text-left">
                  Brand
                </th>
                <th class="text-left">
                  Provider
                </th>
                <th class="text-left">
                  QB Invoice Id
                </th>
                <th class="text-left">
                  Total Payment Amount
                </th>
                <th class="text-left">
                  Total Payment Amount in QB
                </th>
                <th>
                  Balance in QB
                </th>
                <th class="text-left">
                  Has Associated Payment
                </th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="item in selected_vendor_payment_report.invoices"
                  :key="item.bq_invoice_id">
                <td>{{ item.qb_customer_name }}</td>
                <td>{{ item.brand }}</td>
                <td>{{ item.provider }}</td>
                <td>{{ item.qb_invoice_id }}</td>
                <td>${{ item.total_payment_amount }}</td>
                <td><span :class="parseFloat(item.qb_invoice_info.TotalAmt) !== parseFloat(item.total_payment_amount) ? 'warning-display': 'ok-display'" v-if="item.qb_invoice_info">${{ item.qb_invoice_info.TotalAmt }}</span></td>
                <td><span v-if="item.qb_invoice_info">${{item.qb_invoice_info.Balance}}</span></td>
                <td>
                  <span class="warning-display" v-if="item.qb_invoice_info && item.qb_invoice_info.payment_info && item.qb_invoice_info.payment_info.length > 0">{{item.qb_invoice_info.LinkedTxn}}</span>
                  <span class="ok-display" v-else>No</span>
                </td>
              </tr>
              </tbody>
            </template>
          </v-simple-table>

          <v-divider class="mt-2 mb-6" ></v-divider>

          <v-row class="ms-2 me-2 ok-display" v-if="selected_vendor_payment_report.is_valid === false">
            Are you sure you want to mark this report as valid?
          </v-row>

          <template v-else >

            <template v-if="selected_vendor_payment_report.type_of_delete === 'mark_as_invalid'">
              <v-row class="ms-2 me-2 warning-display"
                     v-if="selected_vendor_payment_report.invoices && selected_vendor_payment_report.invoices.length > 0">
                Are you sure you want to mark this report as invalid? This report has invoices created in Quick books and those invoices might have associated payments.
                Marking this as invalid will delete all the invoices in quick books and you cannot use this report again unless you make the report valid again.
              </v-row>
              <v-row class="ms-2 me-2 warning-display" v-else>
                Are you sure you want to mark this report as invalid?
                Marking this as invalid will delete all the invoices in quick books and you cannot use this report again unless you make the report valid again.
              </v-row>
            </template>
            <template v-else>
              <v-row class="ms-2 me-2 warning-display">
                Are you sure you want to delete all the invoices generated from this report?
                This report has invoices created in Quick books and those invoices might have associated payments.
              </v-row>
            </template>
          </template>

        </v-container>

        <v-card-actions v-if="selected_vendor_payment_report">
          <v-spacer></v-spacer>
          <template v-if="selected_vendor_payment_report.type_of_delete === 'mark_as_invalid'">
            <v-btn v-if="selected_vendor_payment_report.is_valid === false"
                color="error"
                @click="mark_report_as_invalid(selected_vendor_payment_report, false)">
              Yes Mark Report as valid
              <v-progress-circular v-if="selected_vendor_payment_report.is_async_operation_in_progress" class="ms-6" indeterminate color="Primary"></v-progress-circular>
            </v-btn>
            <v-btn v-else
                   color="error"
                   @click="mark_report_as_invalid(selected_vendor_payment_report, true)">
              Yes Mark Report as Invalid
              <v-progress-circular v-if="selected_vendor_payment_report.is_async_operation_in_progress" class="ms-6" indeterminate color="Primary"></v-progress-circular>
            </v-btn>
          </template>
          <template v-if="selected_vendor_payment_report.type_of_delete === 'delete_all' && selected_vendor_payment_report.invoices.filter(x => x.qb_invoice_id).length > 0">
            <v-btn color="error" @click="delete_all_invoices_from_report(selected_vendor_payment_report, true)">
              Delete all invoices
              <v-progress-circular v-if="selected_vendor_payment_report.is_async_operation_in_progress" class="ms-6" indeterminate color="Primary"></v-progress-circular>
            </v-btn>
          </template>
          <v-btn
              color="secondary"
              @click="qb_dialog = false">
            No, Dont do nothing
          </v-btn>
        </v-card-actions>
      </v-card>
      <v-card v-if="!selected_vendor_payment_report || selected_vendor_payment_report.is_loading" height="100%" >
        <v-container >
          <v-layout align-center justify-center column fill-height class="my-10">
            <v-flex row align-center>
              <v-progress-circular indeterminate :size="50" color="primary" class=""></v-progress-circular>
              <b class="ml-3">Loading...</b>
            </v-flex>
          </v-layout>
        </v-container>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import InvoiceService from "../services/invoices.service";
import {mapGetters} from "vuex";

export default {
  name: "VendorPaymentReports",
  data () {
    return {
      search: '',
      calories: '',
      vendor_reports: [],
      original_invoices: [],
      selected_vendor_payment_report: null,
      dialog: false,
      qb_dialog: false,

      report_valid_filter_selected_item: 'All',
      report_valid_filters: ['All', 'Reports Marked as Valid', 'Reports Marked as InValid'],

      type_of_delete: null,

      is_loading_data: true,
      qb_authorized: true,
    }
  },
  computed: {
    headers () {
      return [
        {
          text: 'Provider',
          align: 'start',
          sortable: false,
          value: 'provider',
        },
        {
          text: 'Report Name',
          align: 'start',
          sortable: false,
          value: 'original_file_name',
        },
      ]
    },
    ...mapGetters('reports', [
      'items',
      'loading',
      'has_selected_items'
    ]),
    ...mapGetters('report_filters', [
      'filters',
      'brands',
      'brands_loading',
      'invoice_options',
    ]),
    ...mapGetters('quickbooks', [
      'items',
      'is_logged_in',
      'realm_id',
      'access_token',
      'has_selected_customer',
      'expiration_time',
      'is_token_expired',
      'invoice_action',
      'matching_invoices',
      'selected_customer'
    ])
  },
  methods: {
    filterOnlyProvider (value, search, item) {
      console.log(item);
      return (value != null &&
          search != null &&
          typeof value === 'string' &&
          value.toString().includes(search));
    },
    getColor (original_file_name) {
       console.log(original_file_name);
       return 'red';
    },
    async panel_change(event, original_file) {

      if(!original_file.original_file_info) {
        let original_file_info = await InvoiceService.original_files.get(original_file.original_file_id);
        if(original_file_info) {
          let extracted_file = original_file_info.extracted_files.find(x => x._id === original_file.extracted_file_id);
          if (extracted_file) {
            original_file_info.extracted_cloud_storage_path = extracted_file.cloud_storage_path;
            original_file_info.cleaned_cloud_storage_path = extracted_file.cleaned_file.cloud_storage_path;
            console.log(extracted_file.cleaned_file);
          }
        }

        original_file.original_file_info = original_file_info;
        console.log(original_file_info);
      }

      if(event.target.classList.contains('v-expansion-panel-header--active')) {
        console.log("Panel is closing/now closed.");
      } else {
        console.log("Panel is opening/now open.");
      }

    },
    view_vendor_payment_report_metrics(vendor_payment_report) {
      this.selected_vendor_payment_report = vendor_payment_report;
      this.dialog = true;
    },
    async mark_report_as_invalid_prompt(vendor_payment_report) {
      this.selected_vendor_payment_report = vendor_payment_report;
      this.selected_vendor_payment_report.type_of_delete = 'mark_as_invalid';
      this.selected_vendor_payment_report.is_loading = true;
      this.qb_dialog = true;
      await this.load_invoices_from_quickbooks(this.selected_vendor_payment_report);
      this.selected_vendor_payment_report.is_loading = false;
    },
    async delete_all_invoices_from_report_prompt(vendor_payment_report) {
      this.selected_vendor_payment_report = vendor_payment_report;
      this.selected_vendor_payment_report.type_of_delete = 'delete_all';
      this.selected_vendor_payment_report.is_loading = true;
      this.qb_dialog = true;
      await this.load_invoices_from_quickbooks(this.selected_vendor_payment_report);
      this.selected_vendor_payment_report.is_loading = false
    },

    async mark_report_as_invalid(selected_vendor_payment_report, mark_vendor_payment_report_invalid) {
      selected_vendor_payment_report.is_async_operation_in_progress = true;
      let result = await InvoiceService.invoices.mark_vendor_payment_report({
        realm_id: this.realm_id,
        access_token: this.access_token,
        original_file_id: selected_vendor_payment_report.original_file_id,
        mark_vendor_payment_report_invalid: mark_vendor_payment_report_invalid
      });
      console.log(result);
      await this.load_data();
    },
    async delete_all_invoices_from_report(selected_vendor_payment_report) {
      selected_vendor_payment_report.is_async_operation_in_progress = true;
      let result = await InvoiceService.invoices.delete_all({
        realm_id: this.realm_id,
        access_token: this.access_token,
        original_file_id: selected_vendor_payment_report.original_file_id,
        delete_all: true
      });
      console.log(result);
      await this.load_data();
    },
    async report_valid_filter_selected() {
      await this.load_data();
    },
    async load_data() {

      this.dialog = this.qb_dialog = false;
      this.selected_vendor_payment_report = null
      this.vendor_reports = [];
      this.is_loading_data = true;

      let invoice_info = await InvoiceService.invoices.get();
      this.invoice_info = invoice_info;
      this.original_invoices = this.invoice_info.invoices;

      if (this.report_valid_filter_selected_item === 'Reports Marked as Valid') {
        let reports_info = this.original_invoices.filter(x => x.is_valid === null || x.is_valid === true).map(x => {
          return {
            original_file_id: x.original_file_id,
            original_file_name: x.original_file_name,
            extracted_file_id: x.extracted_file_id,
            provider: x.provider,
            original_file_info: null,
            vendor_report_metrics: JSON.parse(x.vendor_report_metrics),
            is_valid: true,
            is_loading: true,
            is_async_operation_in_progress: false
          };
        });

        // Creates an array of objects with unique "name" property values.
        let unique_reports = [
          ...new Map(reports_info.map((item) => [item["original_file_id"], item])).values(),
        ];
        this.vendor_reports = unique_reports;
      } else if (this.report_valid_filter_selected_item === 'Reports Marked as InValid') {
        let reports_info = this.original_invoices.filter(x => x.is_valid === false).map(x => {
          return {
            original_file_id: x.original_file_id,
            original_file_name: x.original_file_name,
            extracted_file_id: x.extracted_file_id,
            provider: x.provider,
            original_file_info: null,
            vendor_report_metrics: JSON.parse(x.vendor_report_metrics),
            is_valid: x.is_valid,
            is_loading: true,
            is_async_operation_in_progress: false
          };
        });
        let unique_reports = [
          ...new Map(reports_info.map((item) => [item["original_file_id"], item])).values(),
        ];
        this.vendor_reports = unique_reports;
      } else {
        let reports_info = this.original_invoices.map(x => {
          return {
            original_file_id: x.original_file_id,
            original_file_name: x.original_file_name,
            extracted_file_id: x.extracted_file_id,
            provider: x.provider,
            original_file_info: null,
            vendor_report_metrics: JSON.parse(x.vendor_report_metrics),
            is_valid: true,
            is_loading: true,
            is_async_operation_in_progress: false
          };
        });
        let unique_reports = [
          ...new Map(reports_info.map((item) => [item["original_file_id"], item])).values(),
        ];
        this.vendor_reports = unique_reports;
      }
      this.vendor_reports.forEach(x => {
        x.invoices = this.original_invoices.filter(o => o.original_file_id === x.original_file_id);
      });

      this.is_loading_data = false;
    },
    async load_invoices_from_quickbooks(vendor_payment_report) {
      let qb_invoice_ids = vendor_payment_report.invoices.filter(x => x.qb_invoice_info && x.qb_invoice_id).map(x => x.qb_invoice_id);
      if (qb_invoice_ids && qb_invoice_ids.length) {
        let qb_invoices_promises = qb_invoice_ids.map(i => {
          return InvoiceService.invoices.get_quickbook_invoice_by_id({
            realm_id: this.realm_id,
            access_token: this.access_token,
            qb_invoice_id: i
          });
        });

        try {
          let results = await Promise.allSettled(qb_invoices_promises);
          results.forEach(r => {
            if(r.status === 'fulfilled') {
              let qbi = r.value
              if (qbi.LinkedTxn) {
                qbi.payment_info = qbi.LinkedTxn.filter(x => x.TxnType === 'Payment');
              }
              let invoice = this.selected_vendor_payment_report.invoices.find(x => x.qb_invoice_id === qbi.Id);
              if (invoice) {
                invoice.qb_invoice_info = r;
              }
            }
          });
        } catch (err) {
          this.qb_authorized = !err.toString().includes('401');
        }
      }
    },
  },
  async mounted() {
    await this.load_data();
  }
}
</script>

<style scoped>

a{
  white-space: pre-wrap;
  word-wrap: break-word;
  word-break: break-all;
  white-space: normal;
  display:block;
}

.warning-display {
  color: red;
  font-size: 18px;
  font-weight: 700;
}

.ok-display {
  color: green;
  font-size: 18px;
  font-weight: 700;
}

</style>
