<template>
  <v-container fluid>
    <v-row justify="center">
      <v-col cols="12" md="6">
        <v-card>
          <v-card-title>Allowed Properties</v-card-title>
          <v-card-subtitle>
            <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line
                          hide-details></v-text-field>
          </v-card-subtitle>
          <v-card-actions>
            <v-dialog v-model="dialog" max-width="500px">
              <template v-slot:activator="{on,attrs}">
                <v-btn color="secondary" v-on="on" v-bind="attrs">Add New Property</v-btn>
              </template>
              <v-card>
                <v-card-title>Add new item</v-card-title>
                <v-card-text>
                  <v-form ref="form" v-model="is_valid">
                    <v-text-field v-model="new_item.name" :rules="[rules.required('name'), rules.counter(3)]"
                                  label="Name"></v-text-field>
                    <v-text-field v-model="new_item.desc" :rules="[rules.required('desc'), rules.counter(5)]"
                                  label="Description"></v-text-field>
                    <v-select :items="types" v-model="new_item.type" :rules="[rules.required('name'), rules.counter(3)]"
                              label="Type"></v-select>
                  </v-form>
                </v-card-text>
                <v-card-actions>
                  <v-btn color="secondary" :disabled="!is_valid" @click="$refs.form.validate() && insert_property(new_item)">
                    Add Property
                  </v-btn>
                  <v-btn color="error" @click="dialog = false">Cancel</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>

          </v-card-actions>
          <v-data-table v-if="allowed_properties.length"  :headers="headers" :items="allowed_properties" :options.sync="pagination_settings" :search="search">
            <template v-slot:item.desc="props">
              <v-edit-dialog :return-value.sync="props.item.desc" @save="save(props.item)" @cancel="cancel"
                             cancel-text="Cancel" save-text="Save" large persistent>
                {{ props.item.desc }}
                <template v-slot:input>
                  <v-text-field v-model="props.item.desc" label="Edit" counter></v-text-field>
                </template>
              </v-edit-dialog>
            </template>
            <template v-slot:item.actions="{item}">
              <v-icon @click="delete_property(item)">
                mdi-delete
              </v-icon>
            </template>
          </v-data-table>
        </v-card>

        <v-snackbar v-model="snack.active" :timeout="3000" :color="snack.color">
          {{ snack.text }}
          <template v-slot:action="{ attrs }">
            <v-btn v-bind="attrs" text @click="snack.active = false">
              Close
            </v-btn>
          </template>
        </v-snackbar>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import report_service from '../services/report.service'

export default {
  name: "AllowedProperties",
  data() {
    return {
      footerProps: {'items-per-page-options': [5, 10, 15, 20, -1] },
      dialog: false,
      search: null,
      headers: [
        {text: 'Scope', value: 'scope'},
        {text: 'Name', value: 'name'},
        {text: 'Desc', value: 'desc'},
        {text: 'Type', value: 'type'},
        {text: 'Actions', value: 'actions'}
      ],
      new_item: {},
      types: [
        'Boolean',
        'String',
        'Date',
        'Number'
      ],
      allowed_properties: [],
      snack: {
        active: false,
        color: 'primary',
        text: 'Hot...... Tooooooo hot!!!!'
      },
      is_valid: null,
      rules: {
        required: property => value => !!value || `${property} required`,
        counter: size => value => !!value && value.length >= size || `min ${size} characters`,
        types: value => this.types.includes(value) || `type must be one of ${this.types}`
      }
    }
  },
  computed: {
    pagination_settings: {
      get() {
        return this.$store.getters['pagination_settings/allowed_properties'];
      },
      set(value) {
        this.$store.dispatch('pagination_settings/update_allowed_properties', value);
      }
    }
  },
  methods: {
    async save(property) {
      try {
        await report_service.allowed_properties.update(property._id, property.desc);
        this.snack.active = true;
        this.snack.color = 'success';
        this.snack.text = `Updated property ${property.name}`;
        await this.search_properties()
      }
      catch (err) {
        this.snack.active = true;
        this.snack.color = 'error';
        this.snack.text = `Error updating property ${property.name}: ${err.message}`;
      }
    },
    cancel() {
      this.snack.active = true;
      this.snack.color = 'error';
      this.snack.text = 'Changes were not saved';
    },
    async delete_property(property) {
      try {
        await report_service.allowed_properties.delete(property._id);
        this.snack.active = true;
        this.snack.color = 'warning';
        this.snack.text = `Removed property ${property.name}`;
        await this.search_properties()
      }
      catch (err) {
        this.snack.active = true;
        this.snack.color = 'error';
        this.snack.text = `Error removing property ${property.name}: ${err.message}`;
      }
    },
    async insert_property(property) {
      try {
        await report_service.allowed_properties.insert(property);
        this.snack.active = true;
        this.snack.color = 'success';
        this.snack.text = `Added new property ${property.name}`;
        this.dialog = false;
        await this.search_properties()
      }
      catch (err) {
        this.snack.active = true;
        this.snack.color = 'error';
        this.snack.text = `Error inserting property ${property.name}: ${err.message}`;
      }
    },
    async search_properties() {
      this.allowed_properties = await report_service.allowed_properties.search();
    }
  },
  async mounted() {
    await this.search_properties()
  }
}
</script>

<style scoped>

</style>
