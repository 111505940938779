var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-card',[_c('v-card-title',[_vm._v("Select a file to process")]),_c('v-card-actions',[_c('v-btn',{attrs:{"disabled":!_vm.can_process,"color":"secondary"},on:{"click":_vm.process_selected_file}},[_vm._v("Process File")])],1),_c('v-card-text',[_c('file-chooser',{ref:"fc",attrs:{"active_update":_vm.active_update}})],1)],1)],1),(_vm.jobs)?_c('v-col',{attrs:{"cols":"12","md":"8"}},[(_vm.jobs.length)?_c('v-data-table',{attrs:{"items":_vm.jobs,"headers":_vm.headers,"item-key":"_id","options":_vm.pagination_settings,"show-expand":""},on:{"update:options":function($event){_vm.pagination_settings=$event}},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('v-btn',{attrs:{"icon":""},on:{"click":_vm.load_jobs}},[_c('v-icon',[_vm._v("mdi-refresh")])],1)]},proxy:true},{key:"item.file",fn:function(ref){
var item = ref.item;
return [(item.data.file)?_c('google-file-link',{attrs:{"file_id":item.data.file.id,"file_name":item.data.file.name}}):_vm._e()]}},{key:"item.logs",fn:function(ref){
var item = ref.item;
return [_c('dialog-table',{attrs:{"items":item.data.log}})]}},{key:"item.finished",fn:function(ref){
var item = ref.item;
return [(item.lastFinishedAt)?_c('span',[_vm._v(" "+_vm._s(_vm._f("moment")(item.lastFinishedAt,'from', 'now'))+" ")]):_vm._e()]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [(!item.lastFinishedAt)?_c('v-progress-circular',{attrs:{"color":"primary","indeterminate":""}}):_c('span',[_vm._v("Complete")])]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{staticClass:"ma-0 pa-0",attrs:{"colspan":headers.length}},[_c('v-data-table',{attrs:{"items":item.data.cleaned_files,"headers":_vm.cleaned_file_headers},scopedSlots:_vm._u([{key:"item.generated_file",fn:function(ref){
var item = ref.item;
return [_c('google-file-link',{attrs:{"file_name":item.generated_file.name,"file_id":item.generated_file.id}})]}},{key:"item.duplicates",fn:function(ref){
var item = ref.item;
return [(item.duplicate_headers)?_c('span',[_vm._v(" "+_vm._s(item.duplicate_headers.map(function (x) { return ((x.name) + ":" + (x.index)); }).join(', '))+" ")]):_vm._e()]}},{key:"item.totals",fn:function(ref){
var item = ref.item;
return [(item.total_rows)?_c('span',[_vm._v(" "+_vm._s(item.total_rows.map(function (x) { return x.row; }).join(', '))+" ")]):_vm._e()]}},{key:"item.original_header",fn:function(ref){
var item = ref.item;
return [_c('dialog-table',{attrs:{"items":item.original_header}})]}},{key:"item.cleaned_header",fn:function(ref){
var item = ref.item;
return [_c('dialog-table',{attrs:{"items":item.cleaned_header}})]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('process-file-dialog',{attrs:{"file":item.generated_file}})]}}],null,true)})],1)]}}],null,false,2982009388)}):_vm._e()],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }