<template>
  <v-dialog max-width="800px" v-if="items">
    <template v-slot:activator="{on, attrs}">
      <v-badge color="primary" :content="items.length" overlap bordered>
        <v-btn color="secondary" outlined v-on="on" v-bind="attrs">
          View
        </v-btn>
      </v-badge>
    </template>
    <v-simple-table v-if="items">
      <thead>
      <tr>
        <th>Index</th>
        <th>Value</th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="(item, index) in items" :key="index">
        <td>{{ index }}</td>
        <td>{{ item }}</td>
      </tr>
      </tbody>
    </v-simple-table>
  </v-dialog>
</template>

<script>
export default {
  name: "DialogTable",
  props: {
    items: Array
  }
}
</script>

<style scoped>

</style>