<template>
  <v-card>
    <v-card-text>
      <v-radio-group v-model="use_custom_description" @change="enable_custom">
        <v-radio :value="false" label="Date Range"></v-radio>
        <v-radio :value="true" label="Custom"></v-radio>
      </v-radio-group>
      <v-date-picker v-if="!use_custom_description" no-title width="20vw" range
                     v-model="date_range"></v-date-picker>
      <v-text-field v-if="use_custom_description" v-model="custom_description"
                    placeholder="Enter custom description..." :rules="custom_description_rules"
                    counter="31"></v-text-field>
    </v-card-text>
    <v-card-actions>
      <v-btn :disabled="!description" color="primary" to="/customer_selection">Next</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "PaymentPeriodSelection",
  data() {
    return {
      group_description: null,
      use_custom_description: false,
      custom_description_rules: [
        v => v && v.length <= 31 || 'Max 31 characters'
      ],
      date_opened: false
    }
  },
  computed: {
    ...mapGetters('reports', [
      'has_selected_items',
      'description'
    ]),
    date_range: {
      get() {
        return this.$store.getters["reports/date_range"];
      },
      set(value) {
        this.$store.dispatch('reports/set_date_range', value);
      }
    },
    custom_description: {
      get() {
        return this.$store.getters['reports/custom_description'];
      },
      set(value) {
        this.$store.dispatch('reports/set_custom_description', value);
      }
    },
  },
  methods: {
    enable_custom(e) {
      if (!e) {
        this.custom_description = null;
      }
    },
  }
}
</script>

<style scoped>

</style>