import Vue from 'vue'
import Vuex from 'vuex'
import quickbooks_service from '../../services/quickbooks.service'
import reports_service from '../../services/report.service'

Vue.use(Vuex)

// root state object.
// each Vuex instance is just a single state tree.
const state = {
    realm_id: null,
    access_token: null,
    refresh_token: null,
    customers: [],
    customers_loading: false,
    customers_error: null,
    selected_customer: null,
    items: [],
    items_loading: false,
    items_error: null,
    invoices: [],
    invoices_loading: false,
    invoices_error: null,
    selected_invoice: null,
    expiration_time: null,
    invoice_action: null,
    show_invoice_action: false,
    matching_invoices: null
}

// getters are functions.
const getters = {
    realm_id_store: state => state.realm_id,
    is_logged_in: state => state.access_token !== null,
    realm_id: state => state.realm_id,
    access_token: state => state.access_token,
    customers: state => state.customers,
    customers_loading: state => state.customers_loading,
    customers_error: state => state.customers_error,
    selected_customer: state => state.selected_customer,
    has_selected_customer: state => state.selected_customer !== null,
    items: state => state.items,
    items_loading: state => state.items_loading,
    items_error: state => state.items_error,
    invoices: state => state.invoices,
    has_invoices: state => state.invoices && state.invoices.length,
    invoices_loading: state => state.invoices_loading,
    invoices_error: state => state.invoices_error,
    selected_invoice: state => state.selected_invoice,
    expiration_time: state => state.expiration_time,
    invoice_action: state => state.invoice_action,
    show_invoice_action: state => state.show_invoice_action,
    is_token_expired: (state) => () => {
        if (state.access_token) {
            const expiration_time = new Date(state.expiration_time);
            const expired = expiration_time < new Date();
            return expired;
        }
        else {
            console.info(`No token found`)
            return true;
        }
    },
    matching_invoices: state => state.matching_invoices
}

// actions are functions that cause side effects and can involve
// asynchronous operations.
const actions = {
    async logout({commit}) {
        commit('set_access_token', null);
        commit('set_refresh_token', null);
    },
    async refresh_tokens({commit, getters, state}) {
        if (getters.is_token_expired()) {
            const token_result = await quickbooks_service.refresh_tokens(state.refresh_token);
            commit('set_access_token', token_result.access_token);
            commit('set_refresh_token', token_result.refresh_token);
            let expiration_time = new Date();
            expiration_time.setSeconds(expiration_time.getSeconds() + token_result.expires_in);
            commit('set_expiration_time', expiration_time);
        }
    },
    async create_invoice({state, rootState, rootGetters, dispatch, commit}) {
        await dispatch('refresh_tokens');
        const items = rootState.reports.selected_items.reduce((prev, curr) => prev.concat(...curr.types), []);
        const created_invoice = await quickbooks_service.invoices.create(state.realm_id, state.access_token, state.selected_customer.Id, items, rootGetters["reports/description"], rootGetters['reports/invoice_date'])
        await reports_service.vendor_payment_records.update_report_for_invoice(rootState.reports.selected_items, rootGetters["reports/description"], created_invoice);
        commit('set_invoice_action', 'Created');
        dispatch('load_invoices');
        dispatch('reports/load_brands', {}, {root: true});
    },
    async append_invoice({state, rootState, rootGetters, dispatch, commit}, invoice) {
        await dispatch('refresh_tokens');
        const items = rootState.reports.selected_items.reduce((prev, curr) => prev.concat(...curr.types), []);
        const created_invoice = await quickbooks_service.invoices.update(state.realm_id, state.access_token, state.selected_customer.Id, invoice.Id, invoice.SyncToken, items, rootGetters["reports/description"], rootGetters['reports/invoice_date'], true)
        await reports_service.vendor_payment_records.update_report_for_invoice(rootState.reports.selected_items, rootGetters["reports/description"], created_invoice);
        commit('set_invoice_action', 'Appended');
        dispatch('load_invoices');
        dispatch('reports/load_brands', {}, {root: true});
    },
    async replace_invoice({state, rootState, rootGetters, dispatch, commit}, payload) {
        await dispatch('refresh_tokens');
        const items = rootState.reports.selected_items.reduce((prev, curr) => prev.concat(...curr.types), []);
        const created_invoice = await quickbooks_service.invoices.update(state.realm_id, state.access_token, state.selected_customer.Id, payload.invoice.Id, payload.invoice.SyncToken, items, rootGetters["reports/description"], rootGetters['reports/invoice_date'], false, payload.delete_invoice_options.delete_records, payload.delete_invoice_options.reprocess)
        await reports_service.vendor_payment_records.update_report_for_invoice(rootState.reports.selected_items, rootGetters["reports/description"], created_invoice);
        commit('set_invoice_action', 'Replaced');
        dispatch('load_invoices');
        dispatch('reports/load_brands', {}, {root: true});
    },
    async delete_invoice({state, dispatch, commit}, payload) {
        await dispatch('refresh_tokens');
        await quickbooks_service.invoices.delete(state.realm_id, state.access_token, payload.invoice.Id, payload.invoice.SyncToken, payload.delete_invoice_options.delete_records, payload.delete_invoice_options.reprocess)
        commit('set_invoice_action', 'Deleted');
        dispatch('load_invoices');
    },
    async clear_invoice({state, dispatch}, payload) {
        await dispatch('refresh_tokens');
        await quickbooks_service.invoices.clear(state.realm_id, state.access_token, payload.invoice.Id);
        dispatch('load_invoices');
    },
    async load_customers({commit, state, getters, dispatch}) {
        if (getters.is_logged_in) {
            await dispatch('refresh_tokens');
            commit('set_customers_loading', true);
            commit('clear_customers_error');
            try {
                const customers = await quickbooks_service.customers.search(state.realm_id, state.access_token);
                commit('set_customers', customers);
            }
            catch (err) {
                commit('set_customers_error', err);
            }
            finally {
                commit('set_customers_loading', false);
            }
        }
    },
    async load_items({commit, state, getters, dispatch}) {
        if (getters.is_logged_in) {
            await dispatch('refresh_tokens');
            commit('set_items_loading', true);
            commit('set_items_error', null);
            try {
                const items = await quickbooks_service.items.search(state.realm_id, state.access_token);
                commit('set_items', items);
            }
            catch (err) {
                commit('set_items_error', err);
            }
            finally {
                commit('set_items_loading', false);
            }
        }
    },
    async load_invoices({commit, state, getters, dispatch}) {
        if (getters.is_logged_in && state.selected_customer) {
            await dispatch('refresh_tokens');
            commit('set_invoices_loading', true);
            commit('clear_invoices_error');
            try {
                const invoices = await quickbooks_service.invoices.search(state.realm_id, state.access_token, state.selected_customer.Id);
                commit('set_invoices', invoices);
                dispatch('find_matching_invoices');
            }
            catch (err) {
                commit('set_invoices_error', err);
            }
            finally {
                commit('set_invoices_loading', false);
            }
        }
    },
    find_matching_invoices({commit, state, rootGetters}) {
        if (state.invoices && rootGetters["reports/description"]) {
            commit('clear_matching_invoices');
            let found_invoices = state.invoices.filter((item) => {
                return item.custom_field === rootGetters["reports/description"];
            })
            if (found_invoices && found_invoices.length) {
                found_invoices.forEach(item => {
                    let found = state.invoices.find(invoice => invoice.Id === item.Id)
                    if (found) {
                        found.has_match = true;
                    }
                })
                commit('set_matching_invoices', found_invoices);
            }
        }
    },
    async get_tokens({commit}, params) {
        commit('set_realm_id', params.realm_id);
        try {
            const token_result = await quickbooks_service.get_tokens(params.code, params.realm_id);
            commit('set_access_token', token_result.access_token);
            commit('set_refresh_token', token_result.refresh_token);
            let expiration_time = new Date();
            expiration_time.setSeconds(expiration_time.getSeconds() + token_result.expires_in);
            commit('set_expiration_time', expiration_time);
        }
        catch (err) {
            console.error(err);
            throw err;
        }
    },
    async get_tokens_v1({commit}, params) {
        commit('set_realm_id', params.realm_id);
        try {
            const token_result = await quickbooks_service.get_tokens_v1(params.code, params.realm_id);
            commit('set_access_token', token_result.access_token);
            commit('set_refresh_token', token_result.refresh_token);
            let expiration_time = new Date();
            expiration_time.setSeconds(expiration_time.getSeconds() + token_result.expires_in);
            commit('set_expiration_time', expiration_time);
        }
        catch (err) {
            console.error(err);
            throw err;
        }
    },
    set_selected_customer({commit, dispatch}, selected_customer) {
        commit('set_selected_customer', selected_customer);
        dispatch('load_invoices');
    },
    clear_selected_customer({commit}) {
        commit('clear_selected_customer');
    },
    hide_invoice_action({commit}) {
        commit('hide_invoice_action');
    },
    update_selected_item({commit}, params) {
        commit('update_selected_item', params);
    }
}


// mutations are operations that actually mutate the state.
// each mutation handler gets the entire state tree as the
// first argument, followed by additional payload arguments.
// mutations must be synchronous and can be recorded by plugins
// for debugging purposes.
const mutations = {
    set_customers(state, customers) {
        state.customers = customers;
    },
    set_selected_customer(state, selected_customer) {
        state.selected_customer = selected_customer;
    },
    clear_selected_customer(state) {
        state.selected_customer = null;
    },
    set_customers_loading(state, loading) {
        state.customers_loading = loading
    },
    set_customers_error(state, error) {
        state.customers_error = error;
    },
    clear_customers_error(state) {
        state.customers_error = null;
    },
    set_invoices(state, invoices) {
        state.invoices = invoices;
    },
    set_invoices_loading(state, loading) {
        state.invoices_loading = loading
    },
    set_invoices_error(state, error) {
        state.invoices_error = error;
    },
    clear_invoices_error(state) {
        state.invoices_error = null;
    },
    set_realm_id(state, realm_id) {
        state.realm_id = realm_id;
    },
    set_access_token(state, access_token) {
        state.access_token = access_token;
    },
    set_refresh_token(state, refresh_token) {
        state.refresh_token = refresh_token;
    },
    set_expiration_time(state, expiration_time) {
        state.expiration_time = expiration_time;
    },
    set_invoice_action(state, action) {
        state.invoice_action = action;
        state.show_invoice_action = true;
    },
    hide_invoice_action(state) {
        state.show_invoice_action = false;
    },
    clear_matching_invoices(state) {
        state.matching_invoices = null;
    },
    set_matching_invoices(state, matching_invoice) {
        state.matching_invoices = matching_invoice;
    },
    set_items(state, items) {
        state.items = items;
    },
    set_items_loading(state, items_loading) {
        state.items_loading = items_loading;
    },
    set_items_error(state, items_error) {
        state.items_error = items_error;
    }
}

// A Vuex instance is created by combining the state, mutations, actions,
// and getters.
export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
