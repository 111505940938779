import axios from "axios";
import {auth} from "../plugins/firebase";
import qs from "qs";

export default {
    invoices: {
        get: async function ({brand, has_invoice_number}) {
            try {

                const token = await auth.currentUser.getIdToken();
                let {data} = await axios.get(`http://localhost:3002/api/reports/invoices`,{
                    headers: {
                        Authorization: `Bearer ${token}`
                    },
                    params: {
                        brand: brand,
                        has_invoice_number: has_invoice_number
                    },
                    paramsSerializer: params => qs.stringify(params, {indices: false}),
                });
                return data;
            }
            catch (err) {
                console.error(err);
                return null
            }
        },
        delete: async function ({realm_id, access_token, bq_invoice_id, qb_invoice_id, original_file_id}) {
            try {
                const token = await auth.currentUser.getIdToken();
                const response = await axios.delete(`${process.env.VUE_APP_API_URL}reports/invoices/`,
                    {
                        params: {
                            realm_id: realm_id,
                            qb_invoice_id: qb_invoice_id,
                            original_file_id: original_file_id,
                            bq_invoice_id: bq_invoice_id
                        },
                        headers: {
                            Authorization: `Bearer ${token}`,
                            QB_AUTH: `Bearer ${access_token}`
                        }
                    });
                const invoice = response.data.Invoice;
                return invoice;
            }
            catch (err) {
                console.error(err);
                throw err;
            }
        },
        mark_vendor_payment_report: async function ({realm_id, access_token, mark_vendor_payment_report_invalid, original_file_id}) {
            try {
                const token = await auth.currentUser.getIdToken();
                const response = await axios.delete(`${process.env.VUE_APP_API_URL}reports/invoices/`,
                    {
                        params: {
                            realm_id: realm_id,
                            mark_vendor_payment_report_invalid: mark_vendor_payment_report_invalid,
                            original_file_id: original_file_id
                        },
                        headers: {
                            Authorization: `Bearer ${token}`,
                            QB_AUTH: `Bearer ${access_token}`
                        }
                    });
                return response.data;
            }
            catch (err) {
                console.error(err);
                throw err;
            }
        },
        delete_all: async function ({realm_id, access_token, original_file_id}) {
            try {
                const token = await auth.currentUser.getIdToken();
                const response = await axios.delete(`${process.env.VUE_APP_API_URL}reports/invoices/`,
                    {
                        params: {
                            realm_id: realm_id,
                            original_file_id: original_file_id,
                            delete_all: true
                        },
                        headers: {
                            Authorization: `Bearer ${token}`,
                            QB_AUTH: `Bearer ${access_token}`
                        }
                    });
                return response.data;
            }
            catch (err) {
                console.error(err);
                throw err;
            }
        },
        get_quickbook_invoice_by_id: async function ({realm_id, access_token, qb_invoice_id}) {
            try {
                const token = await auth.currentUser.getIdToken();
                const response = await axios.get(`${process.env.VUE_APP_API_URL}quickbooks/${realm_id}/invoices/${qb_invoice_id}`,
                    {
                        headers: {
                            Authorization: `Bearer ${token}`,
                            QB_AUTH: `Bearer ${access_token}`
                        }
                    });
                const invoice = response.data.Invoice;
                return invoice;
            }
            catch (err) {
                console.error(err);
                throw err;
            }
        },
    },
    original_files: {
        get: async function (original_file_id) {
            try {
                const token = await auth.currentUser.getIdToken();
                let {data} = await axios.get(`https://files.processor.dev.comparepower.com/api/original_files/${original_file_id}`,{
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                return data;
            }
            catch (err) {
                console.error(err);
                return null
            }
        },
    }
}
