<template>
  <v-dialog width="500" v-model="dialog">
    <template v-slot:activator="{on: dialog, attrs}">
      <v-tooltip top>
        <template v-slot:activator="{on: tooltip}">
          <v-btn v-if="invoice.reports && invoice.reports.length" icon color="error"
                 v-on="{...tooltip, ...dialog}" v-bind="attrs">
            <v-icon>mdi-trash-can</v-icon>
          </v-btn>
        </template>
        <span>Clear Reports</span>
      </v-tooltip>
    </template>
    <template v-slot:default="dialog">
      <v-card>
        <v-card-title>
          Remove reports from invoice
        </v-card-title>
        <v-card-text>
          <h3 class="error--text">By clicking yes it will remove the reports from the invoice and move them back into the queue for processing.</h3>
        </v-card-text>
        <v-card-actions>
          <v-btn color="error" @click="clear_invoice({invoice: invoice})">
            Yes, Delete
          </v-btn>
          <v-btn color="primary" @click="dialog.value = false">No, Cancel</v-btn>
        </v-card-actions>
      </v-card>
    </template>
  </v-dialog>
</template>

<script>
import {mapActions} from "vuex";

export default {
  name: "InvoiceClearDialog",
  props: {
    invoice: Object
  },
  data() {
    return {
      dialog: false
    }
  },
  methods: {
    ...mapActions('quickbooks', [
      'clear_invoice'
    ])
  }
}
</script>

<style scoped>

</style>