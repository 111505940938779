import axios from "axios";
import {auth} from "../plugins/firebase";

export default {
    jobs: {
        search: async function (statuses) {
            try {
                const token = await auth.currentUser.getIdToken();
                let {data} = await axios.get(`${process.env.VUE_APP_API_URL}jobs/file_processor`, {
                    params: {
                        statuses: statuses
                    },
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                return data;
            }
            catch (err) {
                console.error(err);
                return null
            }
        },
        get_by_id: async function (id) {
            try {
                const token = await auth.currentUser.getIdToken();
                let {data} = await axios.get(`${process.env.VUE_APP_API_URL}jobs/file_processor/${id}`, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                return data;
            }
            catch (err) {
                console.error(err);
                return null
            }
        }
    },
    process: {
        file: async function ({file_id, ...rest}) {
            try {
                const token = await auth.currentUser.getIdToken();
                let {data} = await axios.post(`${process.env.VUE_APP_API_URL}jobs/file_processor/process/file/${file_id}`,
                    null,
                    {
                        params: rest,
                        headers: {
                            Authorization: `Bearer ${token}`
                        }
                    });
                return data;
            }
            catch (err) {
                console.error(err);
                return null
            }
        },
        folder: async function (id) {
            try {
                const token = await auth.currentUser.getIdToken();
                let {data} = await axios.post(`${process.env.VUE_APP_API_URL}jobs/file_processor/process/folder/${id}`,
                    null,
                    {
                        headers: {
                            Authorization: `Bearer ${token}`
                        }
                    });
                return data;
            }
            catch (err) {
                console.error(err);
                return null
            }
        },
        all: async function () {
            try {
                const token = await auth.currentUser.getIdToken();
                let {data} = await axios.post(`${process.env.VUE_APP_API_URL}jobs/file_processor/process/all`,
                    null,
                    {
                        headers: {
                            Authorization: `Bearer ${token}`
                        }
                    });
                return data;
            }
            catch (err) {
                console.error(err);
                return null
            }
        }
    },
    folders: {
        all: async function () {
            try {
                const token = await auth.currentUser.getIdToken();
                let {data} = await axios.get(`${process.env.VUE_APP_API_URL}jobs/file_processor/folders`, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                return data;
            }
            catch (err) {
                console.error(err);
                return null
            }
        },
        get_by_id: async function (id) {
            try {
                const token = await auth.currentUser.getIdToken();
                let {data} = await axios.get(`${process.env.VUE_APP_API_URL}jobs/file_processor/folders/${id}`, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                return data;
            }
            catch (err) {
                console.error(err);
                return null
            }
        },
        get_files_by_id: async function (id) {
            try {
                const token = await auth.currentUser.getIdToken();
                let {data} = await axios.get(`${process.env.VUE_APP_API_URL}jobs/file_processor/folders/${id}/files`, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                return data;
            }
            catch (err) {
                console.error(err);
                return null
            }
        }
    }
}