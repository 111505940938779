import axios from "axios";
import {auth} from "../plugins/firebase";
import qs from "qs";

export default {
    brands: {
        search: async function (query) {
            try {
                const token = await auth.currentUser.getIdToken();
                let {data} = await axios.get(`${process.env.VUE_APP_API_URL}brands`, {
                    params: query,
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                return data;
            }
            catch (err) {
                console.error(err);
                throw err;
            }
        },
        update: async function (brand) {
            try {
                const token = await auth.currentUser.getIdToken();
                let {data} = await axios.patch(`${process.env.VUE_APP_API_URL}brands/${brand._id}`,
                    {
                        desc: brand.desc,
                        active: brand.active,
                        provider: brand.provider
                    },
                    {
                        headers: {
                            Authorization: `Bearer ${token}`
                        }
                    });
                return data;
            }
            catch (err) {
                console.error(err);
                throw err;
            }
        },
        insert: async function (brand) {
            try {
                const token = await auth.currentUser.getIdToken();
                let {data} = await axios.post(`${process.env.VUE_APP_API_URL}brands`,
                    brand,
                    {
                        headers: {
                            Authorization: `Bearer ${token}`
                        }
                    });
                return data;
            }
            catch (err) {
                console.error(err);
                throw err;
            }
        }
    },
    providers: {
        search: async function (query) {
            try {
                const token = await auth.currentUser.getIdToken();
                let {data} = await axios.get(`${process.env.VUE_APP_API_URL}providers`, {
                    params: query,
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                return data;
            }
            catch (err) {
                console.error(err);
                throw err;
            }
        }
    },
    allowed_properties: {
        search: async function () {
            try {
                const token = await auth.currentUser.getIdToken();
                let {data} = await axios.get(`${process.env.VUE_APP_API_URL}reports/allowed_properties`, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                return data.map(x => ({
                    text: x.name.split('_').join(' '),
                    ...x
                }));
            }
            catch (err) {
                console.error(err);
                throw err;
            }
        },
        delete: async function (id) {
            try {
                const token = await auth.currentUser.getIdToken();
                let {data} = await axios.delete(`${process.env.VUE_APP_API_URL}reports/allowed_properties/${id}`, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                return data;
            }
            catch (err) {
                console.error(err);
                throw err;
            }
        },
        update: async function (id, desc) {
            try {
                const token = await auth.currentUser.getIdToken();
                let {data} = await axios.patch(`${process.env.VUE_APP_API_URL}reports/allowed_properties/${id}`,
                    {
                        desc: desc
                    },
                    {
                        headers: {
                            Authorization: `Bearer ${token}`
                        }
                    });
                return data;
            }
            catch (err) {
                console.error(err);
                throw err;
            }
        },
        insert: async function (allowed_property) {
            try {
                const token = await auth.currentUser.getIdToken();
                let {data} = await axios.post(`${process.env.VUE_APP_API_URL}reports/allowed_properties`,
                    allowed_property,
                    {
                        headers: {
                            Authorization: `Bearer ${token}`
                        }
                    });
                return data;
            }
            catch (err) {
                console.error(err);
                throw err;
            }
        }
    },
    header_mappings: {
        search: async function (query) {
            try {
                const token = await auth.currentUser.getIdToken();
                let {data} = await axios.get(`${process.env.VUE_APP_API_URL}reports/header_mappings`, {
                    params: query,
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                return data;
            }
            catch (err) {
                console.error(err);
                throw err;
            }
        },
        get_by_id: async function (id) {
            try {
                const token = await auth.currentUser.getIdToken();
                let {data} = await axios.get(`${process.env.VUE_APP_API_URL}reports/header_mappings/${id}`, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                return data;
            }
            catch (err) {
                console.error(err);
                throw err;
            }
        },
        delete: async function (id) {
            try {
                const token = await auth.currentUser.getIdToken();
                let {data} = await axios.delete(`${process.env.VUE_APP_API_URL}reports/header_mappings/${id}`, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                return data;
            }
            catch (err) {
                console.error(err);
                throw err;
            }
        },
        update: async function (id, header_mapping) {
            try {
                const token = await auth.currentUser.getIdToken();
                let {data} = await axios.patch(`${process.env.VUE_APP_API_URL}reports/header_mappings/${id}`,
                    header_mapping,
                    {
                        headers: {
                            Authorization: `Bearer ${token}`
                        }
                    });
                return data;
            }
            catch (err) {
                console.error(err);
                throw err;
            }
        },
        insert: async function (header_mapping) {
            try {
                const token = await auth.currentUser.getIdToken();
                let {data} = await axios.post(`${process.env.VUE_APP_API_URL}reports/header_mappings`,
                    header_mapping,
                    {
                        headers: {
                            Authorization: `Bearer ${token}`
                        }
                    });
                return data;
            }
            catch (err) {
                console.error(err);
                throw err;
            }
        }
    },
    vendor_payment_records: {
        brands: {
            search: async function () {
                try {
                    const token = await auth.currentUser.getIdToken();
                    let {data} = await axios.get(`${process.env.VUE_APP_API_URL}reports/vendor_payment_records/brands`, {
                        headers: {
                            Authorization: `Bearer ${token}`
                        }
                    });
                    return data;
                }
                catch (err) {
                    console.error(err);
                    throw err;
                }
            }
        },
        providers: {
            search: async function () {
                try {
                    const token = await auth.currentUser.getIdToken();
                    let {data} = await axios.get(`${process.env.VUE_APP_API_URL}reports/vendor_payment_records/providers`, {
                        headers: {
                            Authorization: `Bearer ${token}`
                        }
                    });
                    return data;
                }
                catch (err) {
                    console.error(err);
                    throw err;
                }
            }
        },
        compare: async function ({invoice_id, groups}) {
            try {
                const token = await auth.currentUser.getIdToken();
                let {data} = await axios.post(`${process.env.VUE_APP_API_URL}reports/vendor_payment_records/compare`,
                    {
                        invoice_id,
                        groups
                    },
                    {
                        headers: {
                            Authorization: `Bearer ${token}`
                        }
                    });
                return data;
            }
            catch (err) {
                console.error(err);
                throw err;
            }
        },
        search: async function (query) {
            try {
                const token = await auth.currentUser.getIdToken();
                let {data} = await axios.get(`${process.env.VUE_APP_API_URL}reports/vendor_payment_records`, {
                    params: query,
                    paramsSerializer: params => qs.stringify(params, {indices: false}),
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                return data;
            }
            catch (err) {
                console.error(err);
                throw err;
            }
        },
        delete: async function ({group, options}) {
            try {
                const token = await auth.currentUser.getIdToken();
                let {data} = await axios.patch(`${process.env.VUE_APP_API_URL}reports/vendor_payment_records/delete`,
                    {group, options},
                    {
                        headers: {
                            Authorization: `Bearer ${token}`
                        }
                    });
                return data;
            }
            catch (err) {
                console.error(err);
                throw err;
            }
        }
        ,
        get_last_item_type: async function ({group}) {
            try {
                const token = await auth.currentUser.getIdToken();
                let {data} = await axios.get(`${process.env.VUE_APP_API_URL}reports/vendor_payment_records/last_item_type`, {
                    params: group,
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                if (data && data.length) {
                    const found = data.sort((a, b) => a.invoice_id > b.invoice_id ? 1 : -1)[0]
                    return found.item_id;
                }
                else
                    return null;
            }
            catch (err) {
                console.error(err);
                throw err;
            }
        }
        ,
        clear_invoicing_data: async function ({group, options}) {
            try {
                const token = await auth.currentUser.getIdToken();
                await axios.patch(
                    `${process.env.VUE_APP_API_URL}reports/vendor_payment_records/clear`,
                    {group, options},
                    {
                        headers: {
                            Authorization: `Bearer ${token}`
                        }
                    }
                );
            }
            catch (err) {
                console.error(err);
                throw err;
            }
        },
        update_report_for_invoice: async function (selected_items, description, invoice) {
            try {
                const token = await auth.currentUser.getIdToken();
                for (const selected_item of selected_items) {
                    for (const type of selected_item.types) {
                        const payload = {
                            query: {
                                ...selected_item.group,
                                payment_type: type.type
                            },
                            update: {
                                $set: {
                                    description: description,
                                    invoice: invoice,
                                    type: type.selected_item
                                }
                            }
                        }

                        await axios.patch(`${process.env.VUE_APP_API_URL}reports/vendor_payment_records`,
                            payload,
                            {
                                headers: {
                                    Authorization: `Bearer ${token}`
                                }
                            });
                    }
                }
            }
            catch (err) {
                console.error(err);
                throw err;
            }
        }
        ,
        set_report_under_review: async function ({group, options}) {
            try {
                const token = await auth.currentUser.getIdToken();
                const result = await axios.patch(`${process.env.VUE_APP_API_URL}reports/vendor_payment_records`,
                    {group, options},
                    {
                        headers: {
                            Authorization: `Bearer ${token}`
                        }
                    });
                return result;
            }
            catch (err) {
                console.error(err);
                throw err;
            }
        }
        ,
    },
    file_runs: {
        search: async function () {
            try {
                const token = await auth.currentUser.getIdToken();
                let {data} = await axios.get(`${process.env.VUE_APP_API_URL}reports/file_runs`, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                return data;
            }
            catch (err) {
                console.error(err);
                throw err;
            }
        }
        ,
        get_by_id: async function (id) {
            try {
                const token = await auth.currentUser.getIdToken();
                let {data} = await axios.get(`${process.env.VUE_APP_API_URL}reports/file_runs/${id}`, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                return data;
            }
            catch (err) {
                console.error(err);
                throw err;
            }
        }
    }
}
