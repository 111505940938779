var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-treeview',{attrs:{"active":_vm.active,"color":"secondary","items":_vm.items,"load-children":_vm.load_children,"item-key":"key","item-disabled":"disabled","activatable":"","return-object":""},on:{"update:active":[function($event){_vm.active=$event},_vm.active_update]},scopedSlots:_vm._u([{key:"prepend",fn:function(ref){
var item = ref.item;
var leaf = ref.leaf;
var open = ref.open;
var active = ref.active;
return [(item.children)?_c('v-icon',[_vm._v(" "+_vm._s(open ? 'mdi-folder-open' : 'mdi-folder')+" ")]):_c('v-icon',[_vm._v(" "+_vm._s(active ? 'mdi-file' : 'mdi-file-outline')+" ")])]}},{key:"append",fn:function(ref){
var item = ref.item;
var leaf = ref.leaf;
var open = ref.open;
return [(!leaf && open)?_c('v-btn',{attrs:{"icon":"","dark":"","color":"secondary"},on:{"click":function($event){return _vm.load_children(item)}}},[_c('v-icon',[_vm._v("mdi-refresh")])],1):_vm._e()]}},{key:"label",fn:function(ref){
var item = ref.item;
return [_c('span',{attrs:{"title":item.name}},[_vm._v(_vm._s(item.name))])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }