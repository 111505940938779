<template>
  <v-dialog transition="dialog-bottom-transition" max-width="70vw" v-model="dialog" :retain-focus="false">
    <template v-slot:activator="{on: dialog, attrs}">
      <v-tooltip top>
        <template v-slot:activator="{on: tooltip}">
          <v-btn icon color="error" v-on="{...tooltip,...dialog}" v-bind="attrs">
            <v-icon>mdi-alert-circle</v-icon>
          </v-btn>
        </template>
        <span>Take Action</span>
      </v-tooltip>
    </template>
    <template v-slot:default="dialog">
      <v-card>
        <v-card-title>Take action on {{ item.brand }} {{ item.total | currency }}</v-card-title>
        <v-card-text>

          <v-autocomplete v-model="action" :items="options.filter(x=> x.active)" item-text="text" item-value="val"
                          placeholder="Select an action...">
            <template v-slot:item="{ item }">
              <v-list-item-content>
                <v-list-item-title v-text="item.text"></v-list-item-title>
                <v-list-item-subtitle v-text="item.desc"></v-list-item-subtitle>
              </v-list-item-content>
            </template>
          </v-autocomplete>

          <v-textarea v-model="note" v-if="show_note" placeholder="Enter a note"></v-textarea>
          <div v-if="item.invoice">
            <h3 class="error--text">This item has an invoice associated with it</h3>
            <p>
              Id: {{ item.group.invoice.Id }}
              Number:{{ item.group.invoice.DocNumber }}
            </p>
          </div>
        </v-card-text>
        <v-card-actions v-if="action">
          <v-btn color="error" :disabled="disable_action" @click="take_action">
            {{ action.button_text }}
          </v-btn>
          <v-btn color="primary" @click="dialog.value = false">
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </template>
  </v-dialog>
</template>

<script>
import report_service from "../../services/report.service";
import {mapActions} from 'vuex';

export default {
  name: "ReportItemDeleteDialog",
  props: {
    item: Object
  },
  computed: {
    show_note() {
      return this.action && !this.action.delete;
    },
    disable_action() {
      return !this.action || (!this.action.delete && !this.note);
    }
  },
  data() {
    return {
      dialog: false,
      action: null,
      note: null,
      options: [
        {
          text: 'Delete Payment Group w/o Reprocessing',
          desc: 'Delete the payment group and leave the file where it is',
          val: {delete: true, reprocess: false, button_text: 'Delete'},
          active: true
        },
        {
          text: 'Delete payment group and move file to reprocess folder',
          desc: 'Delete the payment group and move the original file to reprocess folder so that either the header mapping or ' +
              'the file can be modified before reprocessing.',
          val: {delete: true, reprocess: true, button_text: 'Delete and move file'},
          active: true
        },
        {
          text: 'Remove invoicing details',
          desc: 'Clear all invoice details from this report and move it back to not invoiced',
          val: {clear: true, button_text: 'Remove Invoice Details'},
          active: this.item.group.invoice ? true : false
        }
      ]
    }
  },
  methods: {
    ...mapActions('reports', [
      'search'
    ]),
    async take_action() {
      if (this.action) {
        if (this.action.delete) {
          await report_service.vendor_payment_records.delete({
            group: this.item.group,
            options: {reprocess: this.action.reprocess}
          });
        }
        else if (this.action.clear) {
          await report_service.vendor_payment_records.clear_invoicing_data({
            group: this.item.group
          });
        }
        await this.search();
        this.dialog = false;
      }
    }
  }
}
</script>

<style scoped>

</style>