<template>
  <v-chip :color="color" v-if="!isNaN(item_number)">
    {{ item_number }}
  </v-chip>
</template>

<script>
export default {
  name: "ChipNumber",
  props: {
    item_number: Number,
    color: {
      type: String,
      default: 'secondary',
      validator: function (value) {
        return ['secondary', 'error', 'warning'].includes(value);
      }
    }
  }
}
</script>

<style scoped>

</style>