<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" md="4">
        <v-card>
          <v-card-title>Select a file to process</v-card-title>
          <v-card-actions>
            <v-btn :disabled="!can_process" color="secondary" @click="process_selected_file">Process File</v-btn>
          </v-card-actions>
          <v-card-text>
            <file-chooser ref="fc" :active_update="active_update"></file-chooser>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="12" md="8" v-if="jobs">
        <v-data-table v-if="jobs.length" :items="jobs" :headers="headers" item-key="_id" :options.sync="pagination_settings"  show-expand>
          <template v-slot:header>
            <v-btn icon @click="load_jobs">
              <v-icon>mdi-refresh</v-icon>
            </v-btn>
          </template>
          <template v-slot:item.file="{item}">
            <google-file-link v-if="item.data.file" :file_id="item.data.file.id"
                              :file_name="item.data.file.name"></google-file-link>
          </template>
          <template v-slot:item.logs="{item}">
            <dialog-table :items="item.data.log"></dialog-table>
          </template>
          <template v-slot:item.finished="{item}">
            <span v-if="item.lastFinishedAt">
              {{ item.lastFinishedAt | moment('from', 'now') }}
            </span>
          </template>
          <template v-slot:item.status="{item}">
            <v-progress-circular color="primary" v-if="!item.lastFinishedAt" indeterminate></v-progress-circular>
            <span v-else>Complete</span>
          </template>
          <template v-slot:expanded-item="{headers, item}">
            <td :colspan="headers.length" class="ma-0 pa-0">
              <v-data-table :items="item.data.cleaned_files" :headers="cleaned_file_headers">
                <template v-slot:item.generated_file="{item}">
                  <google-file-link :file_name="item.generated_file.name"
                                    :file_id="item.generated_file.id"></google-file-link>
                </template>
                <template v-slot:item.duplicates="{item}">
                  <span v-if="item.duplicate_headers">
                    {{ item.duplicate_headers.map(x => `${x.name}:${x.index}`).join(', ') }}
                  </span>
                </template>
                <template v-slot:item.totals="{item}">
                  <span v-if="item.total_rows">
                  {{ item.total_rows.map(x => x.row).join(', ') }}
                  </span>
                </template>
                <template v-slot:item.original_header="{item}">
                  <dialog-table :items="item.original_header"></dialog-table>
                </template>
                <template v-slot:item.cleaned_header="{item}">
                  <dialog-table :items="item.cleaned_header"></dialog-table>
                </template>
                <template v-slot:item.action="{item}">
                  <process-file-dialog :file="item.generated_file"></process-file-dialog>
                </template>
              </v-data-table>
            </td>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import FileChooser from "../components/file_processor/FileChooser";
import io from 'socket.io-client';
import GoogleFileLink from "../components/GoogleFileLink";
import DialogTable from "../components/DialogTable";
import ProcessFileDialog from "../components/ProcessFileDialog";

export default {
  name: "PreProcessorJobs",
  components: {ProcessFileDialog, DialogTable, GoogleFileLink, FileChooser},
  data() {
    return {
      selected: null,
      headers: [
        {text: 'Id', value: '_id'},
        {text: 'File Name', value: 'data.file.name'},
        {text: 'File', value: 'file'},
        {text: 'Sheets Parsed', value: 'data.cleaned_files.length'},
        {text: 'Logs', value: 'logs'},
        {text: 'Error', value: 'failReason'},
        {text: 'Finished', value: 'finished'},
        {text: 'Status', value: 'status'}
      ],
      cleaned_file_headers: [
        {text: 'Sheet Name', value: 'sheet_name'},
        {text: 'Totals Removed', value: 'totals'},
        {text: 'Duplicates Found', value: 'duplicates'},
        {text: 'Skipped', value: 'columns_to_skip'},
        {text: 'Header Found', value: 'found_header_index'},
        {text: 'Original', value: 'original_header'},
        {text: 'Cleaned', value: 'cleaned_header'},
        {text: 'File', value: 'generated_file'},
        {text: 'Action', value: 'action'}
      ]
    }
  },
  computed: {
    ...mapGetters('pre_processor', [
      'jobs'
    ]),
    can_process() {
      return this.selected
          && this.selected.length === 1
          && this.selected[0].mimeType !== 'application/vnd.google-apps.folder';
    },
    pagination_settings: {
      get() {
          return this.$store.getters['pagination_settings/pre_processor'];
      },
      set(value) {
        this.$store.dispatch('pagination_settings/update_pre_processor', value);
      }
    }
  },
  methods: {
    ...mapActions('pre_processor', [
      'load_jobs',
      'update_job',
      'process_file'
    ]),
    active_update(active) {
      this.selected = active;
    },
    process_selected_file() {
      this.process_file(this.selected[0].id);
    }
  },
  async mounted() {
    //let self = this;
    await this.load_jobs();
    const socket = io(process.env.VUE_APP_SOCKET_IO_URL);
    socket.on('connect', () => {
      console.log('connected');
      socket.on('pre-processing-updated', (message) => {
        this.update_job({job_id: message.id, update: message.update});
      })
      socket.on('pre-processing-complete', (message) => {
        this.update_job({job_id: message.id, update: message.update});
      })
    })
  }
}
</script>

<style scoped>

</style>
