<template>
  <v-btn class="mt-5 ms-3" v-if="!is_logged_in" @click="login">Login to Quickbooks</v-btn>
  <div v-else class="ms-1 mt-1">
    <v-card class="mb-4">
      <v-card-title>Connected to quickbooks</v-card-title>
      <v-card-text>
        <v-btn color="primary" @click="logout()">
          <v-icon>mdi-logout</v-icon>
          Logout
        </v-btn>
      </v-card-text>
    </v-card>

    <div class="mt-5" v-if ="is_loading_data === true">
      <v-layout align-center justify-center column fill-height>
        <v-flex row align-center>
          <v-progress-circular indeterminate :size="50" color="primary" class=""></v-progress-circular>
          <b class="ml-3">Loading...</b>
        </v-flex>
      </v-layout>
    </div>

    <template v-else>

      <v-container fluid>
        <v-row>
          <v-col cols="12">
            <v-row>


              {{this.filters.has_invoice_number}}

              <v-col cols="12" md="2">
                <v-text-field v-model="search_text"
                    hide-details
                    prepend-icon="mdi-magnify"
                    single-line></v-text-field>
              </v-col>
              <v-col cols="12" md="3">
                <v-autocomplete :value="filters.brand" :loading="brands_loading" :items="brands"
                                @change="brand_updated" label="Brand"
                                placeholder="Select a brand..." clearable></v-autocomplete>
              </v-col>
              <v-col cols="12" md="2">
                <v-autocomplete :value="filters.has_invoice_number" :items="invoice_options" item-value="value"
                                 item-text="name" @change="has_invoice_number_updated" label="Invoice Options"
                                placeholder="Select an invoice option..." clearable></v-autocomplete>
              </v-col>
              <v-col cols="12" md="1">
                <v-btn icon large dark color="secondary" class="mt-3">
                  <v-icon>mdi-refresh</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
      <v-expansion-panels v-if="is_loading_data === false" >
        <v-expansion-panel class="my-1"  @click="panel_change($event, invoice, index)"
            v-for="(invoice,index) of invoices" :key="invoice.bq_invoice_id">
          <v-expansion-panel-header  class="no-gutters">
            <v-container class="my-0 ms-0 ps-0 py-0 no-gutters">
              <v-row class="mx-0 my-0 px-0 py-0 no-gutters">
                <v-col cols="2" ><b>Quick book Customer:</b></v-col>
                <v-col cols="2" ><h3>{{invoice.qb_customer_name}}</h3></v-col>
                <v-col cols="1" ><b>Brand:</b></v-col>
                <v-col cols="1">{{ invoice.brand }}</v-col>
                <v-col cols="1" ><b>Provider:</b></v-col>
                <v-col cols="1" >{{ invoice.provider }}</v-col>
              </v-row>
              <v-row class="mx-0 mt-2 px-0 py-0 no-gutters">
                <v-col cols="2" ><b>Report File Name</b></v-col>
                <v-col><span style="z-index: 1000">{{invoice.original_file_name}}</span></v-col>
              </v-row>
              <v-row class="mx-0 mt-2 px-0 py-0 no-gutters" >
                <v-col cols="2"><b>Report Create Date:</b></v-col>
                <v-col><h4>{{ invoice.original_file_info.meta_data.createdTime.split('T')[0] }}</h4></v-col>
              </v-row>
              <v-row class="mx-0 mt-2 px-0 py-0 no-gutters" >
                <v-col cols="2"><b>Total Invoice Amount:</b></v-col>
                <v-col><h3 style="color:green">${{ invoice.total_payment_amount }}</h3></v-col>
              </v-row>
              <v-row class="mx-0 mt-2 px-0 py-0 no-gutters" >
                <v-col cols="2"><b>Quickbook Invoice Id:</b></v-col>
                <v-col>{{ invoice.qb_invoice_id }}</v-col>
                <v-col cols="2"><b>Quickbook Invoice Number:</b></v-col>
                <v-col>  {{ invoice.qb_invoice_info ? invoice.qb_invoice_info.Invoice.DocNumber : 'sss' }}</v-col>
              </v-row>
            </v-container>
          </v-expansion-panel-header>
          <v-expansion-panel-content :class="invoice.is_dirty ? 'dirty_panel': 'no_dirty_panel'" >

            <div v-if="!invoice.invoice_created">
              <v-data-table hide-default-footer
                :headers="headers"
                :items="invoice.invoice_line_items"
                item-key="name"
                class="elevation-1">
            </v-data-table>
              <v-container class="mt-2 mb-3">
                <v-row >
                  <v-col cols="4">
                    <v-row>
                      <b>Report Links</b>
                    </v-row>
                    <v-row v-if="invoice.original_file_info" >
                       <div style="display: inline-block">
                       <h3 class="mt-2">original file path:</h3>
                      <a :href="invoice.original_file_info.cloud_storage_path">
                        {{ invoice.original_file_info.cloud_storage_path }}
                      </a>
                      <h3 class="mt-2">extracted file path:</h3>
                      <a :href="invoice.original_file_info.extracted_cloud_storage_path" >
                        {{ invoice.original_file_info.extracted_cloud_storage_path }}
                      </a>
                       <h3 class="mt-2">cleaned file path:</h3>
                        <a :href="invoice.original_file_info.cleaned_cloud_storage_path">
                          {{ invoice.original_file_info.cleaned_cloud_storage_path }}
                        </a>
                    </div>
                    </v-row>
                    <v-row class="mt-6">
                      <b>Report File Name:</b>
                    </v-row>
                    <v-row>
                      {{invoice.original_file_name}}
                    </v-row>
                  </v-col>
                  <v-col cols="8">
                    <v-row class="ml-3">
                      <b>Quick Book Info</b>
                    </v-row>
                    <template v-if="invoice.qb_info_loading === false">
                      <v-row class="ml-3" v-if="invoice.qb_invoice_info_real_time">
                        <v-col cols="4"><b>Invoice Date: </b>{{invoice.qb_invoice_info_real_time.TxnDate}}</v-col>
                        <v-col cols="4"><b>Due Date: </b>{{invoice.qb_invoice_info_real_time.DueDate}}</v-col>
                        <v-col cols="4" ><b>Created Date: </b>{{invoice.qb_invoice_info_real_time.MetaData.CreateTime.split('T')[0] }}</v-col>
                        <v-col cols="4" ><b>Last Updated Date: </b>{{invoice.qb_invoice_info_real_time.MetaData.LastUpdatedTime.split('T')[0]}}</v-col>
                        <v-col cols="4" ><b>Total Amount: </b>${{invoice.qb_invoice_info_real_time.TotalAmt}}</v-col>
                        <v-col cols="4" ><b>QB Invoice Number: </b>{{invoice.qb_invoice_info_real_time.Id}}</v-col>
                        <v-col cols="4" ><b>QB Balance: </b>{{invoice.qb_invoice_info_real_time.Balance}}</v-col>
                        <v-col cols="4" ><b>QB Invoice Id: </b>{{invoice.qb_invoice_info_real_time.Id}}</v-col>
                      </v-row>

                      <v-row class="ml-6 warning-display" v-if="invoice.qb_invoice_id && invoice.qb_invoice_info_real_time
                          && invoice.qb_invoice_info_real_time.payment_info && invoice.qb_invoice_info_real_time.payment_info.length > 0 ">
                        Invoice has an associated payment.
                        {{ invoice.qb_invoice_info_real_time.payment_info }}
                      </v-row>

                      <v-row class="ml-6 mt-8 warning-display" v-if="invoice.qb_invoice_id && !invoice.qb_invoice_info_real_time">
                        Invoice in QB is missing...
                        Invoice has quick book invoice Id. But the invoice is missing in QB. It is an orphaned record. Please delete this invoice and create again.
                      </v-row>

                      <v-row class="ml-6 warning-display" v-if="invoice.qb_invoice_info_real_time && parseFloat(invoice.total_payment_amount).toFixed(2) !== parseFloat(invoice.qb_invoice_info_real_time.TotalAmt)">
                        Invoice Amount in QB is different...
                        {{ parseFloat(invoice.total_payment_amount) }}
                        {{ parseFloat(invoice.qb_invoice_info_real_time.TotalAmt) }}
                      </v-row>

                      <v-row class="mt-8 ml-8">
                        <b>
                          <v-btn
                              color="red lighten-2"
                              dark
                              @click ="view_vendor_payment_report_metrics(invoice)">
                            View Report Details
                          </v-btn>
                        </b>
                      </v-row>

                    </template>
                    <div class="mt-10" v-else>
                      <v-layout align-center justify-center column fill-height>
                        <v-flex row align-center>
                          <v-progress-circular indeterminate :size="30" color="primary" class=""></v-progress-circular>
                          <b class="ml-3">Loading...</b>
                        </v-flex>
                      </v-layout>
                    </div>
                  </v-col>
                </v-row>
              </v-container>
            <v-container class="my-3 ms-0 ps-0 py-0 no-gutters">

              <v-row class="mx-0 my-0 px-0 py-0 no-gutters">

                <v-col cols="6">
                  <PaymentPeriodSelector v-bind:vendor_pay_period_description_input="invoice.vendor_pay_period_description" @pay_period_event="set_invoice_description($event, index)"></PaymentPeriodSelector>
                </v-col>
                <v-col cols="6" class="ps-5 pt-5" >
                  <v-row v-if="invoice.qb_invoice_id && invoice.qb_invoice_info_real_time" class="mb-3">
                    <v-col cols="12">
                    <v-card class="ml-3">
                      <v-card-text class="my-0 py-0">
                        <h4>Invoice Create Date</h4>
                        {{ invoice.qb_invoice_info_real_time.TxnDate }}
                      </v-card-text>
                      <v-card-text class="my-0 py-0">
                        <h4>Vendor Pay Period Desc Create Date</h4>
                        {{ invoice.qb_invoice_info_real_time.vendor_pay_period_description }}
                      </v-card-text>
                    </v-card>
                    </v-col>
                  </v-row>

                  <v-menu
                      v-model="invoice.date_opened"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      min-width="auto">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                          v-model="invoice.invoice_date"
                          label="Invoice Date"
                          prepend-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                        v-model="invoice.invoice_date" @change="date_changed(invoice, index)"
                        @input="invoice.date_opened = false"
                    ></v-date-picker>
                  </v-menu>

                  <v-btn v-if="!invoice.qb_invoice_id" color="primary" class="mt-5" :disabled="!invoice.invoice_date || !invoice.vendor_pay_period_description || invoice.is_creating"
                         @click="create_invoice(invoice, index)">
                    <span>Create New Invoice In QuickBooks</span>
                    <v-progress-circular v-if="invoice.is_async_operation_in_progress"  class="ms-2" indeterminate color=""></v-progress-circular>
                  </v-btn>
                  <v-btn v-else color="primary" class="mt-5" :disabled="!invoice.is_dirty"
                         @click="update_invoice(invoice, index)">
                    <span>Update Invoice In QuickBooks</span>
                    <v-progress-circular v-if="invoice.is_async_operation_in_progress" class="ms-2" indeterminate color=""></v-progress-circular>
                  </v-btn>

                  <v-progress-circular class="ms-5 mt-5" v-if="invoice.is_creating"
                      indeterminate
                      color="primary"></v-progress-circular>

                  <br/>

                  <v-btn v-if="invoice.qb_invoice_id" color="primary" class="mt-5 ms-0"
                         @click="delete_invoice_prompt(invoice, index)">
                    <span>Delete Invoice In QuickBooks<v-progress-circular v-if="invoice.is_deleting" class="ms-2" indeterminate color=""></v-progress-circular></span>
                  </v-btn>

                  <v-btn v-if="invoice.qb_invoice_id" color="primary" class="mt-5" @click.stop ="mark_invoice_as_invalid_prompt(invoice)" v-show="false">
                    <span>Mark Vendor Payment Report as Invalid<v-progress-circular v-if="invoice.marking_invalid" class="ms-2" indeterminate color=""></v-progress-circular></span>
                  </v-btn>

                </v-col>
              </v-row>
            </v-container>
            </div>
            <v-card v-else class="mb-2">
              <v-card-title>Invoice Created Response</v-card-title>
              <v-card-text>
                invoice saved successfully in quickbooks
                {{ invoice.invoice_create_response}}
              </v-card-text>
            </v-card>

          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>

      <v-dialog
          v-model="report_details_dialog"
          max-width="800">
        <v-card v-if="selected_vendor_payment_report && selected_vendor_payment_report.vendor_report_metrics">
          <v-card-title class="text-h5">
            File Metrics
          </v-card-title>
          <v-container fluid v-if="selected_vendor_payment_report" >
            <v-row no-gutters>
              <v-col cols="2" class="pr-3">
                <h5>Brands:</h5>
              </v-col>
              <v-col >
                {{ selected_vendor_payment_report.vendor_report_metrics.brands }}
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="2" class="pr-3">
                <h5>Channels:</h5>
              </v-col>
              <v-col >
                {{ selected_vendor_payment_report.vendor_report_metrics.channels }}
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="2" class="pr-3">
                <h5>Payment Amounts:</h5>
              </v-col>
              <v-col >
                {{ selected_vendor_payment_report.vendor_report_metrics.payment_amounts }}
              </v-col>
            </v-row>

            <v-divider class="mt-2 mb-6" ></v-divider>

            <v-row class="ms-1" >

              <v-col cols="6">
                <v-row no-gutters ><h5>Payments by Channel</h5></v-row>
                <v-row no-gutters>
                  <v-col>
                    <v-container>
                      <v-row no-gutters v-for="(a, index) of selected_vendor_payment_report.vendor_report_metrics.payments_by_channel" :key="index" >
                        <v-col cols="6">
                          <h5>{{a.channel}}</h5>
                        </v-col>
                        <v-col>${{ a.payment_amount }}</v-col>
                      </v-row>
                    </v-container>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="6" >
                <v-row no-gutters><h5>Payments by Brand</h5></v-row>
                <v-row no-gutters>
                  <v-col>
                    <v-container>
                      <v-row no-gutters v-for="(a, index) of selected_vendor_payment_report.vendor_report_metrics.payments_by_brand" :key="index" >
                        <v-col cols="6">
                          <h5>{{a.brand}}</h5>
                        </v-col>
                        <v-col>${{ a.payment_amount }}</v-col>
                      </v-row>
                    </v-container>
                  </v-col>
                </v-row>
              </v-col>

            </v-row>

            <v-divider class="mt-3 mb-3" ></v-divider>

            <v-row no-gutters class="mb-0 pb-0 mt-0 pb-0" >
              <v-col cols="6">
                <h5>Items Count:</h5>
              </v-col>
              <v-col >
                {{ selected_vendor_payment_report.vendor_report_metrics.items_count }}
              </v-col>
            </v-row>
            <v-row no-gutters class="mb-0 pb-0 mt-0 pb-0">
              <v-col cols="6">
                <h5>Items Count with No Channel:</h5>
              </v-col>
              <v-col >
                {{ selected_vendor_payment_report.vendor_report_metrics.items_count_with_null_channel }}
              </v-col>
            </v-row>
            <v-row no-gutters class="mb-0 pb-0 mt-0 pb-0">
              <v-col cols="6">
                <h5>Items Count with No Brand:</h5>
              </v-col>
              <v-col >
                {{ selected_vendor_payment_report.vendor_report_metrics.items_count_with_null_brand }}
              </v-col>
            </v-row>
            <v-row no-gutters class="mb-0 pb-0 mt-0 pb-0">
              <v-col cols="6">
                <h5>Items Count with No Payment Amount:</h5>
              </v-col>
              <v-col>
                {{ selected_vendor_payment_report.vendor_report_metrics.items_count_with_null_or_zero_payment_amount }}
              </v-col>
            </v-row>
            <v-row no-gutters class="mb-0 pb-0 mt-0 pb-0">
              <v-col cols="6">
                <h5>Items Count with No Payment Key:</h5>
              </v-col>
              <v-col >
                {{ selected_vendor_payment_report.vendor_report_metrics.items_count_with_null_payment_key }}
              </v-col>
            </v-row>

            <v-divider class="mt-3 mb-3" ></v-divider>

            <v-row no-gutters class="mb-0 pb-0 mt-0 pb-0">
              <v-col cols="6">
                <h5>Missing QuickBook Customer</h5>
              </v-col>
              <v-col >
                <span :class="selected_vendor_payment_report.vendor_report_metrics.items_with_missing_customer_qb
                      && selected_vendor_payment_report.vendor_report_metrics.items_with_missing_customer_qb.length === 0 ? 'ok-display' : 'warning-display'">
                  {{ selected_vendor_payment_report.vendor_report_metrics.items_with_missing_customer_qb }}
                </span>
              </v-col>
            </v-row>
            <v-row no-gutters class="mb-0 pb-0 mt-0 pb-0">
              <v-col cols="6">
                <h5>Quick Book upfront LineItem exists</h5>
              </v-col>
              <v-col >
                <span :class="selected_vendor_payment_report.vendor_report_metrics.qb_line_item_exists === 'Yes' ? 'ok-display' : 'warning-display'">
                  {{ selected_vendor_payment_report.vendor_report_metrics.qb_line_item_exists }}
                </span>
              </v-col>
            </v-row>
            <v-row no-gutters class="mb-0 pb-0 mt-0 pb-0">
              <v-col cols="6">
                <h5>Items count with missing quickbook customer</h5>
              </v-col>
              <v-col >
                <span :class="selected_vendor_payment_report.vendor_report_metrics.items_count_with_null_line_item_qb === 0 ? 'ok-display' : 'warning-display'">
                  {{ selected_vendor_payment_report.vendor_report_metrics.items_count_with_null_line_item_qb }}
                </span>
              </v-col>
            </v-row>

          </v-container>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="report_details_dialog = false">Close</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="qb_dialog" max-width="850">
        <v-card v-if="selected_vendor_payment_report && selected_vendor_payment_report.is_loading === false" >
          <v-card-title class="text-h5">
            Invoice Details
          </v-card-title>
          <v-container fluid v-if="selected_vendor_payment_report" >

            <v-simple-table>
              <template v-slot:default>
                <thead>
                <tr>
                  <th class="text-left">
                    QB customer
                  </th>
                  <th class="text-left">
                    Brand
                  </th>
                  <th class="text-left">
                    Provider
                  </th>
                  <th class="text-left">
                    QB Invoice Id
                  </th>
                  <th class="text-left">
                    Total Payment Amount
                  </th>
                  <th class="text-left">
                    Total Payment Amount in QB
                  </th>
                  <th>
                    Balance
                  </th>
                  <th class="text-left">
                    Has Associated Payment
                  </th>
                  <th class="text-left">
                    Missing IN QB
                  </th>
                </tr>
                </thead>
                <tbody>
                <tr
                    v-for="item in selected_vendor_payment_report.invoices"
                    :key="item.bq_invoice_id">
                  <td>{{ item.qb_customer_name }}</td>
                  <td>{{ item.brand }}</td>
                  <td>{{ item.provider }}</td>
                  <td>{{ item.qb_invoice_id }}</td>
                  <td>${{ item.total_payment_amount }}</td>
                  <td><span :class="parseFloat(item.qb_invoice_info.TotalAmt) !== parseFloat(item.total_payment_amount) ? 'warning-display':  'ok-display'" v-if="item.qb_invoice_info">${{ item.qb_invoice_info.TotalAmt }}</span></td>
                  <td><span v-if="item.qb_invoice_info">${{ item.qb_invoice_info.Balance }}</span></td>
                  <td>
                    <span class="warning-display" v-if="item.qb_invoice_info && item.qb_invoice_info.payment_info && item.qb_invoice_info.payment_info.length > 0">{{item.qb_invoice_info.LinkedTxn}}</span>
                    <span class="ok-display" v-else>No</span>
                  </td>
                  <td>
                    <span class="warning-display" v-if="item.qb_invoice_info && !item.qb_invoice_info.Id">YES Missing in QB</span>
                  </td>
                </tr>
                </tbody>
              </template>
            </v-simple-table>

            <v-divider class="mt-2 mb-6" ></v-divider>

            <v-row class="ms-2 me-2 ok-display">
              Are you sure you want to delete this invoice. There could be invoices generated from this report. They are listed above. Deleting this will also delete
              the other invoices as these are generated from the same report. Also some of these may have an associated payment.
              Do you want to continue?
            </v-row>

          </v-container>

          <v-card-actions v-if="selected_vendor_payment_report">
            <v-spacer></v-spacer>
            <v-btn color="error" @click="delete_invoice(selected_invoice)">
               Continue..
              <v-progress-circular v-if="dialog_is_async_operation_in_progress" class="ms-6" indeterminate color="Primary"></v-progress-circular>
            </v-btn>
            <v-btn
                color="secondary"
                @click="qb_dialog = false">
              No, Dont do nothing
            </v-btn>
          </v-card-actions>
        </v-card>
        <v-card v-else height="100%" >
          <v-container >
            <v-layout align-center justify-center column fill-height class="my-10">
              <v-flex row align-center>
                <v-progress-circular indeterminate :size="50" color="primary" class=""></v-progress-circular>
                <b class="ml-3">Loading...</b>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card>
      </v-dialog>

    </template>

  </div>
</template>

<script>

import {mapActions, mapGetters} from 'vuex';
import InvoiceService from '../services/invoices.service'
import PaymentPeriodSelector from '../components/v1/PaymentPeriodSelector';
import quickbooks_service from "../services/quickbooks.service";
import utility_service from "../services/utility_service";

export default {
  name: "NewInvoices",
  components: {PaymentPeriodSelector},
  props: {
    code: String,
    realm_id: String
  },
  data() {
    return {
      original_invoices: null,
      invoices: null,
      group_description: null,
      use_custom_description: false,
      custom_description_rules: [
        v => v && v.length <= 31 || 'Max 31 characters'
      ],
      date_opened: false,
      headers:
          [
            {
              text: 'Brand',
              align: 'start',
              value: 'brand',
            },
            {
              text: 'Provider',
              value: 'provider',
            },
            {text: 'Payment Type', value: 'payment_type'},
            {text: 'Line Item Name', value: 'qb_line_item_name'},
            {text: 'Total Payment Amount', value: 'total_payment_amount'},
            {text: 'Items Count', value: 'items_count'},
            {text: 'Extracted File Hash', value: 'extracted_file_hash'},
          ],
      dialog: false,
      qb_dialog: false,

      mark_invoice_as_invalid: false,
      selected_invoice: {},

      report_details_dialog: false,
      selected_vendor_payment_report: {},

      invoice_info: null,
      is_loading_data: true,
      search_text: null,

      dialog_is_async_operation_in_progress :false,
    }
  },
  watch: {
    search_text(new_search_text, old_search_text) {
      console.log(new_search_text);
      console.log(old_search_text);

      let current_filter =  this.filters.has_invoice_number;

      if(current_filter) {
        this.invoices = this.original_invoices.filter(x => x.qb_invoice_id)
      } else if(current_filter === false) {
        this.invoices = this.original_invoices.filter(x => !x.qb_invoice_id)
      } else {
        this.invoices = this.original_invoices;
      }

      if(new_search_text) {
        this.invoices = this.invoices.filter(f => f.original_file_name.toLowerCase().includes(new_search_text.toLowerCase())
            || f.provider.toLowerCase().includes(new_search_text.toLowerCase()) || f.brand.toLowerCase().includes(new_search_text.toLowerCase()));
      }

      this.invoices.forEach(x => {
        x.invoice_line_items = this.invoice_info.invoice_line_items.filter(y => y.bq_invoice_id === x.bq_invoice_id);
      });

    }
  },
  computed: {
    date_range: {
      get() {
        return this.$store.getters["reports/date_range"];
      },
      set(value) {
        this.$store.dispatch('reports/set_date_range', value);
      }
    },
    custom_description: {
      get() {
        return this.$store.getters['reports/custom_description'];
      },
      set(value) {
        this.$store.dispatch('reports/set_custom_description', value);
      }
    },
    show_invoice_action: {
      get() {
        return this.$store.getters['quickbooks/show_invoice_action'];
      },
      set() {
        this.$store.dispatch('quickbooks/hide_invoice_action');
      }
    },
    invoice_date: {
      get() {
        return this.$store.getters['reports/invoice_date'];
      },
      set(value) {
        this.$store.dispatch('reports/set_invoice_date', value);
      }
    },
    ...mapGetters('reports', [
      'items',
      'loading',
      'has_selected_items'
    ]),
    ...mapGetters('report_filters', [
      'filters',
      'brands',
      'brands_loading',
      'invoice_options',
    ]),
    ...mapGetters('quickbooks', [
      'items',
      'is_logged_in',
      'access_token',
      'realm_id_store',
      'has_selected_customer',
      'expiration_time',
      'is_token_expired',
      'invoice_action',
      'matching_invoices',
      'selected_customer'
    ])
  },
  methods: {
    date_changed(invoice, index) {
      if(!invoice.qb_invoice_id) {
        invoice.is_dirty = invoice.invoice_date !== null;
        this.$set(this.invoices, index, invoice);
        return;
      }
      if (invoice.qb_invoice_info_real_time) {
        if (invoice.invoice_date !== invoice.qb_invoice_info_real_time.TxnDate) {
          invoice.is_dirty = true;
          this.$set(this.invoices, index, invoice);
        } else {
          invoice.is_dirty = false;
          this.$set(this.invoices, index, invoice);
        }
      }
    },
    ...mapActions('report_filters', [
      'load_brands',
      'update_filter'
    ]),
    ...mapActions('reports', [
      'search'
    ]),
    async create_invoice(invoice, index) {
      let invoice_request = {
        vendor_pay_period_description: invoice.vendor_pay_period_description,
        extracted_file_hash: invoice.extracted_file_hash,
        bq_invoice_id: invoice.bq_invoice_id,
        customer_id: invoice.qb_customer_id,
        invoice_date: invoice.invoice_date,
        items: invoice.invoice_line_items.map(x => {
          return {
            Name: x.qb_line_item_name,
            Id: x.qb_line_item_id,
            total: x.total_payment_amount
          }
        })
      };
      invoice.is_creating = true;
      this.$set(this.invoices, index, invoice);
      invoice.invoice_create_response = await quickbooks_service.invoices.create_v1({
        realm_id: this.realm_id_store,
        access_token: this.access_token,
        customer_id: invoice.qb_customer_id,
        invoice_request: invoice_request
      });
      invoice.invoice_created = true;
      invoice.is_creating = false;
      this.$set(this.invoices, index, invoice);
    },
    async update_invoice(invoice, index) {
      let invoice_update_request = {
        vendor_pay_period_description: invoice.vendor_pay_period_description,
        qb_invoice_id: invoice.qb_invoice_id,
        invoice_date: invoice.invoice_date,
        realm_id: this.realm_id_store,
      };
      invoice.is_async_operation_in_progress = true;
      this.$set(this.invoices, index, invoice);
      try {
        let response = await quickbooks_service.invoices.update_invoice_v1({
          access_token: this.access_token,
          invoice_request: invoice_update_request,
          bq_invoice_id: invoice.bq_invoice_id,
          realm_id: this.realm_id_store,
        });
        console.log(response);
        invoice.is_async_operation_in_progress = false;
        this.$set(this.invoices, index, invoice);
        await this.load();
      }
      catch (err) {
        let qb_authorized = !err.toString().includes('401');
        if (!qb_authorized) {
          this.logout();
          return;
        }
      }
    },
    close_dialog() {
      this.selected_invoice = null;
      this.dialog = false;
    },
    set_invoice_description(vendor_pay_period_info, index) {

      let invoice = this.invoices[index];
      if (vendor_pay_period_info.sender === 'custom_description') {
        invoice.vendor_pay_period_description = vendor_pay_period_info.custom_description;
      } else if (vendor_pay_period_info.sender === 'date_range' && vendor_pay_period_info.date_range && vendor_pay_period_info.date_range.length === 2) {
        const sorted = Object.assign([], vendor_pay_period_info.date_range);
        sorted.sort();
        invoice.vendor_pay_period_description = sorted.join(' to ');
        invoice.invoice_date = sorted[1];
      } else
        invoice.vendor_pay_period_description = null;

      if (!invoice.qb_invoice_id) {
        invoice.is_dirty = true
        this.$set(this.invoices, index, invoice);
        return;
      }

      if (invoice.qb_invoice_info_real_time) {
        if (invoice.vendor_pay_period_description !== invoice.qb_invoice_info_real_time.vendor_pay_period_description) {
          invoice.is_dirty = true;
          this.$set(this.invoices, index, invoice);
        } else {
          invoice.is_dirty = false;
          this.$set(this.invoices, index, invoice);
        }
      }

      this.$set(this.invoices, index, invoice);
    },
    login: async function () {
      const url = await quickbooks_service.get_login_url_v1();
      window.location.href = url;
    },
    logout: function () {
      this.$store.commit('quickbooks/set_access_token', null);
      this.$store.commit('quickbooks/set_refresh_token', null);
      this.$store.commit('quickbooks/set_expiration_time', null);
      this.$store.commit('quickbooks/set_realm_id', null);
      this.step = 1;
    },
    ...mapActions('quickbooks', [
      'get_tokens',
      'get_tokens_v1',
      'load_customers',
      'load_items',
      'clear_selected_customer'
    ]),
    ...mapActions('reports', [
      'update_selected_item_item_type',
      'update_selected_item_item_desc',
      'search',
      'clear_selected_items',
      'clear_date_range',
      'remove_item'
    ]),
    ...mapActions('report_filters', [
      'load_brands'
    ]),
    ...mapActions('stepper', [
      'set_step',
      'next_step',
      'previous_step'
    ]),
    delay(milliseconds = 500) {
      return new Promise((resolve) => {
        setTimeout(resolve, milliseconds);
      });
    },
    async load() {

      this.dialog = this.qb_dialog = false;
      this.is_loading_data = true;

      /*
      if(this.filters.has_invoice_number) {
        if(!this.filters.brand) {
          await this.update_filter({brand: '4Change Energy'});
        }
      } */


      alert('asdfdsaf')

      let invoice_info = await InvoiceService.invoices.get({brand: this.filters.brand, has_invoice_number: this.filters.has_invoice_number});

      this.invoice_info = invoice_info;
      this.original_invoices = invoice_info.invoices.filter(x => x.is_valid !== false);
      this.original_invoices.forEach(i => {
        i.vendor_report_metrics = JSON.parse(i.vendor_report_metrics);
        if(i.qb_invoice_info) {
          i.qb_invoice_info = JSON.parse(i.qb_invoice_info);
          console.log(i.qb_invoice_info);
        }

        i.qb_invoice_info_real_time = null;

        // new invoices..
        if(!i.qb_invoice_id) {
          if(i.vendor_pay_period_description) {
            let date_ranges = i.vendor_pay_period_description.split('to')
            if (date_ranges && date_ranges.length == 2) {
              i.invoice_date = date_ranges[1].trim();
              i.is_dirty = true;
            }
          }
        }
      })

      let distinct_original_file_id = [...new Set(this.original_invoices.map(x => x.original_file_id))];
      let original_file_promises = distinct_original_file_id.map(original_file_id => InvoiceService.original_files.get(original_file_id));
      let original_files = await Promise.all(original_file_promises);

      this.invoices = [];
      await this.delay(500);

      this.original_invoices.forEach((x) => {
        x.invoice_line_items = invoice_info.invoice_line_items.filter(y => y.bq_invoice_id === x.bq_invoice_id);
        x.original_file_info = original_files.find(o => o._id === x.original_file_id);
        if(x.qb_invoice_id) {

          /*
          InvoiceService.invoices.get_quickbook_invoice_by_id({
            realm_id: this.realm_id_store,
            access_token: this.access_token,
            qb_invoice_id: x.qb_invoice_id
          }).then(qb_invoice_info_real_time => {
            if (qb_invoice_info_real_time.LinkedTxn) {
              qb_invoice_info_real_time.payment_info = qb_invoice_info_real_time.LinkedTxn.filter(x => x.TxnType === 'Payment');
            }
            x.qb_invoice_info_real_time = qb_invoice_info_real_time;
            let custom_field_vpp_description = x.qb_invoice_info_real_time.CustomField.find(x => x.Name === 'Vendor Pay Period Desc');
            if (custom_field_vpp_description) {
              x.qb_invoice_info_real_time.vendor_pay_period_description = custom_field_vpp_description.StringValue;
            }
          }).catch((err) => {
            let qb_authorized = !err.toString().includes('401');
            if (!qb_authorized) {
              this.logout();
            }
          });
          */

        }
      });

      if (this.filters.has_invoice_number) {
        this.invoices = this.original_invoices.filter(x => x.qb_invoice_id);
      } else if (this.filters.has_invoice_number === false) {
        this.invoices = this.original_invoices.filter(x => !x.qb_invoice_id);
      } else {
        this.invoices = this.original_invoices;
      }

      this.invoices.sort((x, y) => {
        if (x.original_file_name < y.original_file_name) {
          return -1
        }
        if (x.original_file_name < y.original_file_name) {
          return -1
        }
        return 0
      });
      this.is_loading_data = false;
    },
    async delete_invoice_prompt(invoice, index) {
      this.mark_invoice_as_invalid = false;
      this.selected_invoice = invoice;
      this.qb_dialog = true;
      this.selected_vendor_payment_report = {
        original_file_id: invoice.original_file_id,
        original_file_name: invoice.original_file_name,
        is_loading: true,
      }
      this.selected_vendor_payment_report.invoices = this.invoices.filter(x => x.original_file_id === invoice.original_file_id);
      await this.load_invoices_from_quickbooks(this.selected_vendor_payment_report);
      this.selected_vendor_payment_report.is_loading = false;
      this.$set(this.invoices, index, invoice);
    },
    mark_invoice_as_invalid_prompt(invoice) {
      this.mark_invoice_as_invalid = true;
      this.selected_invoice = invoice;
      this.dialog = true;
    },
    async delete_invoice(invoice) {
     this.dialog_is_async_operation_in_progress = true;
      let result = await InvoiceService.invoices.delete({
        realm_id: this.realm_id_store,
        access_token: this.access_token,
        bq_invoice_id: invoice.bq_invoice_id,
        qb_invoice_id: invoice.qb_invoice_id,
        original_file_id: invoice.original_file_id,
      });
      console.log(result);
      this.dialog_is_async_operation_in_progress = result;
      await this.load();
    },
    async has_invoice_number_updated(value) {
      await this.update_filter({has_invoice_number: value})

      await this.load();

      if (value) {
        this.invoices = this.original_invoices.filter(x => x.qb_invoice_id)
      } else if (value === false) {
        this.invoices = this.original_invoices.filter(x => !x.qb_invoice_id)
      } else {
        this.invoices = this.original_invoices;
      }
      this.invoices.forEach(x => {
        x.invoice_line_items = this.invoice_info.invoice_line_items.filter(y => y.bq_invoice_id === x.bq_invoice_id);
      });
      this.search_text = null
    },
    async panel_change(event, invoice, index) {
      invoice.qb_info_loading = true;
      this.$set(this.invoices, index, invoice);
      if(!invoice.original_file_info) {
        try {
          let original_file_info = await InvoiceService.original_files.get(invoice.original_file_id);
          if (original_file_info) {
            let extracted_file = original_file_info.extracted_files.find(x => x._id === invoice.extracted_file_id);
            if (extracted_file) {
              original_file_info.extracted_cloud_storage_path = extracted_file.cloud_storage_path;
              original_file_info.cleaned_cloud_storage_path = extracted_file.cleaned_file.cloud_storage_path;
            }
          }
          invoice.original_file_info = original_file_info;
        } catch (err) {
          console.log(err);
        }
      }
      if(invoice.qb_invoice_id && !invoice.qb_invoice_info_real_time) {
        try {

          let qb_invoice_info_real_time = await InvoiceService.invoices.get_quickbook_invoice_by_id({
            realm_id: this.realm_id_store,
            access_token: this.access_token,
            qb_invoice_id: invoice.qb_invoice_id
          });

          if (qb_invoice_info_real_time.LinkedTxn) {
            qb_invoice_info_real_time.payment_info = qb_invoice_info_real_time.LinkedTxn.filter(x => x.TxnType === 'Payment');
          }
          invoice.qb_invoice_info_real_time = qb_invoice_info_real_time;

          let custom_field_vpp_description = invoice.qb_invoice_info_real_time.CustomField.find(x => x.Name === 'Vendor Pay Period Desc');
          if (custom_field_vpp_description) {
            invoice.qb_invoice_info_real_time.vendor_pay_period_description = custom_field_vpp_description.StringValue;
          }
        } catch (err) {
          let qb_authorized = !err.toString().includes('401');
          if (!qb_authorized) {
            this.logout();
            return;
          }
        }
      }
      invoice.qb_info_loading = false;
      this.$set(this.invoices, index, invoice);
      if(event.target.classList.contains('v-expansion-panel-header--active')) {
        console.log("Panel is closing/now closed.");
      } else {
        console.log("Panel is opening/now open.");
      }
    },
    view_vendor_payment_report_metrics(vendor_payment_report) {
      this.selected_vendor_payment_report = vendor_payment_report;
      this.report_details_dialog = true;
    },
    async load_invoices_from_quickbooks(vendor_payment_report) {
      let qb_invoice_ids = vendor_payment_report.invoices.filter(x => x.qb_invoice_info && x.qb_invoice_id).map(x => x.qb_invoice_id);
      if (qb_invoice_ids && qb_invoice_ids.length) {
        let qb_invoices_promises = qb_invoice_ids.map(i => {
          return InvoiceService.invoices.get_quickbook_invoice_by_id({
            realm_id: this.realm_id_store,
            access_token: this.access_token,
            qb_invoice_id: i
          });
        });

        try {
          let results = await Promise.allSettled(qb_invoices_promises);
          results.forEach(r => {
            if(r.status === 'fulfilled') {
              let qbi = r.value
              if (qbi.LinkedTxn) {
                qbi.payment_info = qbi.LinkedTxn.filter(x => x.TxnType === 'Payment');
              }
              let invoice = vendor_payment_report.invoices.find(x => x.qb_invoice_id === qbi.Id);
              if (invoice) {
                invoice.qb_invoice_info = qbi;
              }
            }
          });
        } catch (err) {
          let qb_authorized = !err.toString().includes('401');
          if(!qb_authorized) {
            this.logout();
          }
        }
      }
    },
    brand_updated(value) {


      this.report_fil

      alert('jambs');

      this.update_filter({brand: value})
    },
  },
  async mounted() {
    await this.load_brands();

    if (this.is_logged_in) {
        await this.load();
    }
    else if (this.code && this.realm_id) {
      try {
        await this.get_tokens_v1({code: this.code, realm_id: this.realm_id});
          await this.load();
      }
      catch (err) {
        alert(err);
      }
    }

    if(this.filters.has_invoice_number === false) {
      if(this.invoices && this.invoices.length > 0) {
        console.log(utility_service.get_brand_name(this.invoices[0].brand));
        // await this.update_filter({brand: null});
      }
    } else {
      // await this.update_filter({brand: '4Change Energy'});
    }
  }
}
</script>

<style scoped>
a{
  white-space: pre-wrap;
  word-wrap: break-word;
  word-break: break-all;
  white-space: normal;
  display:block;
}

.warning-display {
  color: red;
  font-size: 18px;
  font-weight: 700;
}

.ok-display {
  color: green;
  font-size: 18px;
  font-weight: 700;
}

.dirty_panel {
  background-color: lightblue;
}

.no_dirty_panel {
  background-color: white;
}

</style>
