<template>
  <v-container fluid>
    <v-row justify="center">
      <v-col cols="12" md="6">
        <v-card>
          <v-card-title>Brands</v-card-title>
          <v-card-subtitle>
            <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line
                          hide-details></v-text-field>
          </v-card-subtitle>
          <v-card-actions>
            <v-dialog v-model="dialog" max-width="500px">
              <template v-slot:activator="{on,attrs}">
                <v-btn color="secondary" v-on="on" v-bind="attrs">Add New Brand</v-btn>
              </template>
              <v-card>
                <v-card-title>Add new item</v-card-title>
                <v-card-text>
                  <v-form ref="form" v-model="is_valid">
                    <v-text-field v-model="new_item.name" :rules="[rules.required('name'), rules.counter(3)]"
                                  label="Name"></v-text-field>
                    <v-text-field v-model="new_item.description" :rules="[rules.required('description'), rules.counter(5)]"
                                  label="Description"></v-text-field>
                    <v-text-field v-model="new_item.provider" label="Provider"></v-text-field>
                    <v-checkbox v-model="new_item.active" label ="Is Active"></v-checkbox>
                  </v-form>
                </v-card-text>
                <v-card-actions>
                  <v-btn color="secondary" :disabled="!is_valid" @click="$refs.form.validate() && insert_brand(new_item)">
                    Add Brand
                  </v-btn>
                  <v-btn color="error" @click="dialog = false">Cancel</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-card-actions>

          <v-data-table v-if="brands.length"  :headers="headers" :items="brands" :options.sync="pagination_settings" :search="search">

            <template v-slot:item.name="props"  >
              <v-edit-dialog :return-value.sync="props.item.description" @save="save_brand(props.item)" @cancel="cancel"  v-if="!props.item.is_in_use"
                             cancel-text="Cancel" save-text="Save" large persistent>
                {{ props.item.name }}
                <template v-slot:input>
                  <v-text-field v-model="props.item.name" label="Edit" counter></v-text-field>
                </template>
              </v-edit-dialog>
              <div v-else>{{ props.item.name }}</div>
            </template>

            <template v-slot:item.description="props"  >
              <v-edit-dialog :return-value.sync="props.item.description" @save="save_brand(props.item)" @cancel="cancel"
                             cancel-text="Cancel" save-text="Save" large persistent>
                {{ props.item.description }}
                <template v-slot:input>
                  <v-text-field v-model="props.item.description" label="Edit" counter></v-text-field>
                </template>
              </v-edit-dialog>
            </template>

            <template v-slot:item.provider="props">
              <v-edit-dialog :return-value.sync="props.item.provider" @save="save_brand(props.item)" @cancel="cancel"  v-if="!props.item.is_in_use"
                             cancel-text="Cancel" save-text="Save" large persistent>
                {{ props.item.provider }}
                <template v-slot:input>
                  <v-text-field v-model="props.item.provider" label="Edit" counter></v-text-field>
                </template>
              </v-edit-dialog>
              <div v-else>{{ props.item.description }}</div>
            </template>

            <template v-slot:item.active="props" >
              <v-edit-dialog :return-value.sync="props.item.active" @save="save_brand(props.item)" @cancel="cancel" v-if="!props.item.is_in_use"
                             cancel-text="Cancel" save-text="Save" large persistent>
                <v-container>
                  <v-simple-checkbox v-model="props.item.active" disabled  ></v-simple-checkbox>
                  <v-icon>mdi-pencil</v-icon>
                </v-container>
                <template v-slot:input>
                  <v-checkbox class="mt-5" v-model="props.item.active" label ="Is Active"></v-checkbox>
                </template>
              </v-edit-dialog>
              <div class="ms-3" v-else>  <v-simple-checkbox v-model="props.item.active" disabled  ></v-simple-checkbox></div>
            </template>
          </v-data-table>
        </v-card>
        <v-snackbar v-model="snack.active" :timeout="3000" :color="snack.color">
          {{ snack.text }}
          <template v-slot:action="{ attrs }">
            <v-btn v-bind="attrs" text @click="snack.active = false">
              Close
            </v-btn>
          </template>
        </v-snackbar>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

import report_service from '../services/report.service'

export default {
  name: "brands",
  data() {
    return {
      footerProps: {'items-per-page-options': [5, 10, 15, 20, -1] },
      dialog: false,
      search: null,
      headers: [
        {text: 'Name', value: 'name'},
        {text: 'Description', value: 'description'},
        {text: 'Provider', value: 'provider'},
        {text: 'Active', value: 'active'},
      ],
      new_item: {},
      brands: [],
      snack: {
        active: false,
        color: 'primary',
        text: 'Hot...... Tooooooo hot!!!!'
      },
      is_valid: null,
      rules: {
        required: brand => value => !!value || `${brand} required`,
        counter: size => value => !!value && value.length >= size || `min ${size} characters`,
      }
    }
  },
  computed: {
    pagination_settings: {
      get() {
        return this.$store.getters['pagination_settings/brands'];
      },
      set(value) {
        this.$store.dispatch('pagination_settings/update_brands', value);
      }
    }
  },
  methods: {
    async save_brand(brand) {
      try {
        await report_service.brands.update(brand);
        this.snack.active = true;
        this.snack.color = 'success';
        this.snack.text = `Updated brand ${brand.name}`;
        await this.search_brands();
      }
      catch (err) {
        this.snack.active = true;
        this.snack.color = 'error';
        this.snack.text = `Error updating brand ${brand.name}: ${err.message}`;
      }
    },
    cancel() {
      this.snack.active = true;
      this.snack.color = 'error';
      this.snack.text = 'Changes were not saved';
    },
    async insert_brand(brand) {
      try {
        console.log(brand);
        await report_service.brands.insert(brand);
        this.snack.active = true;
        this.snack.color = 'success';
        this.snack.text = `Added new brand ${brand.name}`;
        this.dialog = false;
        await this.search_brands();
      }
      catch (err) {
        this.snack.active = true;
        this.snack.color = 'error';
        this.snack.text = `Error inserting brand ${brand.name}: ${err.message}`;
      }
    },
    async search_brands() {
      this.brands = await report_service.brands.search();
    }
  },
  async mounted() {
    await this.search_brands();
  }
}

</script>

<style scoped>

</style>
