import Vue from 'vue'
import Vuex from 'vuex'
import {auth, google_login, sign_out} from '../../plugins/firebase'

Vue.use(Vuex)

const state = {
    is_logged_in: false,
    login_error: null
}

const getters = {
    user: () => auth.currentUser,
    login_error: state => state.login_error,
    is_logged_in: state => state.is_logged_in
}

const actions = {
    async login({commit}) {
        try {
            commit('clear_login_error');
            await google_login()
            commit('set_is_logged_in', true);
        }
        catch (err) {
            commit('set_login_error', err);
        }
    },
    async logout({commit}) {
        await sign_out();
        commit('set_is_logged_in', false)
    }
}

const mutations = {
    set_is_logged_in(state, value) {
        state.is_logged_in = value;
    },
    set_login_error(state, err) {
        state.login_error = err;
    },
    clear_login_error(state) {
        state.login_error = null;
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
