<template>
  <v-container fluid v-if="header_mapping">
    <v-row justify="center" v-if="header_mapping.headers.length === 0">
      <v-col cols="12" md="6">
        <v-textarea v-model="paste" placeholder="paste or type header list items on new lines"></v-textarea>
        <v-btn @click="header_mapping.headers = paste.split('\n')">Continue</v-btn>
      </v-col>
    </v-row>
    <v-row v-if="header_mapping.headers.length">
      <v-col cols="12">
        <v-btn color="primary" @click="save">Save</v-btn>
      </v-col>
    </v-row>
    <v-row v-if="header_mapping.headers.length">
      <v-col cols="4">
        <v-card class="mb-2">
          <v-card-title>Classification</v-card-title>
          <v-card-text>
            <v-autocomplete label="Provider" v-model="header_mapping.provider" item-text="name" item-value="name"
                            :items="providers" clearable></v-autocomplete>
            <v-autocomplete label="Brand" v-model="header_mapping.brand" :disabled="brand_used_in_mapping"
                            @change="brand_changed" :items="brands" item-text="name" item-value="name"
                            clearable></v-autocomplete>
            <v-select label="Scope" v-model="header_mapping.scope" :items="['vendor_payment_records']"
                      @change="scope_changed" clearable></v-select>
            <v-select label="Payment Type(s)" :items="['Upfront', 'Residual', 'Both']"
                      v-model="header_mapping.payment_types" @change="payment_type_changed" clearable></v-select>
            <v-switch label="Ignore" v-model="header_mapping.ignore"></v-switch>
            <v-textarea label="Description" v-model="header_mapping.description"></v-textarea>
          </v-card-text>
        </v-card>

        <v-card>
          <v-card-title>Static Properties</v-card-title>
          <v-card-text>
            <v-select v-model="custom_property" :items="unused_properties" item-text="name" item-value="name"
                      append-outer-icon="mdi-plus-circle"
                      @click:append-outer="add_static_property(custom_property, null)"></v-select>

            <v-text-field v-for="static_property in static_properties_key_list"
                          :disabled="['brand','scope', 'payment_type'].includes(static_property)"
                          v-model="header_mapping.static_mappings[static_property]" :label="static_property"
                          :key="static_property" @click:append-outer="remove_static_property(static_property)"
                          append-outer-icon="mdi-delete"></v-text-field>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="4">
        <v-card>
          <v-card-title>Properties</v-card-title>
          <v-card-text style="max-height: 500px; overflow: auto">
            <v-form v-if="header_mapping.mappings">
              <v-autocomplete v-model="header_mapping.mappings[header]" v-for="header in header_mapping.headers"
                              :label="header" :error="!header_mapping.mappings[header]"
                              :key="header" clearable :items="allowed_properties" item-value="name" item-text="text">
                <template v-slot:item="{active, item, on, attrs}">
                  <v-list-item three-line v-on="on" v-bind="attrs" :disabled="used_properties.includes(item.name)">
                    <v-list-item-content>
                      <v-list-item-title>{{ item.text }}</v-list-item-title>
                      <v-list-item-subtitle>{{ item.name }}</v-list-item-subtitle>
                      <v-list-item-subtitle>{{ item.desc }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </template>
              </v-autocomplete>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="4">
        <v-card class="mb-2">
          <v-card-title>
            <span v-if="unmapped_required_properties.length" class="error--text">
              {{ unmapped_required_properties.length }} required properties not mapped
            </span>
            <span v-else class="success--text">All required properties mapped</span>
          </v-card-title>
          <v-card-text>
            <v-list-item v-for="item in required_property_status" :key="item.name">
              <v-icon class="mr-2" :color="item.mapped ? 'success': 'error'">{{ item.mapped ? 'mdi-check' : 'mdi-close' }}</v-icon>
              <span>{{ item.name }}</span>
            </v-list-item>
          </v-card-text>
        </v-card>

        <v-card v-if="selected_brand && selected_brand.examples">
          <v-card-title>
            {{ selected_brand.name }} Examples
          </v-card-title>
          <v-card-text>
            <p v-for="[key,value] in Object.entries(selected_brand.examples)" :key="key">
              <strong>{{ key }}</strong>: {{ value }}
            </p>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import report_service from "../../services/report.service";
import {mapGetters} from "vuex";

export default {
  name: "HeaderMappingEditControl",
  props: {
    header_mapping: Object
  },
  computed: {
    selected_brand() {
      if (this.header_mapping && this.header_mapping.brand) {
        const found = this.brands.find(x => x.name === this.header_mapping.brand)
        return found;
      }
      else {
        return null;
      }
    },
    ...mapGetters('reports', [
      'brands',
      'providers'
    ]),
    required_properties() {
      if (this.header_mapping.ignore) {
        return [];
      }
      else {
        switch (this.header_mapping.payment_types) {
          case 'Upfront':
            return [...this.required_property_map.base, ...this.required_property_map.Upfront];
          case 'Residual':
            return [...this.required_property_map.base, ...this.required_property_map.Upfront, ...this.required_property_map.Residual];
          case 'Both':
            return [...this.required_property_map.base, ...this.required_property_map.Upfront, ...this.required_property_map.Residual];
          default:
            return [...this.required_property_map.base, ...this.required_property_map.Upfront, ...this.required_property_map.Residual];
        }
      }
    },
    required_property_status() {
      if (this.header_mapping.mappings) {
        const values = [...Object.values(this.header_mapping.mappings), ...Object.keys(this.header_mapping.static_mappings)];
        return this.required_properties.map(x => ({name: x, mapped: values.includes(x)}));
      }
      else {
        return this.required_properties;
      }
    },
    unmapped_required_properties() {
      if (this.header_mapping.mappings) {
        const values = [...Object.values(this.header_mapping.mappings), ...Object.keys(this.header_mapping.static_mappings)];
        return this.required_properties.filter(x => !values.includes(x));
      }
      else {
        return this.required_properties;
      }
    },
    brand_used_in_mapping() {
      return Object.values(this.header_mapping.mappings).includes('brand');
    },
    used_properties() {
      const mapping_used = this.header_mapping.mappings ? Object.values(this.header_mapping.mappings) : [];
      return [...mapping_used, ...this.static_properties_key_list];
    },
    unused_properties() {
      return this.allowed_properties.filter(x => !this.used_properties.includes(x.name));
    },
    static_properties_key_list() {
      return this.header_mapping.static_mappings ? Object.keys(this.header_mapping.static_mappings) : [];
    }
  },
  data() {
    return {
      custom_property: null,
      allowed_properties: [],
      required_property_map: {
        base: ['brand', 'scope', 'payment_type'],
        Upfront: ['contract_key', 'payment_amount'],
        Residual: ['invoice_key', 'usage_amount', 'usage_start_date', 'usage_end_date', 'payment_rate']
      }
    }
  },
  methods: {
    brand_changed() {
      if (this.header_mapping.brand) {
        this.add_static_property('brand', this.header_mapping.brand);
      }
      else {
        this.remove_static_property('brand');
      }
    },
    scope_changed() {
      if (this.header_mapping.scope) {
        this.add_static_property('scope', this.header_mapping.scope);
      }
      else {
        this.remove_static_property('scope')
      }
    },
    payment_type_changed() {
      if (this.header_mapping.payment_types) {
        if (this.header_mapping.payment_types === 'Upfront') {
          this.add_static_property('payment_type', 'Upfront');
        }
        if (this.header_mapping.payment_types === 'Residual') {
          this.add_static_property('payment_type', 'Residual');
        }
        if (this.header_mapping.payment_types === 'Both') {
          this.remove_static_property('payment_type')
        }
      }
      else {
        this.remove_static_property('payment_type')
      }
    },
    add_static_property(key, value) {
      this.$set(this.header_mapping.static_mappings, key, value);
    },
    remove_static_property(key) {
      this.$delete(this.header_mapping.static_mappings, key);
    },
    async save() {
      if (this.header_mapping._id) {
        this.header_mapping = await report_service.header_mappings.update(this.header_mapping._id, this.header_mapping);
      }
      else {
        this.header_mapping = await report_service.header_mappings.insert(this.header_mapping);
      }
    }
  },
  async mounted() {
    this.allowed_properties = await report_service.allowed_properties.search();
  }
}
</script>

<style scoped>

</style>