<template>
  <div>
    <v-data-table v-if="indexed_items" :loading="loading" show-select v-model="selected_items" :headers="headers"
                  :items="indexed_items" show-expand item-key="id">
      <template v-slot:top>
        <v-row>
          <v-col cols="12" md="3">
            <v-autocomplete :value="filters.brand" :loading="brands_loading" :items="brands"
                            @change="brand_updated" label="Brand"
                            placeholder="Select a brand..." clearable></v-autocomplete>
          </v-col>
          <v-col cols="12" md="3">
            <v-autocomplete :value="filters.has_invoice_number" :items="invoice_options" item-value="value"
                            item-text="name" @change="has_invoice_number_updated" label="Invoice Options"
                            placeholder="Select an invoice option..." clearable></v-autocomplete>
          </v-col>
          <v-col cols="12" md="3">
            <v-autocomplete :value="filters.group" :items="group_options" @change="group_updated"
                            label="Group" placeholder="Select a grouping..." multiple></v-autocomplete>
          </v-col>
          <v-col>
            <v-btn icon large dark color="secondary" @click="search" class="mt-3">
              <v-icon>mdi-refresh</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </template>
      <template v-slot:item.group="{item}">
        <v-simple-table>
          <tbody>
          <tr v-for="[key, value] in Object.entries(item.group).filter(x => !['brand', 'file_id', 'file_name', 'job_id'].includes(x[0]))"
              :key="key">
            <td>{{ key }}</td>
            <td>{{ value }}</td>
          </tr>
          </tbody>
        </v-simple-table>
      </template>
      <template v-slot:item.types="{item}">{{ item.types.length }}</template>
      <template v-slot:item.total="{item}">{{ item.total | currency }}</template>
      <template v-slot:item.file="{item}">
        <google-file-link :file_id="item.group.file_id" :file_name="item.group.file_name"></google-file-link>
      </template>
      <template v-slot:item.actions="{item}">
        <report-item-delete-dialog :item="item"></report-item-delete-dialog>
      </template>
      <template v-slot:expanded-item="{headers, item}">
        <td :colspan="headers.length" class="pa-0">
          <v-simple-table>
            <thead>
            <tr>
              <th>Type</th>
              <th>Count</th>
              <th>Total</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="type in item.types" :key="type.type">
              <td>{{ type.type }}</td>
              <td>{{ type.count }}</td>
              <td>{{ type.total | currency }}</td>
            </tr>
            </tbody>
          </v-simple-table>
        </td>
      </template>
    </v-data-table>
    <v-alert v-if="error" color="red" dark>{{ error }}</v-alert>
  </div>
</template>

<script>
import {mapGetters, mapActions} from "vuex";
import GoogleFileLink from "../GoogleFileLink";
import ReportItemDeleteDialog from "./ReportItemDeleteDialog";

export default {
  name: "ReportSelector",
  components: {ReportItemDeleteDialog, GoogleFileLink},
  data() {
    return {
      headers: [
        // {text: 'Group', value: 'group'},
        {text: 'Brand', value: 'group.brand'},
        {text: 'File Name', value: 'group.file_name'},
        {text: 'File Id', value: 'group.file_id'},
        {text: 'Job Id', value: 'group.job_id'},
        {text: 'Payment Period', value: 'group.payment_period'},
        {text: 'Sheet Name', value: 'group.sheet_name'},
        {text: 'Sales Channel', value: 'group.sales_channel'},
        {text: 'Invoice #', value: 'group.invoice.DocNumber'},
        {text: 'Types', value: 'types'},
        {text: 'Count', value: 'count'},
        {text: 'Total', value: 'total'},
        {text: 'File', value: 'file'},
        {text: 'Actions', value: 'actions'}
      ],
      group_options: [
        {text: 'Pay Period', value: 'payment_period'},
        {text: 'Sheet Name', value: 'sheet_name'},
        {text: 'Sales Channel', value: 'sales_channel'}
      ]
    }
  },
  computed: {
    ...mapGetters('reports', [
      'items',
      'loading',
      'has_selected_items',
      'error'
    ]),
    ...mapGetters('report_filters', [
      'filters',
      'brands',
      'brands_loading',
      'invoice_options',
    ]),
    indexed_items() {
      return this.items.map((item, index) => ({
        id: index,
        ...item
      }))
    },
    selected_items: {
      get() {
        return this.$store.state.reports.selected_items;
      },
      set(value) {
        this.$store.dispatch('reports/set_selected_items', value);
      }
    },
  },
  methods: {
    ...mapActions('report_filters', [
      'load_brands',
      'update_filter'
    ]),
    ...mapActions('reports', [
      'search'
    ]),
    brand_updated(value) {
      this.update_filter({brand: value})
    },
    has_invoice_number_updated(value) {
      this.update_filter({has_invoice_number: value})
    },
    group_updated(value) {
      this.update_filter({group: value})
    }
  }
}
</script>

<style scoped>

</style>