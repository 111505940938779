var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',[_c('v-card',[_c('v-card-title',[_vm._v("File Runs")]),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"item-key":"_id","show-expand":"","expanded":_vm.expanded},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"item.report_options",fn:function(ref){
var item = ref.item;
return _vm._l((Object.entries(item.report_options)),function(ref){
var key = ref[0];
var value = ref[1];
return _c('p',{key:key},[_c('strong',[_vm._v(_vm._s(key))]),_vm._v(": "+_vm._s(value)+" ")])})}},{key:"item.date_created",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moment")(item.date_created,'calendar'))+" ")]}},{key:"item.found_all_sheets",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"color":item.found_all_sheets ? 'success' : 'error'}},[_vm._v(" "+_vm._s(item.found_all_sheets ? 'mdi-check' : 'mdi-close')+" ")])]}},{key:"item.processed_all_sheets",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"color":item.processed_all_sheets ? 'success' : 'error'}},[_vm._v(" "+_vm._s(item.processed_all_sheets ? 'mdi-check' : 'mdi-close')+" ")])]}},{key:"item.total_number_of_sheets",fn:function(ref){
var item = ref.item;
return [_c('chip-number',{attrs:{"item_number":item.total_number_of_sheets}})]}},{key:"item.number_of_sheets_with_found_headers",fn:function(ref){
var item = ref.item;
return [_c('chip-number',{attrs:{"item_number":item.number_of_sheets_with_found_headers,"color":item.number_of_sheets_with_found_headers < item.total_number_of_sheets ? 'error': 'secondary'}})]}},{key:"item.number_of_sheets_to_process",fn:function(ref){
var item = ref.item;
return [_c('chip-number',{attrs:{"item_number":item.number_of_sheets_to_process}})]}},{key:"item.number_of_sheets_processed",fn:function(ref){
var item = ref.item;
return [_c('chip-number',{attrs:{"item_number":item.number_of_sheets_processed,"color":item.number_of_sheets_processed < item.number_of_sheets_to_process ? 'error' : 'secondary'}})]}},{key:"item.number_of_duplicates_found",fn:function(ref){
var item = ref.item;
return [_c('chip-number',{attrs:{"item_number":item.number_of_duplicates_found,"color":item.number_of_duplicates_found > 0 ? 'warning' : 'secondary'}})]}},{key:"item.error",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"error--text"},[_vm._v(_vm._s(item.error))])]}},{key:"item.file",fn:function(ref){
var item = ref.item;
return [_c('google-file-link',{attrs:{"file_id":item.file.id,"file_name":item.file.name}})]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{class:{'pa-0': item.sheet_results && item.sheet_results.length},attrs:{"colspan":headers.length}},[(item.sheet_results)?_c('v-simple-table',[_c('thead',[_c('tr',[_c('th',[_vm._v("Sheet Name")]),_c('th',[_vm._v("Header Count")]),_c('th',[_vm._v("Found")]),_c('th',[_vm._v("Processed")]),_c('th',[_vm._v("Not Dupe")]),_c('th',[_vm._v("Has Data")]),_c('th',[_vm._v("Data Count")]),_c('th',[_vm._v("Hash")]),_c('th',[_vm._v("Actions")])])]),_c('tbody',_vm._l((item.sheet_results),function(sheet_result){return _c('tr',{key:sheet_result.sheet_name},[_c('td',[_vm._v(_vm._s(sheet_result.sheet_name))]),_c('td',[(sheet_result.header_values)?_c('v-dialog',{attrs:{"max-width":"500px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-badge',{attrs:{"color":"primary","content":sheet_result.header_values.length,"overlap":"","bordered":""}},[_c('v-btn',_vm._g(_vm._b({attrs:{"color":"secondary","outlined":""}},'v-btn',attrs,false),on),[_vm._v(" View ")])],1)]}}],null,true)},[_c('v-card',[_c('v-card-title',[_vm._v("Headers")]),(sheet_result.header_values)?_c('v-data-table',{attrs:{"headers":[{text: 'Value', value: 'value'}],"items":sheet_result.header_values.map(function (x){ return ({key: x, value: x}); })}}):_vm._e()],1)],1):_c('p',[_vm._v("No headers found")])],1),_c('td',[_c('v-icon',{attrs:{"color":sheet_result.mapping || sheet_result.is_empty_sheet ? 'success' : 'error'}},[_vm._v(" "+_vm._s(sheet_result.mapping || sheet_result.is_empty_sheet ? 'mdi-check' : 'mdi-close')+" ")])],1),_c('td',[_c('v-icon',{attrs:{"color":sheet_result.processed ? 'success' : 'error'}},[_vm._v(" "+_vm._s(sheet_result.processed ? 'mdi-check' : 'mdi-close')+" ")])],1),_c('td',[_c('v-icon',{attrs:{"color":sheet_result.is_duplicate ? 'error' : 'success'}},[_vm._v(" "+_vm._s(sheet_result.is_duplicate ? 'mdi-close' : 'mdi-check')+" ")])],1),_c('td',[_c('v-icon',{attrs:{"color":sheet_result.has_data_items ? 'success' : 'error'}},[_vm._v(" "+_vm._s(sheet_result.has_data_items ? 'mdi-check' : 'mdi-close')+" ")])],1),_c('td',[_vm._v(_vm._s(sheet_result.data_item_count))]),_c('td',[_vm._v(_vm._s(sheet_result.hash))]),_c('td',[(sheet_result.header_values)?_c('v-btn',{attrs:{"icon":"","color":"primary","to":_vm.generate_header_mapping_link(item, sheet_result)}},[_c('v-icon',[_vm._v("mdi-plus")])],1):_vm._e()],1)])}),0)]):_c('div',{staticClass:"error--text"},[_vm._v("No sheet results")])],1)]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }