import Vue from 'vue'
import Router from 'vue-router'
import store from './store'
import Home from './views/Home'
import FileProcessorJobs from "./views/FileProcessorJobs";
import PreProcessorJobs from "./views/PreProcessorJobs";
import Invoices from './views/Invoices';

import NewInvoices from './views/NewInvoices';

import AllowedProperties from "./views/AllowedProperties";
import Brands from './views/brands';
import HeaderMappings from "./views/HeaderMappings";
import HeaderMappingEdit from "./views/HeaderMappingEdit";
import FileRuns from "./views/FileRuns";
import VendorPaymentRecords from "./views/VendorPaymentRecords";
import VendorPaymentReports from './views/VendorPaymentReports';
import PaymentPeriodSelection from "./views/PaymentPeriodSelection";
import CustomerSelection from "./views/CustomerSelection";

const auth_guard = (to, from, next) => {
    console.log(`Running auth guard`)
    if (store.getters["firebase/is_logged_in"]) {
        next();
    }
    else {
        next({name: 'home', query: {redirectFrom: to.name}})
    }
};

Vue.use(Router)

const router = new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [
        {
            path: '/',
            name: 'home',
            component: Home,
            beforeEnter: (to, from, next) => {
                if (store.getters["firebase/is_logged_in"]) {
                    next({name: 'file_processor'});
                }
                else {
                    next();
                }
            }
        },
        {
            path: '/file_processor',
            name: 'file_processor',
            component: FileProcessorJobs,
            beforeEnter: auth_guard
        },
        {
            path: '/pre_processor',
            name: 'pre_processor',
            component: PreProcessorJobs,
            beforeEnter: auth_guard
        },
        {
            path: '/invoices',
            name: 'invoices',
            component: Invoices,
            props: route => ({code: route.query.code, realm_id: route.query.realmId}),
            beforeEnter: auth_guard
        },
        {
            path: '/invoices_new',
            name: 'invoices_new',
            component: NewInvoices,
            props: route => ({code: route.query.code, realm_id: route.query.realmId}),
            beforeEnter: auth_guard
        },
        {
            path: '/allowed_properties',
            name: 'allowed_properties',
            component: AllowedProperties,
            beforeEnter: auth_guard
        },
        {
            path: '/brands',
            name: 'brands',
            component: Brands,
            beforeEnter: auth_guard
        },
        {
            path: '/header_mappings',
            name: 'header_mappings',
            component: HeaderMappings,
            beforeEnter: auth_guard
        },
        {
            path: '/header_mappings/:id',
            name: 'header_mappings_edit',
            component: HeaderMappingEdit,
            beforeEnter: auth_guard,
            props: route => ({
                id: route.params.id,
                copy: route.query.copy,
                headers: route.query.headers,
                brand: route.query.brand,
                scope: route.query.scope,
                provider: route.query.provider,
                file_id: route.query.file_id
            })
        },
        {
            path: '/file_runs',
            name: 'file_runs',
            component: FileRuns,
            beforeEnter: auth_guard,
            props: route => ({
                file_run_id: route.query.file_run_id
            })
        },
        {
            path: '/vendor_payment_records',
            name: 'vendor_payment_records',
            component: VendorPaymentRecords,
            beforeEnter: auth_guard
        },
        {
            path: '/vendor_payment_reports',
            name: 'vendor_payment_reports',
            component: VendorPaymentReports,
            beforeEnter: auth_guard
        },
        {
            path: '/payment_period_selection',
            name: 'payment_period_selection',
            component: PaymentPeriodSelection,
            beforeEnter: (to, from, next) => {
                if (store.getters["reports/has_selected_items"]) {
                    auth_guard(to, from, next);
                }
                else {
                    next({name: 'vendor_payment_records'});
                }
            }
        },
        {
            path: '/customer_selection',
            name: 'customer_selection',
            component: CustomerSelection,
            beforeEnter: (to, from, next) => {
                if (store.getters["reports/description"]) {
                    auth_guard(to, from, next);
                }
                else {
                    next({name: 'vendor_payment_records'});
                }
            }
        }
    ]
})

export default router;
