<template>
  <v-container fluid>
    <v-row>
      <v-col cols="8">
        <v-stepper v-model="step">
          <v-stepper-header>
            <v-stepper-step step="1" :complete="!!is_logged_in">
              Login to Quickbooks
            </v-stepper-step>
            <v-divider></v-divider>
            <v-stepper-step step="2" :complete="!!has_selected_items" :editable="step_two_enabled">
              Select reports
            </v-stepper-step>
            <v-divider></v-divider>
            <v-stepper-step step="3" :complete="!!description" :editable="step_three_enabled">
              Select Pay Period
            </v-stepper-step>
            <v-divider></v-divider>
            <v-stepper-step step="4" :complete="!!has_selected_customer" :editable="step_four_enabled">
              Select Customer
            </v-stepper-step>
            <v-divider></v-divider>
            <v-stepper-step step="5" :editable="step_five_enabled">
              Create/Modify Invoice
            </v-stepper-step>
          </v-stepper-header>
          <v-stepper-items>
            <v-stepper-content step="1">
              <v-btn v-if="!is_logged_in" @click="login">Login to Quickbooks</v-btn>
            </v-stepper-content>
            <v-stepper-content step="2">
              <v-card>
                <report-selector></report-selector>
                <v-card-actions>
                  <v-btn color="primary" :disabled="!has_selected_items" @click="next_step">Next</v-btn>
                </v-card-actions>
              </v-card>
            </v-stepper-content>
            <v-stepper-content step="3">
              <v-card>
                <v-card-text>
                  <v-radio-group v-model="use_custom_description" @change="enable_custom">
                    <v-radio :value="false" label="Date Range"></v-radio>
                    <v-radio :value="true" label="Custom"></v-radio>
                  </v-radio-group>
                  <v-date-picker v-if="!use_custom_description" no-title width="20vw" range
                                 v-model="date_range"></v-date-picker>
                  <v-text-field v-if="use_custom_description" v-model="custom_description"
                                placeholder="Enter custom description..." :rules="custom_description_rules"
                                counter="31"></v-text-field>
                </v-card-text>
                <v-card-actions>
                  <v-btn :disabled="!description" color="primary" @click="next_step">Next</v-btn>
                </v-card-actions>
              </v-card>
            </v-stepper-content>
            <v-stepper-content step="4">
              <v-col cols="6">
                <v-card>
                  <v-card-text>
                    <customer-selector></customer-selector>
                  </v-card-text>
                  <v-card-actions>
                    <v-btn :disabled="!has_selected_customer" color="primary" @click="next_step">Next</v-btn>
                  </v-card-actions>
                </v-card>
              </v-col>
            </v-stepper-content>
            <v-stepper-content step="5">
              <v-card class="mb-2">
                <v-card-title>
                  Selected Reports
                </v-card-title>
                <v-simple-table v-for="item in selected_items" :key="item._id">
                  <thead>
                  <tr>
                    <th colspan="5">
                      <h2> {{ item.group.brand }}: {{ item.total | currency }} -- {{ item.count }} items</h2>
                    </th>
                  </tr>
                  <tr>
                    <th>Type</th>
                    <th>Count</th>
                    <th>Total</th>
                    <th>Item Type</th>
                    <th>Item Desc</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="type in item.types" :key="type.type">
                    <td>{{ type.type }}</td>
                    <td>{{ type.count }}</td>
                    <td>{{ type.total | currency }}</td>
                    <td>
                      <v-autocomplete :value="type.selected_item" :items="items" item-text="FullyQualifiedName"
                                      @change="e=>update_selected_item_item_type({_id: item._id, type: type.type, item: e})"
                                      return-object clearable
                      ></v-autocomplete>
                    </td>
                    <td>
                      <v-text-field v-if="type.selected_item" :value="type.selected_item.Description"
                                    @change="e=>update_selected_item_item_desc({_id: item._id, type: type.type, desc: e})"
                                    class="mt-0" clearable
                      ></v-text-field>
                    </td>
                  </tr>
                  </tbody>
                </v-simple-table>
                <v-card-text>
                  <v-menu
                      v-model="date_opened"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                          v-model="invoice_date"
                          label="Invoice Date"
                          prepend-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                        v-model="invoice_date"
                        @input="date_opened = false"
                    ></v-date-picker>
                  </v-menu>
                </v-card-text>
              </v-card>

              <v-card v-if="has_selected_customer">
                <v-card-title>Invoices for {{ selected_customer.DisplayName }}</v-card-title>
                <v-btn :disabled="!has_selected_customer || !selected_items_have_items || !invoice_date" color="primary"
                       @click="finish_invoice">
                  Create New Invoice
                </v-btn>
                <blockquote>or select an option for an existing invoice</blockquote>
                <invoice-selector></invoice-selector>
                <v-card-actions>
                  <v-btn @click="reset_form" color="red" dark>Clear form and start new</v-btn>
                </v-card-actions>
              </v-card>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </v-col>

      <v-col cols="4" v-if="is_logged_in">

        <v-card class="mb-4">
          <v-card-title>Connected to quickbooks</v-card-title>
          <v-card-text>
            <v-btn color="primary" @click="logout()">
              <v-icon>mdi-logout</v-icon>
              Logout
            </v-btn>
          </v-card-text>
        </v-card>

        <v-card v-if="selected_items" class="mb-4">
          <v-card-title>Selected Items</v-card-title>
          <v-card-subtitle>Total: {{
              Math.round(selected_items.reduce((total, item) => total + item.total, 0) * 1000, 2) / 1000 | currency
            }}
          </v-card-subtitle>
          <v-list>
            <v-list-item v-for="item in selected_items" :key="item.id" three-line>
              <v-list-item-content>
                <v-list-item-title>{{ item.total | currency }} -- {{ item.count }}
                  items
                </v-list-item-title>
                <v-list-item-subtitle v-for="[key,value] in Object.entries(item.group)" :key="key">
                  <strong>{{ key }}</strong>: {{ value }}
                </v-list-item-subtitle>
                <v-list>
                  <v-list-item v-for="type in item.types" :key="type.type">
                    <strong>{{ type.type }}</strong>: {{ type.total | currency }} -- {{ type.count }} items
                  </v-list-item>
                </v-list>
              </v-list-item-content>
              <v-list-item-action>
                <v-btn icon dark color="error" @click="remove_item(item)">
                  <v-icon>mdi-undo</v-icon>
                </v-btn>
                <google-file-link :file_id="item.group.file_id" :file_name="item.group.file_name"></google-file-link>
              </v-list-item-action>
            </v-list-item>
          </v-list>
          <v-card-actions>
            <v-btn v-if="step==2 && has_selected_items" color="primary" @click="next_step">Next</v-btn>
          </v-card-actions>
        </v-card>

        <v-card v-if="description" class="mb-4">
          <v-card-title>Pay Period Desc</v-card-title>
          <v-card-subtitle v-if="matching_invoices" class="red--text">
            <p v-for="matching_invoice in matching_invoices" :key="matching_invoice.Id">
              Invoice: {{ matching_invoice.DocNumber }} matches this pay period
            </p>
          </v-card-subtitle>
          <v-card-text>
            {{ description }}
          </v-card-text>
          <v-card-actions>
            <v-btn v-if="step == 3" color="primary" @click="next_step">Next</v-btn>
          </v-card-actions>
        </v-card>

        <v-card v-if="selected_customer" class="mb-4">
          <v-card-title>Selected Customer</v-card-title>
          <v-card-text>
            {{ selected_customer.DisplayName }}
          </v-card-text>
          <v-card-actions>
            <v-btn v-if="step == 4" color="primary" @click="next_step">Next</v-btn>
          </v-card-actions>
        </v-card>

        <v-btn v-if="step == 5" :disabled="!has_selected_customer || !selected_items_have_items || !invoice_date"
               color="primary"
               @click="finish_invoice">
          Create New Invoice
        </v-btn>

      </v-col>
    </v-row>


    <v-snackbar v-if="invoice_action" v-model="show_invoice_action" timeout="2000" color="info" dark
                transition="slide-x-transition">
      Invoice {{ invoice_action }}
    </v-snackbar>


  </v-container>
</template>

<script>
import ReportSelector from "@/components/payments/ReportSelector";
import CustomerSelector from "@/components/payments/CustomerSelector";
import {mapGetters, mapActions} from 'vuex';
import InvoiceSelector from "@/components/payments/InvoiceSelector";
import GoogleFileLink from "../components/GoogleFileLink";
import quickbooks_service from "../services/quickbooks.service";

export default {
  name: "Invoices",
  components: {GoogleFileLink, InvoiceSelector, CustomerSelector, ReportSelector},
  props: {
    code: String,
    realm_id: String
  },
  data() {
    return {
      group_description: null,
      use_custom_description: false,
      custom_description_rules: [
        v => v && v.length <= 31 || 'Max 31 characters'
      ],
      date_opened: false
    }
  },
  computed: {
    date_range: {
      get() {
        return this.$store.getters["reports/date_range"];
      },
      set(value) {
        this.$store.dispatch('reports/set_date_range', value);
      }
    },
    custom_description: {
      get() {
        return this.$store.getters['reports/custom_description'];
      },
      set(value) {
        this.$store.dispatch('reports/set_custom_description', value);
      }
    },
    show_invoice_action: {
      get() {
        return this.$store.getters['quickbooks/show_invoice_action'];
      },
      set() {
        this.$store.dispatch('quickbooks/hide_invoice_action');
      }
    },
    invoice_date: {
      get() {
        return this.$store.getters['reports/invoice_date'];
      },
      set(value) {
        this.$store.dispatch('reports/set_invoice_date', value);
      }
    },
    ...mapGetters('quickbooks', [
      'items',
      'is_logged_in',
      'has_selected_customer',
      'expiration_time',
      'is_token_expired',
      'invoices',
      'invoice_action',
      'matching_invoices',
      'selected_customer'
    ]),
    ...mapGetters('reports', [
      'has_selected_items',
      'description',
      'selected_items',
      'selected_items_have_items',
      'has_data'
    ]),
    ...mapGetters('stepper', [
      'step_two_enabled',
      'step_three_enabled',
      'step_four_enabled',
      'step_five_enabled'
    ]),
    step: {
      get() {
        return this.$store.getters["stepper/step"];
      },
      set(value) {
        this.$store.dispatch('stepper/set_step', value);
      }
    }
  },
  async mounted() {
    if (this.is_logged_in) {
      this.step = 2;
      await this.refresh();
    }
    else if (this.code && this.realm_id) {
      try {
        this.step = 2;
        await this.get_tokens({code: this.code, realm_id: this.realm_id});
        await this.refresh();
      }
      catch (err) {
        this.step = 1
      }
    }
    else {
      this.step = 1;
    }
  },
  methods: {
    async refresh() {
      await this.search();
      await this.load_brands();
      await this.load_customers()
      await this.load_items()
    },
    enable_custom(e) {
      if (!e) {
        this.custom_description = null;
      }
    },
    login: async function () {
      const url = await quickbooks_service.get_login_url();
      window.location.href = url;
    },
    logout: function () {
      this.$store.commit('quickbooks/set_access_token', null);
      this.$store.commit('quickbooks/set_refresh_token', null);
      this.$store.commit('quickbooks/set_expiration_time', null);
      this.$store.commit('quickbooks/set_realm_id', null);
      this.step = 1;
    },
    ...mapActions('quickbooks', [
      'get_tokens',
      'load_customers',
      'load_items',
      'create_invoice',
      'clear_selected_customer'
    ]),
    ...mapActions('reports', [
      'update_selected_item_item_type',
      'update_selected_item_item_desc',
      'search',
      'clear_selected_items',
      'clear_date_range',
      'remove_item'
    ]),
    ...mapActions('report_filters', [
      'load_brands'
    ]),
    ...mapActions('stepper', [
      'set_step',
      'next_step',
      'previous_step'
    ]),
    async finish_invoice() {
      await this.create_invoice();
    },
    async reset_form() {
      if (this.is_logged_in) {
        await this.search()
        this.description = null;
        this.clear_selected_items();
        this.clear_date_range();
        this.clear_selected_customer();
        this.set_step(2);
      }
      else {
        this.set_step(1);
      }
    }
  }
}
</script>

<style scoped>

</style>
