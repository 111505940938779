<template>
  <div>
    <google-file-link v-if="file_id" :file_id="file_id"></google-file-link>
    <header-mapping-edit-control :header_mapping="header_mapping"></header-mapping-edit-control>
  </div>
</template>

<script>
import report_service from "../services/report.service";
import HeaderMappingEditControl from "../components/header_mappings/HeaderMappingEditControl";
import GoogleFileLink from "../components/GoogleFileLink";

export default {
  name: "HeaderMappingEdit",
  components: {GoogleFileLink, HeaderMappingEditControl},
  props: {
    id: String,
    copy: Boolean,
    headers: Array,
    brand: String,
    scope: String,
    provider: String,
    file_id: String
  },
  data() {
    return {
      header_mapping: null
    }
  },
  async mounted() {
    if (this.id === 'new') {
      this.header_mapping = {
        ...this.brand && {brand: this.brand},
        ...this.provider && {provider: this.provider},
        scope: this.scope,
        headers: [],
        mappings: {},
        static_mappings: {
          ...this.brand && {brand: this.brand},
          scope: this.scope
        }
      };
      console.log(this);
      if (this.headers) {
        if (typeof this.headers === 'string') {
          this.headers = [this.headers];
        }
        this.headers = this.headers.map(x => x === 'null' ? null : x);
        this.header_mapping.headers = this.headers;
      }
      const similar = await report_service.header_mappings.search({
        provider: this.provider,
        brand: this.brand,
        scope: this.scope,
        ignore: false
      });
      if (similar && similar.length) {
        const first = similar[0];
        for (const [key, value] of Object.entries(first.mappings)) {
          if (this.header_mapping.headers.includes(key)) {
            this.$set(this.header_mapping.mappings, key, value);
            console.log(this.header_mapping.mappings);
          }
        }
      }
    }
    else {
      this.header_mapping = await report_service.header_mappings.get_by_id(this.id);
      if (this.copy) {
        this.header_mapping._id = null;
      }
    }
  }
}
</script>

<style scoped>

</style>