import Vue from 'vue'
import Vuex from 'vuex'
import {createLogger} from "vuex";
import {gtm_plugin} from "./plugins/gtm_plugin";
import createPersistedState from 'vuex-persistedstate';
import firebase from './modules/firebase'
import pagination_settings from './modules/pagination_settings'
import file_processor from './modules/file_processor'
import pre_processor from './modules/pre_processor'
import quickbooks from './modules/quickbooks'
import reports from './modules/reports'
import report_filters from './modules/report_filters'
import stepper from './modules/stepper'

Vue.use(Vuex)

const debug = process.env.NODE_ENV !== 'production'
const persisted = createPersistedState({
    paths: ['quickbooks.access_token', 'quickbooks.refresh_token', 'quickbooks.realm_id', 'quickbooks.expiration_time'
        , 'report_filters.filters'
        , 'pagination_settings.brands'
        , 'pagination_settings.pre_processor'
        , 'pagination_settings.allowed_properties'
        , 'pagination_settings.header_mappings'
        , 'pagination_settings.file_processor'
        , 'pagination_settings.vendor_payment_records']
});

export default new Vuex.Store({
    modules: {
        pagination_settings,
        pre_processor,
        file_processor,
        firebase,
        quickbooks,
        reports,
        report_filters,
        stepper
    },
    strict: debug,
    plugins: debug ? [createLogger(), gtm_plugin, persisted] : [gtm_plugin, persisted]
})
