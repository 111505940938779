import axios from "axios";
import {auth} from "../plugins/firebase";

export default {
    jobs: {
        search: async function (statuses) {
            try {
                const token = await auth.currentUser.getIdToken();
                let {data} = await axios.get(`${process.env.VUE_APP_API_URL}jobs/pre_processor`, {
                    params: {
                        statuses: statuses
                    },
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                return data;
            }
            catch (err) {
                console.error(err);
                return null
            }
        },
        get_by_id: async function (id) {
            try {
                const token = await auth.currentUser.getIdToken();
                let {data} = await axios.get(`${process.env.VUE_APP_API_URL}jobs/pre_processor/${id}`, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                return data;
            }
            catch (err) {
                console.error(err);
                return null
            }
        }
    },
    process: {
        file: async function (id) {
            try {
                const token = await auth.currentUser.getIdToken();
                let {data} = await axios.post(`${process.env.VUE_APP_API_URL}jobs/pre_processor/process/file/${id}`,
                    null,
                    {
                        headers: {
                            Authorization: `Bearer ${token}`
                        }
                    });
                return data;
            }
            catch (err) {
                console.error(err);
                return null
            }
        }
    }
}