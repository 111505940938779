<template>
  <v-autocomplete v-if="customers" v-model="selected_customer" :loading="customers_loading" :items="customers"
                  item-text="FullyQualifiedName" item-value="Id" return-object label="Select a customer"
                  clearable></v-autocomplete>
</template>

<script>
import {mapState, mapActions} from "vuex";

export default {
  name: "CustomerSelector",
  computed: {
    selected_customer: {
      get() {
        return this.$store.state.quickbooks.selected_customer;
      },
      set(value) {
        this.$store.dispatch('quickbooks/set_selected_customer', value)
      }
    },
    ...mapState('quickbooks', [
      'customers',
      'customers_loading',
    ])
  },
  methods: {
    ...mapActions('quickbooks', [
      'load_invoices'
    ])
  }
}
</script>

<style scoped>

</style>