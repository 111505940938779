import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const state = {
    brands: {page: 1, itemsPerPage: 10},
    pre_processor: {page: 1, itemsPerPage: 5},
    allowed_properties: {page: 1, itemsPerPage: 10},
    header_mappings: {page: 1, itemsPerPage: 5},
    file_processor: {page: 1, itemsPerPage: 5},
    vendor_payment_records: {page: 1, itemsPerPage: 10},
}

const getters = {
    brands: state => state.brands,
    pre_processor: state => state.pre_processor,
    allowed_properties: state => state.allowed_properties,
    header_mappings: state => state.header_mappings,
    file_processor: state => state.file_processor,
    vendor_payment_records: state => state.vendor_payment_records,
}

const actions = {
    async update_brands({commit}, payload) {
        commit('update_brands', payload);
    },
    async update_pre_processor({commit}, payload) {
        commit('update_pre_processor', payload);
    },
    async update_allowed_properties({commit}, payload) {
        commit('update_allowed_properties', payload);
    },
    async update_header_mappings({commit}, payload) {
        commit('update_header_mappings', payload);
    },
    async update_file_processor({commit}, payload) {
        commit('update_file_processor', payload);
    },
    async update_vendor_payment_records({commit}, payload) {
        commit('update_vendor_payment_records', payload);
    },
}

const mutations = {
    async update_brands(state, payload) {
        state.brands = payload;
    },
    update_pre_processor(state, payload) {
        state.pre_processor = payload;
    },
    update_allowed_properties(state, payload) {
        state.allowed_properties = payload;
    },
    update_header_mappings(state, payload) {
        state.header_mappings = payload;
    },
    update_file_processor(state, payload) {
        state.file_processor = payload;
    },
    update_vendor_payment_records(state, payload) {
        state.vendor_payment_records = payload;
    },
}

// A Vuex instance is created by combining the state, mutations, actions,
// and getters.
export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
