
export default {
    get_brand_name: function (brand) {
        if(brand === 'frontier') {
            return 'Frontier Utilities'
        }

        if(brand === 'gexa') {
            return 'Gexa Energy';
        }
    }
}
