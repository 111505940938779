<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <v-card>
          <v-card-title>File Runs</v-card-title>
          <v-data-table :headers="headers" :items="items" item-key="_id" show-expand :expanded.sync="expanded">
            <template v-slot:item.report_options="{item}">
              <p v-for="[key,value] in Object.entries(item.report_options)" :key="key">
                <strong>{{ key }}</strong>: {{ value }}
              </p>
            </template>
            <template v-slot:item.date_created="{item}">
              {{ item.date_created | moment('calendar') }}
            </template>
            <template v-slot:item.found_all_sheets="{item}">
              <v-icon :color="item.found_all_sheets ? 'success' : 'error'">
                {{ item.found_all_sheets ? 'mdi-check' : 'mdi-close' }}
              </v-icon>
            </template>
            <template v-slot:item.processed_all_sheets="{item}">
              <v-icon :color="item.processed_all_sheets ? 'success' : 'error'">
                {{ item.processed_all_sheets ? 'mdi-check' : 'mdi-close' }}
              </v-icon>
            </template>
            <template v-slot:item.total_number_of_sheets="{item}">
              <chip-number :item_number="item.total_number_of_sheets"></chip-number>
            </template>
            <template v-slot:item.number_of_sheets_with_found_headers="{item}">
              <chip-number :item_number="item.number_of_sheets_with_found_headers"
                           :color="item.number_of_sheets_with_found_headers < item.total_number_of_sheets ? 'error': 'secondary'"></chip-number>
            </template>

            <template v-slot:item.number_of_sheets_to_process="{item}">
              <chip-number :item_number="item.number_of_sheets_to_process"></chip-number>
            </template>
            <template v-slot:item.number_of_sheets_processed="{item}">
              <chip-number :item_number="item.number_of_sheets_processed"
                           :color="item.number_of_sheets_processed < item.number_of_sheets_to_process ? 'error' : 'secondary'"></chip-number>
            </template>
            <template v-slot:item.number_of_duplicates_found="{item}">
              <chip-number :item_number="item.number_of_duplicates_found"
                           :color="item.number_of_duplicates_found > 0 ? 'warning' : 'secondary'"></chip-number>
            </template>
            <template v-slot:item.error="{item}">
              <span class="error--text">{{ item.error }}</span>
            </template>
            <template v-slot:item.file="{item}">
              <google-file-link :file_id="item.file.id" :file_name="item.file.name"></google-file-link>
            </template>

            <template v-slot:expanded-item="{headers, item}">
              <td :colspan="headers.length" :class="{'pa-0': item.sheet_results && item.sheet_results.length}">
                <v-simple-table v-if="item.sheet_results">
                  <thead>
                  <tr>
                    <th>Sheet Name</th>
                    <th>Header Count</th>
                    <th>Found</th>
                    <th>Processed</th>
                    <th>Not Dupe</th>
                    <th>Has Data</th>
                    <th>Data Count</th>
                    <th>Hash</th>
                    <th>Actions</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="sheet_result in item.sheet_results" :key="sheet_result.sheet_name">
                    <td>{{ sheet_result.sheet_name }}</td>
                    <td>
                      <v-dialog max-width="500px" v-if="sheet_result.header_values">
                        <template v-slot:activator="{on, attrs}">
                          <v-badge color="primary" :content="sheet_result.header_values.length" overlap bordered>
                            <v-btn color="secondary" outlined v-on="on" v-bind="attrs">
                              View
                            </v-btn>
                          </v-badge>
                        </template>
                        <v-card>
                          <v-card-title>Headers</v-card-title>
                          <v-data-table v-if="sheet_result.header_values"
                                        :headers="[{text: 'Value', value: 'value'}]"
                                        :items="sheet_result.header_values.map(x=>({key: x, value: x}))"
                          >
                          </v-data-table>
                        </v-card>
                      </v-dialog>
                      <p v-else>No headers found</p>
                    </td>
                    <td>
                      <v-icon :color="sheet_result.mapping || sheet_result.is_empty_sheet ? 'success' : 'error'">
                        {{ sheet_result.mapping || sheet_result.is_empty_sheet ? 'mdi-check' : 'mdi-close' }}
                      </v-icon>
                    </td>
                    <td>
                      <v-icon :color="sheet_result.processed ? 'success' : 'error'">
                        {{ sheet_result.processed ? 'mdi-check' : 'mdi-close' }}
                      </v-icon>
                    </td>
                    <td>
                      <v-icon :color="sheet_result.is_duplicate ? 'error' : 'success'">
                        {{ sheet_result.is_duplicate ? 'mdi-close' : 'mdi-check' }}
                      </v-icon>
                    </td>
                    <td>
                      <v-icon :color="sheet_result.has_data_items ? 'success' : 'error'">
                        {{ sheet_result.has_data_items ? 'mdi-check' : 'mdi-close' }}
                      </v-icon>
                    </td>
                    <td>{{ sheet_result.data_item_count }}</td>
                    <td>{{ sheet_result.hash }}</td>
                    <td>
                      <v-btn v-if="sheet_result.header_values" icon color="primary"
                             :to="generate_header_mapping_link(item, sheet_result)">
                        <v-icon>mdi-plus</v-icon>
                      </v-btn>
                    </td>
                  </tr>
                  </tbody>
                </v-simple-table>
                <div v-else class="error--text">No sheet results</div>
              </td>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import report_service from '../services/report.service';
import ChipNumber from "../components/ChipNumber";
import GoogleFileLink from "../components/GoogleFileLink";

export default {
  name: "FileRuns",
  components: {GoogleFileLink, ChipNumber},
  props: {
    file_run_id: String
  },
  data() {
    return {
      headers: [
        {text: 'Job Id', value: 'job_id'},
        {text: 'File name', value: 'file.name'},
        {text: 'Report Options', value: 'report_options'},
        {text: 'Date', value: 'date_created'},
        {text: 'Found All?', value: 'found_all_sheets'},
        {text: 'Processed All?', value: 'processed_all_sheets'},
        {text: 'Total', value: 'total_number_of_sheets'},
        {text: 'Headers Found', value: 'number_of_sheets_with_found_headers'},
        {text: 'To Process', value: 'number_of_sheets_to_process'},
        {text: 'Processed', value: 'number_of_sheets_processed'},
        {text: 'Duplicates', value: 'number_of_duplicates_found'},
        {text: 'Error', value: 'error'},
        {text: 'File', value: 'file'},
      ],
      items: [],
      expanded: []
    }
  },
  async mounted() {
    this.items = await report_service.file_runs.search();
    this.expanded = [this.items.find(x => x._id === this.file_run_id)];
  },
  methods: {
    generate_header_mapping_link(item, sheet_result) {
      let link = '/header_mappings/new?';
      for (const [key, value] of Object.entries(item.report_options)) {
        link += `${key}=${value}&`
      }
      link += `file_id=${item.file.id}&`;
      link += sheet_result.header_values.map(x => `headers=${x.replace('#', '%23').replace('+', '%2b')}`).join('&');
      return link;
    }
  }
}
</script>

<style scoped>

</style>